import React from 'react';
import { Outlet } from 'react-router-dom';
import AdminHeader from '../components/admin/AdminHeader';
import Footer from '../components/Footer';
import { Box } from '@mui/material';
import { useThemeContext } from '../context/ThemeContext';
import AdminBreadcrumbs from "../components/admin/AdminBreadcrumbs";

const AdminLayout = () => {
    const { darkMode, toggleDarkMode } = useThemeContext();

  return (
    <>
      <AdminHeader darkMode={darkMode} toggleDarkMode={toggleDarkMode} />
      <Box component="main" sx={{ flex: 1, padding: '20px' }}>
        <AdminBreadcrumbs /> {/* Adicionando Breadcrumbs antes do conteúdo */}
        <Outlet />
      </Box>
      <Footer />
    </>
  );
};

export default AdminLayout;
