import { apiWithAuth } from './api'; // Importa as instâncias de API com e sem autenticação

// Lista de tipos permitidos
const tiposPermitidos = ['fundos', 'etf', 'stock'];

// Função para validar o tipo
const validarTipo = (tipo) => {
  if (!tiposPermitidos.includes(tipo)) {
    return null;
  }
};

// --------------- Funções Ranking e Aprovados ----------------------------------------------//
export const fetchRendaVariavelAtivoRanking = async (tipo) => {
  validarTipo(tipo); // Valida o tipo antes de fazer a requisição
  
  try {
    const response = await apiWithAuth.get(`/renda_variavel/${tipo}/ranking`);
    return response.data;
  } catch (error) {
    console.error(`Erro ao obter os dados de ${tipo}:`, error);
    throw error;
  }
};

export const fetchRendaVariavelAtivoAprovados = async (tipo) => {
    validarTipo(tipo); // Valida o tipo antes de fazer a requisição
    
    try {
      const response = await apiWithAuth.get(`/renda_variavel/${tipo}/ranking/aprovados`);
      return response.data;
    } catch (error) {
      console.error(`Erro ao obter os dados de ${tipo}:`, error);
      throw error;
    }
  };
  
export const sendRendaVariavelAtivoAprovados = async (tipo,aprovados) => {
    validarTipo(tipo); // Valida o tipo antes de fazer a requisição

    try {
        const response = await apiWithAuth.post(`/renda_variavel/${tipo}/ranking/aprovados`,aprovados);
        return response.data;
    } catch (error) {
        // Tratamento de erro com base na resposta HTTP
        if (error.response) {
            const status = error.response.status;
            const data = error.response.data;

            // Propagando uma mensagem específica com base no status HTTP
            switch (status) {
                case 400:
                    throw new Error('Erro de validação. Verifique os dados enviados.');
                case 401:
                    throw new Error('Não autorizado. Faça login novamente.');
                case 403:
                    throw new Error('Você não tem permissão para realizar essa ação.');
                case 404:
                    throw new Error('Endpoint não encontrado. Verifique a URL.');
                case 500:
                    throw new Error('Erro interno do servidor. Tente novamente mais tarde.');
                default:
                    throw new Error(data.message || 'Erro inesperado. Tente novamente mais tarde.');
            }
        } else if (error.request) {
            // Caso não haja resposta (problema na conexão)
            throw new Error('Não foi possível conectar ao servidor. Verifique sua conexão com a internet.');
        } else {
            // Erros fora do escopo de HTTP (erros de configuração, etc.)
            throw new Error(`Erro: ${error.message}`);
        }
    }
    };
// --------------- (Fim) Funções Ranking e Aprovados ----------------------------------------------//
// ------- Estatistica ----------- //

export const fetchRendaVariavelStatsTicker = async (tipo,ticker) =>  {
    validarTipo(tipo);
    try {
        const response = await apiWithAuth.get(`/renda_variavel/${tipo}/stats/${ticker}`);
        return response.data;
        } catch (error) {
            console.error(`Erro ao obter as estatísticas do ${ticker}`, error);
            throw error;
    }
};
// ------- (Fim) Estatistica ----------- //
// ---------------  Proventos ----------------------------------------------//

export const fetchRendaVariavelProventos = async (tipo_proventos) =>  {
  try {
      const response = await apiWithAuth.get(`/renda_variavel/proventos/${tipo_proventos}`);
      return response.data;
      } catch (error) {
          console.error(`Erro ao obter os dados de ${tipo_proventos} `, error);
          throw error;
  }
};

// --------------- (Fim) Proventos ----------------------------------------------//

// --------------- Classificação ------------------------------------------------//

export const fetchClassificacaoAtivosPaginado = async (page = 1, limit = 10) => {
  try {
      // Verifica se o limite excede 100 e lança erro
      if (limit > 100) {
          throw new Error('O valor máximo para "limit" é 100.');
      }

      const response = await apiWithAuth.get(`/renda_variavel/classificacao_ativos/paginado?page=${page}&limit=${limit}`);
      return response.data;
      } catch (error) {
          console.error(`Erro ao obter os dados de Classificação Ativos`, error);
          throw error;
  }
};

export const AddUpdateClassificacaoAtivos = async (data) => {
  try {
    const response = await apiWithAuth.post(`/renda_variavel/classificacao_ativos`, data);
    return response.data; // Retorna os dados em caso de sucesso
  } catch (error) {
    console.error(`Erro ao enviar os dados de Classificação Ativos`, error);

    // Tratamento de erros específicos com base nos códigos de status
    if (error.response) {
      if (error.response.status === 422) {
        throw new Error('O campo "TICKER B3" é obrigatório e deve ser válido.');
      } else if (error.response.status === 500) {
        throw new Error(`Erro interno no servidor: ${error.response.data.detail || 'Por favor, tente novamente mais tarde.'}`);
      } else if (error.response.status === 401) {
        throw new Error('Usuário não autenticado. Por favor, faça login.');
      } else if (error.response.status === 403) {
        throw new Error('Permissão negada. Você não tem acesso para realizar esta ação.');
      }
    }
    throw new Error('Erro ao processar a solicitação.'); // Mensagem genérica para outros casos
  }
};

export const deleteClassificacaoAtivos = async (ticker) => {
  try {
    const response = await apiWithAuth.delete(`/renda_variavel/classificacao_ativos/${ticker}`);
    return response.data;
  } catch (error) {
    console.error(`Erro ao remover classificação do ${ticker}:`, error);
    throw error;
  }
};

export const uploadClassificacaoAtivos = async (file) => {
    try {
        // Cria um FormData para enviar o arquivo
        const formData = new FormData();
        formData.append('file', file); // Adiciona o arquivo ao FormData

        // Faz a requisição POST para o upload do arquivo
        const response = await apiWithAuth.post('/classificacao_ativos/upload-data', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });

        return response.data; // Retorna a resposta do backend
    } catch (error) {
        console.error('Erro ao fazer upload do arquivo:', error);
        throw error;
    }
};

export const fetchClassificacaoAtivosClassificadosPaginado = async (page = 1, limit = 10) => {
  try {
      // Verifica se o limite excede 100 e lança erro
      if (limit > 100) {
          throw new Error('O valor máximo para "limit" é 100.');
      }

      const response = await apiWithAuth.get(`/renda_variavel/classificacao_ativos/classificados/paginado?page=${page}&limit=${limit}`);
      return response.data;
      } catch (error) {
          console.error(`Erro ao obter os dados de Ativos Classificados`, error);
          throw error;
  }
};

export const fetchClassificacaoAtivosNaoClassificadosPaginado = async (page = 1, limit = 10) => {
  try {
      // Verifica se o limite excede 100 e lança erro
      if (limit > 100) {
          throw new Error('O valor máximo para "limit" é 100.');
      }

      const response = await apiWithAuth.get(`/renda_variavel/classificacao_ativos/nao_classificados/paginado?page=${page}&limit=${limit}`);
      return response.data;
      } catch (error) {
          console.error(`Erro ao obter os dados de Ativos Não Classificados`, error);
          throw error;
  }
};

export const downloadAtivosNaoClassificadosExcel = async () => {
  try {
    const response = await apiWithAuth.get(
      '/renda_variavel/classificacao_ativos/nao_classificados/export_excel', 
      { responseType: 'blob' }
    );
    
    // Criar uma URL para o arquivo
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'ativos_nao_classificados.xlsx');
    document.body.appendChild(link);
    link.click();
    
    // Limpar o link após o download
    link.parentNode.removeChild(link);
  } catch (error) {
    console.error('Erro ao fazer o download do arquivo:', error);
    throw error;
  }
};

// -------- Configuraçoes ------------------------- //

// Função para obter configurações específicas pelo nome
export const getConfiguracao = async (nome) => {
  try {
      const response = await apiWithAuth.get(`/renda_variavel/configuracoes?nome=${nome}`);
      // console.log(response.data[0]?.config_data)
      return response.data; // Retorna o primeiro elemento, já que a busca é por nome específico
  } catch (error) {
      console.error(`Erro ao obter a configuração para ${nome}`, error);
      throw error;
  }
};

// Função para atualizar configurações específicas pelo nome
export const updateConfiguracao = async (nome, novosValores) => {
  try {
      const response = await apiWithAuth.patch(`/renda_variavel/configuracoes?nome=${nome}`, {  novos_valores: novosValores } );
      return response.data;
  } catch (error) {
      console.error(`Erro ao atualizar a configuração para ${nome}`, error);
       // Tratamento de erro com base na resposta HTTP
       if (error.response) {
        const status = error.response.status;
        const data = error.response.data;

        // Propagando uma mensagem específica com base no status HTTP
        switch (status) {
            case 400:
                throw new Error('Erro de validação. Verifique os dados enviados.');
            case 401:
                throw new Error('Não autorizado. Faça login novamente.');
            case 403:
                throw new Error('Você não tem permissão para realizar essa ação.');
            case 404:
                throw new Error('Endpoint não encontrado. Verifique a URL.');
            case 500:
                throw new Error('Erro interno do servidor. Tente novamente mais tarde.');
            default:
                throw new Error(data.message || 'Erro inesperado. Tente novamente mais tarde.');
        }
    } else if (error.request) {
        // Caso não haja resposta (problema na conexão)
        throw new Error('Não foi possível conectar ao servidor. Verifique sua conexão com a internet.');
    } else {
        // Erros fora do escopo de HTTP (erros de configuração, etc.)
        throw new Error(`Erro: ${error.message}`);
    }
}
};