import React, { useState, useEffect, useCallback } from 'react';
import { Box, Button, Typography,Tooltip } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import GenericTable from '../../components/GenericTable';
import Pagination from '../../components/Pagination';
import CustomSnackbar from '../../components/CustomSnackbar';
import { fetchClassificacaoAtivosNaoClassificadosPaginado, downloadAtivosNaoClassificadosExcel } from '../../services/rendaVariavel';

const AtivosNaoClassificadosRendaVariavel = () => {
    const [rows, setRows] = useState([]);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [totalPages, setTotalPages] = useState(1);
    const [loading, setLoading] = useState(false);
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success',
        position: { vertical: 'top', horizontal: 'right' },
    });

    const columns = [
        { label: 'Ticker B3', field: 'TICKER B3', width: '20%' },
        { label: 'Nomenclatura', field: 'DENOM_MONT(igual da B3)', width: '25%' },
        { label: 'ISIN', field: 'isin', width: '20%' },
        { label: 'CNPJ', field: 'cnpj', width: '15%' },
        { label: 'Segmento', field: 'Segmentos(GESTORA)', width: '10%' },
        { label: 'Tipo de Ativo', field: 'Tipo Ativo(GESTORA)', width: '10%' },
    ];

    const fetchRows = useCallback(async () => {
        setLoading(true);
        try {
            const data = await fetchClassificacaoAtivosNaoClassificadosPaginado(page, limit);
            setRows(data.ticker_b3);
            setTotalPages(data.totalPages || 1);
        } catch (err) {
            setSnackbar({
                open: true,
                message: 'Erro ao carregar dados de ativos não classificados.',
                severity: 'error',
                position: { vertical: 'top', horizontal: 'center' }
            });
        } finally {
            setLoading(false);
        }
    }, [page, limit]);

    useEffect(() => {
        fetchRows();
    }, [fetchRows]);

    const handleDownload = async () => {
        try {
            await downloadAtivosNaoClassificadosExcel();
            setSnackbar({
                open: true,
                message: 'Download realizado com sucesso!',
                severity: 'success',
                position: { vertical: 'top', horizontal: 'right' }
            });
        } catch (error) {
            setSnackbar({
                open: true,
                message: 'Erro ao baixar o arquivo Excel.',
                severity: 'error',
                position: { vertical: 'top', horizontal: 'center' }
            });
        }
    };

    const handleSnackbarClose = () => {
        setSnackbar({ ...snackbar, open: false });
    };

    return (
        <Box sx={{ width: '100%', padding: '20px' }}>
            <Typography variant="h4" gutterBottom>
                Ativos Não Classificados
            </Typography>
            <Box display="flex" justifyContent="flex-end" mb={2}>
                <Tooltip title="Baixar dados de ativos não classificados em Excel">
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<DownloadIcon />}
                        onClick={handleDownload}
                    >
                        Download
                    </Button>
                </Tooltip>
            </Box>

            <GenericTable
                data={rows}
                columns={columns}
                emptyMessage="Nenhum ativo não classificado disponível."
            />

            <Pagination
                totalPages={totalPages}
                currentPage={page}
                limit={limit}
                onPageChange={(newPage) => setPage(newPage)}
                onLimitChange={(newLimit) => {
                    setLimit(newLimit);
                    setPage(1);
                }}
            />

            <CustomSnackbar
                open={snackbar.open}
                message={snackbar.message}
                severity={snackbar.severity}
                onClose={handleSnackbarClose}
                position={snackbar.position}
            />

            {loading && <Typography variant="body2" color="textSecondary">Carregando...</Typography>}
        </Box>
    );
};

export default AtivosNaoClassificadosRendaVariavel;
