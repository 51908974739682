import { apiWithAuth } from './api';  // Importa as instâncias de API com e sem autenticação

export const Indicadores = async () =>  {
    try {
        const response = await apiWithAuth.get(`/indicadores`);
        return response.data;
        } catch (error) {
            console.error('Erro ao obter os indicadores', error);
            throw error;
    }
}