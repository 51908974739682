import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import PeopleIcon from '@mui/icons-material/People';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import ModuleCard from '../components/ModuleCard';
import { menuItems, filterMenuByPermissions } from '../routes/routes';
import { useAuth } from '../context/AuthContext';

const moduleIcons = {
  'Planejadores': <PeopleIcon sx={{ fontSize: 60 }} />,
  'Fundos de Investimento': <TrendingUpIcon sx={{ fontSize: 60 }} />,
  'Renda Variável': <ShowChartIcon sx={{ fontSize: 60 }} />,
};

const Home = () => {
  const theme = useTheme();
  const { permissions } = useAuth();

  // Filtra apenas os módulos que o usuário tem permissão para ver
  const allowedModules = filterMenuByPermissions(menuItems, permissions);

  // Garante que a Home NÃO apareça como um módulo na tela
  const topModules = allowedModules
    .filter(item => item.text !== 'Home')
    .map(item => ({
      text: item.text,
      description: `Gerencie o módulo de ${item.text}.`,
      route: item.route,
    }));

  // Calcula o número total de colunas com base na quantidade de módulos (1 coluna a cada 3 módulos)
  const columns = Math.ceil(topModules.length / 3);

  // Função auxiliar para limitar o número de colunas de acordo com o breakpoint
  const getColumnsForBreakpoint = (breakpoint) => {
    if (breakpoint === 'xs') return Math.min(columns, 1); // em telas extra pequenas: no máximo 1 coluna
    if (breakpoint === 'sm') return Math.min(columns, 2); // em telas pequenas: no máximo 2 colunas
    if (breakpoint === 'md') return Math.min(columns, 3); // em telas médias: no máximo 3 colunas
    return columns; // para telas maiores, utiliza o valor total calculado
  };

  return (
    <Box sx={{ flexGrow: 1, padding: 2 }}>
      <Typography
        variant="h4"
        align="center"
        gutterBottom
        sx={{ fontWeight: 'bold', color: theme.palette.text.primary }}
      >
        Módulos
      </Typography>

      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: {
            xs: `repeat(${getColumnsForBreakpoint('xs')}, 1fr)`,
            sm: `repeat(${getColumnsForBreakpoint('sm')}, 1fr)`,
            md: `repeat(${getColumnsForBreakpoint('md')}, 1fr)`,
            lg: `repeat(${getColumnsForBreakpoint('lg')}, 1fr)`,
          },
          gap: 2,
          paddingX: 2,
        }}
      >
        {topModules.map((module, index) => (
          <ModuleCard
            key={index}
            title={module.text}
            description={module.description}
            icon={moduleIcons[module.text] || <PeopleIcon sx={{ fontSize: 60 }} />}
            route={module.route}
          />
        ))}
      </Box>
    </Box>
  );
};

export default Home;

