import React, { useState, useEffect } from 'react';
import {
  Box, Button, MenuItem, Select, Typography, FormControl, InputLabel,
  Paper, Stack, IconButton, Divider
} from '@mui/material';
import {
  Close as CloseIcon,
  CloudDownload as DownloadIcon
} from '@mui/icons-material';
import { gerarRelatorioRisco } from '../../services/fundosInvestimentos';
import { downloadFile } from '../../components/utils/downloadUtils';
import { listaPlanejadorNomes } from '../../services/planejadores';
import FullScreenLoader from '../../components/FullScreenLoader';
import CustomSnackbar from '../../components/CustomSnackbar';
import SearchBoxFundos from '../../components/SearchBoxFundos';
import CustomDialog from '../../components/Modal/CustomDialog';

const MAX_FUNDOS = 3;

const FundoRelatorioRisco = () => {
  const [fundosSelecionados, setFundosSelecionados] = useState([]);
  const [indicador, setIndicador] = useState('');
  const [nomePlanejador, setNomePlanejador] = useState('');
  const [planejadores, setPlanejadores] = useState([]);
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
    position: { vertical: 'top', horizontal: 'center' }
  });

  const [dialogOpen, setDialogOpen] = useState(false);
  const [fundoParaRemover, setFundoParaRemover] = useState(null);

  useEffect(() => {
    const carregarPlanejadores = async () => {
      try {
        const resultados = await listaPlanejadorNomes();
        setPlanejadores(resultados);
      } catch (error) {
        console.error('Erro ao carregar planejadores:', error);
      }
    };
    carregarPlanejadores();
  }, []);

  const handleSelectFund = (cnpj_classe, classe, id_subclasse, subclasse) => {
    let mensagem = "";

    if (fundosSelecionados.length >= MAX_FUNDOS) {
      mensagem = `Você só pode selecionar até ${MAX_FUNDOS} fundos.`;
    } else if (fundosSelecionados.some(f => f.cnpj_classe === cnpj_classe && f.id_subclasse === id_subclasse)) {
      mensagem = "Este fundo já foi selecionado!";
    }

    if (mensagem) {
      setSnackbar(prev => ({ ...prev, open: false }));
      setTimeout(() => {
        setSnackbar({
          open: true,
          message: mensagem,
          severity: 'warning',
          position: { vertical: 'top', horizontal: 'center' }
        });
      }, 100);
      return;
    }

    setFundosSelecionados(prev => [...prev, { cnpj_classe, classe, id_subclasse, subclasse }]);
  };

  const handleRemoveFund = (fundo) => {
    setFundoParaRemover(fundo);
    setDialogOpen(true);
  };
 
  const confirmarRemocaoFundo = () => {
    try {
      if (!fundoParaRemover) return;
  
      setFundosSelecionados(prev =>
        prev.filter(f =>
          !(f.cnpj_classe === fundoParaRemover.cnpj_classe && f.id_subclasse === fundoParaRemover.id_subclasse)
        )
      );
  
      setSnackbar({
        open: true,
        message: `Fundo "${fundoParaRemover.classe}" removido com sucesso!`,
        severity: 'success',
        position: { vertical: 'top', horizontal: 'right' }
      });
    } catch (error) {
      setSnackbar({
        open: true,
        message: `Erro ao remover o fundo "${fundoParaRemover.classe}".`,
        severity: 'error',
        position: { vertical: 'top', horizontal: 'right' }
      });
      console.error('Erro ao remover fundo:', error);
    } finally {
      setDialogOpen(false);
      setFundoParaRemover(null);
    }
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (fundosSelecionados.length === 0) {
      setSnackbar({
        open: true,
        message: 'Selecione pelo menos um fundo.',
        severity: 'error',
        position: { vertical: 'top', horizontal: 'center' }
      });
      return;
    }

    if (!indicador) {
      setSnackbar({
        open: true,
        message: 'Por favor, selecione um indicador.',
        severity: 'error',
        position: { vertical: 'top', horizontal: 'center' }
      });
      return;
    }

    setLoading(true);

    try {
      const usarCapaPersonalizada = nomePlanejador.trim() !== '' && nomePlanejador !== 'MONT(DEFAULT)';

      const dataRelatorio = {
        fundos: fundosSelecionados,
        indicador,
        usar_capa_personalizada: usarCapaPersonalizada,
        nome_planejador: usarCapaPersonalizada ? nomePlanejador : null
      };

      const blob = await gerarRelatorioRisco(dataRelatorio);
      downloadFile(blob, 'relatorio_risco.pdf');

      setSnackbar({
        open: true,
        message: 'Relatório gerado e baixado com sucesso!',
        severity: 'success',
        position: { vertical: 'top', horizontal: 'right' }
      });
    } catch (err) {
      const errorMessage = err.message.includes('\n')
        ? err.message.split('\n').map((line, index) => (
            <span key={index}>
              {line}
              <br />
            </span>
          ))
        : err.message;

      setSnackbar({
        open: true,
        message: errorMessage,
        severity: 'error',
        position: { vertical: 'top', horizontal: 'center' }
      });
    } finally {
      setLoading(false);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <Box p={4} display="flex" flexDirection="column" alignItems="center">
      <Typography variant="h4" gutterBottom>
        Relatório de Risco Fundos
      </Typography>

      <SearchBoxFundos onSelectFund={handleSelectFund} />

      {fundosSelecionados.length > 0 && (
        <Paper elevation={3} sx={{ width: '100%', maxWidth: '400px', p: 2, mt: 2, borderRadius: 2 }}>
          <Typography variant="h6" sx={{ mb: 1 }}>Fundos Selecionados:</Typography>
          <Stack direction="column" spacing={1} mt={1}>
            {fundosSelecionados.map((fundo) => (
              <Box
                key={`${fundo.cnpj_classe}-${fundo.id_subclasse}`}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  backgroundColor: '#f5f5f5',
                  padding: '8px 12px',
                  borderRadius: 2,
                  border: '1px solid #ddd',
                  position: 'relative'
                }}
              >
                <IconButton
                  size="small"
                  onClick={() => handleRemoveFund(fundo)}
                  sx={{
                    position: 'absolute',
                    top: 4,
                    right: 4,
                    color: 'red'
                  }}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>

                <Typography variant="body2"><strong>Nome Classe:</strong> {fundo.classe}</Typography>
                <Typography variant="body2"><strong>CNPJ Classe:</strong> {fundo.cnpj_classe}</Typography>
                <Divider sx={{ my: 1 }} />
                <Typography variant="body2"><strong>Subclasse:</strong> {fundo.subclasse}</Typography>
                <Typography variant="body2"><strong>ID Subclasse:</strong> {fundo.id_subclasse}</Typography>
              </Box>
            ))}
          </Stack>
        </Paper>
      )}

      <FormControl fullWidth variant="outlined" margin="normal" sx={{ maxWidth: '400px' }}>
        <InputLabel id="indicador-label">Indicador</InputLabel>
        <Select
          labelId="indicador-label"
          label="Indicador"
          value={indicador}
          onChange={(e) => setIndicador(e.target.value)}
        >
          <MenuItem value="CDI">CDI</MenuItem>
          <MenuItem value="IBOV">IBOV</MenuItem>
          <MenuItem value="IMAB-GERAL">IMAB-GERAL</MenuItem>
        </Select>
      </FormControl>

      <FormControl fullWidth variant="outlined" margin="normal" sx={{ maxWidth: '400px' }}>
        <InputLabel id="planejador-label">Nome do Planejador (opcional)</InputLabel>
        <Select
          labelId="planejador-label"
          value={nomePlanejador}
          onChange={(e) => setNomePlanejador(e.target.value)}
          displayEmpty
          fullWidth
        >
          {planejadores.map((nome) => (
            <MenuItem key={nome} value={nome}>{nome}</MenuItem>
          ))}
        </Select>
      </FormControl>

      <Button
        type="submit"
        variant="contained"
        color="primary"
        fullWidth
        sx={{ mt: 3, maxWidth: '400px', fontSize: '1rem', display: 'flex', alignItems: 'center' }}
        onClick={handleSubmit}
        startIcon={<DownloadIcon />}
        disabled={fundosSelecionados.length === 0 || loading}
      >
        {loading ? 'Gerando Relatório...' : 'Gerar Relatório'}
      </Button>

      <FullScreenLoader visible={loading} message="Gerando Relatório..." />

      <CustomSnackbar
        open={snackbar.open}
        message={snackbar.message}
        severity={snackbar.severity}
        onClose={handleCloseSnackbar}
        position={snackbar.position}
      />
      
      <CustomDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        onConfirm={confirmarRemocaoFundo}
        title="Remover Fundo Selecionado"
        description={
            fundoParaRemover ? (
            <span>
                Você está prestes a remover o fundo selecionado:
                <br />
                <br />
                <strong>Classe:</strong> {fundoParaRemover.classe}
                <br />
                <strong>Subclasse:</strong> {fundoParaRemover.subclasse}
                <br />
                <br />
                <span style={{ fontSize: '0.85em', color: 'gray', fontStyle: 'italic' }}>
                Tem certeza que deseja remover este fundo da seleção?
                </span>
            </span>
            ) : (
            "Tem certeza que deseja remover este fundo da seleção?"
            )
        }
        confirmButtonText="Remover"
        cancelButtonText="Cancelar"
        />

    </Box>
  );
};

export default FundoRelatorioRisco;
