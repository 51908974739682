import { apiWithAuth } from './api'; // Importa as instâncias de API com e sem autenticação


export const fetchCreditoPrivadoRanking = async () => {    
    try {
      const response = await apiWithAuth.get(`/credito_privado/ranking`);
      return response.data;
    } catch (error) {
      console.error(`Erro ao obter os dados de Crédito Privado:`, error);
      throw error;
    }
  };

export const fetchCreditoPrivadoRankingAprovados = async () => {

try {
    const response = await apiWithAuth.get(`/credito_privado/ranking/aprovados`);
    return response.data;
} catch (error) {
    console.error(`Erro ao obter os dados de Aprovados Crédito Privado:`, error);
    throw error;
}
};
  
export const sendCreditoPrivadoAprovados = async (aprovados) => {

    try {
        const response = await apiWithAuth.post(`/credito_privado/ranking/aprovados`,aprovados);
        return response.data;
    } catch (error) {
        // Tratamento de erro com base na resposta HTTP
        if (error.response) {
            const status = error.response.status;
            const data = error.response.data;

            // Propagando uma mensagem específica com base no status HTTP
            switch (status) {
                case 400:
                    throw new Error('Erro de validação. Verifique os dados enviados.');
                case 401:
                    throw new Error('Não autorizado. Faça login novamente.');
                case 403:
                    throw new Error('Você não tem permissão para realizar essa ação.');
                case 404:
                    throw new Error('Endpoint não encontrado. Verifique a URL.');
                case 500:
                    throw new Error('Erro interno do servidor. Tente novamente mais tarde.');
                default:
                    throw new Error(data.message || 'Erro inesperado. Tente novamente mais tarde.');
            }
        } else if (error.request) {
            // Caso não haja resposta (problema na conexão)
            throw new Error('Não foi possível conectar ao servidor. Verifique sua conexão com a internet.');
        } else {
            // Erros fora do escopo de HTTP (erros de configuração, etc.)
            throw new Error(`Erro: ${error.message}`);
        }
    }
    };

// ------- Estatistica ----------- //

export const fetchCreditoPrivadoStatsCodigoIF = async (codigo_if) =>  {

    try {
        const response = await apiWithAuth.get(`/credito_privado/stats/${codigo_if}`);
        return response.data;
        } catch (error) {
            console.error(`Erro ao obter as estatísticas do ${codigo_if}`, error);
            throw error;
    }
};
// ------- (Fim) Estatistica ----------- //

// -------- Configuraçoes ------------------------- //

// Função para obter configurações específicas pelo nome
export const getConfiguracao = async (nome) => {
    try {
        const response = await apiWithAuth.get(`/credito_privado/configuracoes?nome=${nome}`);
        return response.data; // Retorna o primeiro elemento, já que a busca é por nome específico
    } catch (error) {
        console.error(`Erro ao obter a configuração para ${nome}`, error);
        throw error;
    }
  };
  
  // Função para atualizar configurações específicas pelo nome
  export const updateConfiguracao = async (nome, novosValores) => {
    try {
        const response = await apiWithAuth.patch(`/credito_privado/configuracoes?nome=${nome}`, {  novos_valores: novosValores } );
        return response.data;
    } catch (error) {
        console.error(`Erro ao atualizar a configuração para ${nome}`, error);
         // Tratamento de erro com base na resposta HTTP
         if (error.response) {
          const status = error.response.status;
          const data = error.response.data;
  
          // Propagando uma mensagem específica com base no status HTTP
          switch (status) {
              case 400:
                  throw new Error('Erro de validação. Verifique os dados enviados.');
              case 401:
                  throw new Error('Não autorizado. Faça login novamente.');
              case 403:
                  throw new Error('Você não tem permissão para realizar essa ação.');
              case 404:
                  throw new Error('Endpoint não encontrado. Verifique a URL.');
              case 500:
                  throw new Error('Erro interno do servidor. Tente novamente mais tarde.');
              default:
                  throw new Error(data.message || 'Erro inesperado. Tente novamente mais tarde.');
          }
      } else if (error.request) {
          // Caso não haja resposta (problema na conexão)
          throw new Error('Não foi possível conectar ao servidor. Verifique sua conexão com a internet.');
      } else {
          // Erros fora do escopo de HTTP (erros de configuração, etc.)
          throw new Error(`Erro: ${error.message}`);
      }
  }
  };