// Função para criar dados para gráficos
export function criarChartData(propriedade, data) {
    return data.map(item => {
        const date = new Date(item.date);
        const timestamp = date.getTime();
        const valor = item[propriedade];
        return [timestamp, valor];
    });
}

// Função para calcular a rentabilidade acumulada
export function RentSum(propriedade, data) {
    let rentabilidadeAcumulada = [];
    let retornoAcumulado = 0;
    let rentlnacumulado = 0;

    data.forEach(item => {
        const date = new Date(item.date);
        const timestamp = date.getTime();
        const retornoLN = item[propriedade];
        rentlnacumulado += retornoLN;
        retornoAcumulado = Math.exp(rentlnacumulado) - 1;
        rentabilidadeAcumulada.push([timestamp, retornoAcumulado * 100]);
    });

    return rentabilidadeAcumulada;
}




// Função para calcular a rentabilidade acumulada dos fundos
export function calcularRentabilidadeAcumuladaParaFundos(data, propriedade) {
    let rentabilidadeAcumulada = [];
    let retornoAcumulado = 0;
    
    data.forEach((item, index) => {
      const timestamp = new Date(item.date).getTime();
      retornoAcumulado += item[propriedade];

      // Ajusta a rentabilidade para começar em 0 no primeiro índice
      const rentabilidade = index === 0 
        ? 0 
        : (Math.exp(retornoAcumulado) - 1) * 100; // Converte para porcentagem

      rentabilidadeAcumulada.push([timestamp, rentabilidade]);
    });
    
    return rentabilidadeAcumulada;
}

  
  // Função para calcular a rentabilidade média móvel
  export function calcularRentabilidadeMovel(array, propriedade, n_periodos) {
    let rentabilidades = [];
    
    for (let i = 0; i < array.length; i++) {
      if (i >= n_periodos - 1) {
        let somaMovel = 0;
        for (let j = i; j > i - n_periodos; j--) {
          somaMovel += array[j][propriedade];
        }
        
        const mediaRetornos = (somaMovel / n_periodos) * 252; // Anualiza para 252 dias úteis
        const rentabilidade = (Math.exp(mediaRetornos) - 1) * 100;
        const timestamp = new Date(array[i].date).getTime();
        
        rentabilidades.push([timestamp, rentabilidade]);
      }
    }
    
    return rentabilidades;
  }
  

  
  // Função para filtrar os dados entre duas datas (em formato timestamp)
  export function filtrarPorIntervalo(array, propriedade, dataInicialTimestamp) {
    return array
      .filter(item => new Date(item.date).getTime() >= dataInicialTimestamp)
      .map(item => ({
        date: item.date,
        [propriedade]: item[propriedade]
      }));
  }
  