import React, { useState, useEffect, useCallback } from 'react';
import PlanejadoresTable from '../components/PlanejadoresTable';
import Pagination from '../components/Pagination';
import CustomDialog from '../components/Modal/CustomDialog';
import CustomSnackbar from '../components/CustomSnackbar';
import AddIcon from '@mui/icons-material/Add';
import { Box, Button } from '@mui/material';
import { tabelaPlanejadorLogo, adicionarPlanejador, removerPlanejador, atualizarPlanejadorLogo } from '../services/planejadores';
import './Planejadores.css';

const Planejadores = () => {
    const [planejadores, setPlanejadores] = useState([]);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [totalPages, setTotalPages] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [addDialogOpen, setAddDialogOpen] = useState(false);
    const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
    const [editDialogOpen, setEditDialogOpen] = useState(false); // Estado para o diálogo de edição
    const [selectedPlanner, setSelectedPlanner] = useState(null); 
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success',
        title: '',
    });
    const [formData, setFormData] = useState({
        plannerName: "",
        plannerLogo: null,
    });

    const fetchPlanejadores = useCallback(async () => {
        setIsLoading(true);
        try {
            const data = await tabelaPlanejadorLogo(page, limit);
            setPlanejadores(data.planejadores);
            setTotalPages(data.totalPages);
        } catch (error) {
            console.error('Erro ao carregar planejadores:', error);
        } finally {
            setIsLoading(false);
        }
    }, [page, limit]);

    useEffect(() => {
        fetchPlanejadores();
    }, [fetchPlanejadores]);

    const openAddDialog = () => {
        setAddDialogOpen(true);
    };

    const openEditDialog = (nome_mc) => {
        setSelectedPlanner(nome_mc);
        setFormData({ plannerLogo: null }); // Limpa o logo no formulário de edição
        setEditDialogOpen(true);
    };

    const closeDialog = () => {
        setAddDialogOpen(false);
        setRemoveDialogOpen(false);
        setEditDialogOpen(false);
        setFormData({
            plannerName: "",
            plannerLogo: null,
        });
    };

    const closeSnackbar = () => {
        setSnackbar((prev) => ({ ...prev, open: false }));
    };

    // const handleFieldChange = (name, value) => {
    //     setFormData((prev) => ({ ...prev, [name]: value }));
    // };
    const handleFieldChange = (name, value) => {
        // Converte o valor para maiúsculas apenas se for uma string (para não afetar arquivos, por exemplo)
        const transformedValue = name === "plannerName" && typeof value === 'string' ? value.toUpperCase() : value;
    
        // Atualiza o estado ou faz outras manipulações necessárias
        setFormData((prevData) => ({
            ...prevData,
            [name]: transformedValue,
        }));
    };
    
    const handleAddPlanner = async () => {
        const { plannerName, plannerLogo } = formData;

        if (!plannerName || !plannerLogo) {
            setSnackbar({
                open: true,
                message: "Por favor, preencha o nome e selecione um logo.",
                severity: "error",
                title: "Erro de Validação",
                position: { vertical: 'top', horizontal: 'center' }
            });
            return;
        }

        try {
            await adicionarPlanejador(plannerName, plannerLogo);
            setSnackbar({
                open: true,
                message: "Planejador adicionado com sucesso!",
                severity: "success",
                position: { vertical: 'top', horizontal: 'right' }
            });

            fetchPlanejadores();
            closeDialog();
        } catch (error) {
            setSnackbar({
                open: true,
                message: "Falha ao adicionar o planejador.",
                severity: "error", 
                position: { vertical: 'top', horizontal: 'center' }
            });
        }
    };

    const handleConfirmRemove = async () => {
        try {
            await removerPlanejador(selectedPlanner);
            setPlanejadores(planejadores.filter((planejador) => planejador.nome_mc !== selectedPlanner));
            setSnackbar({
                open: true,
                message: "Planejador removido com sucesso.",
                severity: "success",
                position: { vertical: 'top', horizontal: 'right' }
            });
            closeDialog();
        } catch (error) {
            setSnackbar({
                open: true,
                message: "Falha ao remover o planejador.",
                severity: "error", 
                position: { vertical: 'top', horizontal: 'center' }
            });
        }
    };

    // Função para editar o logo do planejador
    const handleEditPlannerLogo = async () => {
        const { plannerLogo } = formData;
        
        if (!plannerLogo) {
            setSnackbar({
                open: true,
                message: "Por favor, selecione um novo logo para o planejador.",
                severity: "error",
                title: "Erro de Validação",
                position: { vertical: 'top', horizontal: 'center' }
            });
            return;
        }

        try {
            await atualizarPlanejadorLogo(selectedPlanner, plannerLogo);
            setSnackbar({
                open: true,
                message: "Logo do planejador atualizado com sucesso.",
                severity: "success",
                position: { vertical: 'top', horizontal: 'right' }
            });

            closeDialog();
        } catch (error) {
            setSnackbar({
                open: true,
                message: "Erro ao atualizar o logo do planejador.",
                severity: "error",
                position: { vertical: 'top', horizontal: 'center' }
            });
        }
    };

    return (
        <Box className="planejadores-container" p={2}>
            <h2>Planejadores</h2>

            <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                onClick={openAddDialog}
                style={{
                    marginBottom: '16px',
                    backgroundColor: '#007bff',
                    color: '#fff',
                    fontWeight: 'bold',
                    padding: '10px 20px',
                    borderRadius: '8px',
                }}
            >
                Adicionar Planejador
            </Button>

            {isLoading ? (
                <p>Carregando...</p>
            ) : (
                <>
                    <PlanejadoresTable
                        planejadores={planejadores}
                        onEdit={openEditDialog} // Abre o diálogo de edição
                        onRemove={(nome_mc) => {
                            setSelectedPlanner(nome_mc);
                            setRemoveDialogOpen(true);
                        }}
                    />
                    <Pagination
                        totalPages={totalPages}
                        currentPage={page}
                        limit={limit}
                        onPageChange={(newPage) => setPage(newPage)}
                        onLimitChange={(newLimit) => {
                            setLimit(newLimit);
                            setPage(1); // Redefine para a primeira página ao alterar o limite
                        }}
                        limitOptions={[1, 10, 20]}
                    />
                </>
            )}

            {/* Diálogo de Adicionar Planejador */}
            <CustomDialog
                open={addDialogOpen}
                onClose={closeDialog}
                onConfirm={handleAddPlanner}
                title="Adicionar Planejador"
                description="Preencha os detalhes do novo planejador."
                confirmButtonText="Adicionar"
                cancelButtonText="Cancelar"
                fields={[
                    {
                        name: "plannerName",
                        label: "Nome do Planejador:",
                        placeholder: "Insira o nome do planejador",
                        type: "text",
                        slotProps: {
                            htmlInput: {
                                style: { textTransform: 'uppercase' } // Aplica o estilo de maiúsculas
                            }
                        }
                    },
                    {
                        name: "plannerLogo",
                        label: "Logo do Planejador (PNG):",
                        type: "file",
                        accept: ".png",
                    },
                ]}
                onFieldChange={handleFieldChange}
            />

            {/* Diálogo de Confirmação de Remoção */}
            <CustomDialog
                open={removeDialogOpen}
                onClose={closeDialog}
                onConfirm={handleConfirmRemove}
                title="Remover Planejador"
                description={`Tem certeza de que deseja remover o planejador ${selectedPlanner}?`}
                confirmButtonText="Remover"
                cancelButtonText="Cancelar"
                fields={[]}
            />

            {/* Diálogo de Edição de Logo do Planejador */}
            <CustomDialog
                open={editDialogOpen}
                onClose={closeDialog}
                onConfirm={handleEditPlannerLogo}
                title="Atualizar Logo do Planejador"
                description={`Selecione um novo logo para o planejador ${selectedPlanner}.`}
                confirmButtonText="Atualizar"
                cancelButtonText="Cancelar"
                fields={[
                    {
                        name: "plannerLogo",
                        label: "Logo do Planejador (PNG):",
                        type: "file",
                        accept: ".png",
                    },
                ]}
                onFieldChange={handleFieldChange}
            />

            {/* CustomSnackbar para exibir mensagens */}
            <CustomSnackbar
                open={snackbar.open}
                message={snackbar.message}
                severity={snackbar.severity}
                onClose={closeSnackbar}
                title={snackbar.title}
                position={snackbar.position}
            />
        </Box>
    );
};

export default Planejadores;

