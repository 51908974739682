// Mesma pagina 
import React from 'react';
import { Tabs, Tab } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const NavigationTabs = ({ tabs, currentTab, onChange }) => {
    const theme = useTheme();

    return (
        <Tabs
            value={currentTab}
            onChange={(event, newValue) => onChange(newValue)} // Atualiza a aba ativa ao clicar
            sx={{
                marginBottom: '20px',
            }}
        >
            {tabs.map((tab) => (
                <Tab
                    key={tab.value}
                    label={tab.label}
                    value={tab.value}
                    sx={{
                        color: theme.palette.text.primary,
                        fontWeight: currentTab === tab.value ? 'bold' : 'normal',
                        transition: 'all 0.3s ease',
                        '&:hover': {
                            color: theme.palette.primary.light,
                            backgroundColor: theme.palette.action.hover,
                        },
                        '&.Mui-selected': {
                            color: theme.palette.secondary.main,
                            fontWeight: 'bold',
                            borderBottom: `2px solid ${theme.palette.secondary.main}`,
                        },
                    }}
                />
            ))}
        </Tabs>
    );
};

export default NavigationTabs;
