import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import CustomSnackbar from '../../components/CustomSnackbar';
import LoadingIndicator from '../../components/LoadingIndicator';
import { getConfiguracao, updateConfiguracao } from '../../services/creditoPrivado';
import { EstatisticasSection, IndicadoresSection } from '../../components/ParametresSections';

const ConfiguracoesCreditoPrivado = () => {
    const nomeConfiguracao = "Credito_Privado";
    const titulo = "Configurações de Crédito Privado";

    const [parametrosEstatisticos, setParametrosEstatisticos] = useState({});
    const [parametrosRanking, setParametrosRanking] = useState({
        indicadores_desejados: [],
        indicadores_desejados_asc: [],
    });
    const [indicadoresDisponiveis, setIndicadoresDisponiveis] = useState([]);
    const [indicadoresSelecionados, setIndicadoresSelecionados] = useState([]);
    const [orderAsc, setOrderAsc] = useState({});
    const [hasStatsChanges, setHasStatsChanges] = useState(false);
    const [hasRankingChanges, setHasRankingChanges] = useState(false);
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

    useEffect(() => {
        const fetchConfiguracoes = async () => {
            setLoading(true);
            try {
                const [estatisticosData, rankingData] = await Promise.all([
                    getConfiguracao(`Parametros_Estatisticos_${nomeConfiguracao}`),
                    getConfiguracao(`Parametros_Ranking_${nomeConfiguracao}`),
                ]);

                setParametrosEstatisticos(estatisticosData?.[0]?.config_data || {});

                const rankingConfig = rankingData?.[0]?.config_data || {
                    indicadores_desejados: [],
                    indicadores_desejados_asc: [],
                };

                setParametrosRanking(rankingConfig);
                setIndicadoresSelecionados(rankingConfig.indicadores_desejados || []);
                const initialOrderAsc = {};
                (rankingConfig.indicadores_desejados_asc || []).forEach((asc, idx) => {
                    initialOrderAsc[rankingConfig.indicadores_desejados[idx]] = asc;
                });
                setOrderAsc(initialOrderAsc);

                const allIndicators = ["SHARPE", "alfa_jensen", "Risco", "DP(AT)%", "BETA", "RENT_%DI+", "RENT_%IBOV+", "RENT_%IMABG+", "RENT_%IHFA+", "RENT_%IFIX+"];
                setIndicadoresDisponiveis(allIndicators.filter(ind => !rankingConfig.indicadores_desejados.includes(ind)));
            } catch (error) {
                setSnackbar({ open: true, message: `Erro ao carregar configurações de ${titulo}.`, severity: 'error', position: { vertical: 'top', horizontal: 'center' } });
            } finally {
                setLoading(false);
            }
        };

        fetchConfiguracoes();
    }, []);

    const handleSave = async (parametros, tipo) => {
        setSaving(true);
        try {
            await updateConfiguracao(`Parametros_${tipo}_${nomeConfiguracao}`, parametros);
            setSnackbar({ open: true, message: `${titulo} atualizado com sucesso!`, severity: 'success', position: { vertical: 'top', horizontal: 'right' } });
            if (tipo === 'Estatisticos') setHasStatsChanges(false);
            if (tipo === 'Ranking') setHasRankingChanges(false);
        } catch (error) {
            setSnackbar({ open: true, message: error.message, severity: 'error', position: { vertical: 'top', horizontal: 'center' } });
        } finally {
            setSaving(false);
        }
    };

    const handleStatsChange = (updatedStats) => {
        setParametrosEstatisticos(updatedStats);
        setHasStatsChanges(true);
    };

    const handleRankingChange = (updatedRanking) => {
        setParametrosRanking(updatedRanking);
        setHasRankingChanges(true);
    };

    return (
        <Box p={4}>
            <Typography variant="h4" gutterBottom>{titulo}</Typography>

            {loading ? (
                <LoadingIndicator message={`Carregando configurações de ${titulo}...`} />
            ) : (
                <>

                    <EstatisticasSection
                        statsParametros={parametrosEstatisticos}
                        setStatsParametros= {handleStatsChange}//{setParametrosEstatisticos}
                        hasStatsChanges={hasStatsChanges}
                        setHasStatsChanges={setHasStatsChanges}
                        onSave={() => handleSave(parametrosEstatisticos, 'Estatisticos')}
                        savingStats={saving}
                    />
                    <IndicadoresSection
                        showPublicoAlvo={false}
                        publicoAlvo={parametrosRanking.publicoAlvo || "SIM"}
                        setPublicoAlvo={(value) => handleRankingChange({ ...parametrosRanking, publicoAlvo: value })}
                        indicadoresDisponiveis={indicadoresDisponiveis}
                        setIndicadoresDisponiveis={setIndicadoresDisponiveis}
                        indicadoresSelecionados={indicadoresSelecionados}
                        setIndicadoresSelecionados={setIndicadoresSelecionados}
                        orderAsc={orderAsc}
                        setOrderAsc={setOrderAsc}
                        hasRankingChanges={hasRankingChanges}
                        setHasRankingChanges={setHasRankingChanges}
                        onSave={() => handleSave({
                            indicadores_desejados: indicadoresSelecionados,
                            indicadores_desejados_asc: indicadoresSelecionados.map((ind) => orderAsc[ind]),
                        }, 'Ranking')}
                        message="Configure os indicadores e a ordem para o ranking de ativos."
                    />
                </>
            )}

            <CustomSnackbar
                open={snackbar.open}
                message={snackbar.message}
                severity={snackbar.severity}
                onClose={() => setSnackbar({ ...snackbar, open: false })}
                position={snackbar.position}
            />
        </Box>
    );
};

export default ConfiguracoesCreditoPrivado;
