import React, { useState, useEffect, useCallback } from 'react';
import { Box, Typography } from '@mui/material';
import GenericTable from '../../components/GenericTable';
import Pagination from '../../components/Pagination';
import { fetchClassificacaoAtivosClassificadosPaginado } from '../../services/rendaVariavel';
import CustomSnackbar from '../../components/CustomSnackbar';

const AtivosClassificadosRendaVariavel = () => {
    const [rows, setRows] = useState([]);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [totalPages, setTotalPages] = useState(1);
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success',
        title: '',
    });

    const columns = [
        { label: 'Ticker B3', field: 'TICKER B3', width: '20%' },
        { label: 'Nomenclatura', field: 'DENOM_MONT(igual da B3)', width: '25%' },
        { label: 'ISIN', field: 'isin', width: '20%' },
        { label: 'CNPJ', field: 'cnpj', width: '15%' },
        { label: 'Segmento', field: 'Segmentos(GESTORA)', width: '10%' },
        { label: 'Tipo de Ativo', field: 'Tipo Ativo(GESTORA)', width: '10%' },
    ];

    const fetchData = useCallback(async () => {
        try {
            const data = await fetchClassificacaoAtivosClassificadosPaginado(page, limit);
            setRows(data.ticker_b3);
            setTotalPages(data.totalPages || 1);
        } catch (error) {
            setSnackbar({
                open: true,
                message: 'Erro ao carregar dados dos ativos classificados.',
                severity: 'error',
                title: 'Erro',
            });
        }
    }, [page, limit]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const handleSnackbarClose = () => {
        setSnackbar((prev) => ({ ...prev, open: false }));
    };

    return (
        <Box sx={{ width: '100%', padding: '20px' }}>
            <Typography variant="h4" gutterBottom>
                Ativos Classificados
            </Typography>
            <GenericTable
                data={rows}
                columns={columns}
                emptyMessage="Nenhum ativo classificado disponível."
            />
            <Pagination
                totalPages={totalPages}
                currentPage={page}
                limit={limit}
                onPageChange={setPage}
                onLimitChange={(newLimit) => {
                    setLimit(newLimit);
                    setPage(1);
                }}
            />
            <CustomSnackbar
                open={snackbar.open}
                message={snackbar.message}
                severity={snackbar.severity}
                title={snackbar.title}
                onClose={handleSnackbarClose}
            />
        </Box>
    );
};

export default AtivosClassificadosRendaVariavel;
