import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Box } from '@mui/material';
import { useThemeContext } from '../context/ThemeContext';
import UserBreadcrumbs from '../components/UserBreadcrumbs';

const UserLayout = () => {
  const { darkMode, toggleDarkMode } = useThemeContext();

  return (
    <>
      <Header darkMode={darkMode} toggleDarkMode={toggleDarkMode} />
      <Box component="main" sx={{ flex: 1, padding: '20px' }}>
        <UserBreadcrumbs />
        <Outlet />
      </Box>
      <Footer />
    </>
  );
};

export default UserLayout;
