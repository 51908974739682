import React, { useState } from "react";
import {
  Stack,
  Box,
  Card,
  CardHeader,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  Checkbox,
  ListItemText,
  Button,
  TextField,
  Typography,
  useTheme
} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

const TransferList = ({
  availableItems,
  selectedItems,
  onChange,
  titleAvailable,
  titleSelected,
}) => {
  const [checked, setChecked] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const theme = useTheme();

  // Filtra os itens disponíveis pelo termo de busca
  const filteredAvailableItems = availableItems.filter((item) =>
    item.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Funções auxiliares para manipular arrays
  const not = (a, b) => a.filter((item) => !b.some((i) => i.id === item.id));
  const intersection = (a, b) =>
    a.filter((item) => b.some((i) => i.id === item.id));

  // Alterna a seleção de um item
  const handleToggle = (item) => () => {
    const currentIndex = checked.findIndex((i) => i.id === item.id);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(item);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  const availableChecked = intersection(checked, availableItems);
  const selectedChecked = intersection(checked, selectedItems);

  // Move itens selecionados de "available" para "selected"
  const handleAdd = () => {
    onChange({
      available: not(availableItems, availableChecked),
      selected: selectedItems.concat(availableChecked),
    });
    setChecked(not(checked, availableChecked));
  };

  // Move itens selecionados de "selected" para "available"
  const handleRemove = () => {
    onChange({
      available: availableItems.concat(selectedChecked),
      selected: not(selectedItems, selectedChecked),
    });
    setChecked(not(checked, selectedChecked));
  };

  // Renderiza cada Card (coluna)
  const renderCard = (items, title, showSearch = false) => (
    <Card
      variant="outlined"
      sx={{
        width: 300,
        height: 420,
        display: "flex",
        flexDirection: "column",
        borderRadius: 2,
        boxShadow: theme.shadows[1],
      }}
    >
      <CardHeader
        title={<Typography variant="subtitle1">{title}</Typography>}
        sx={{ textAlign: "center", py: 1 }}
      />
      <Divider />
      {showSearch && (
        <Box sx={{ p: 1 }}>
          <TextField
            fullWidth
            placeholder="Buscar..."
            variant="outlined"
            size="small"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </Box>
      )}
      <Divider />
      <List
        dense
        sx={{
          flex: 1,
          overflowY: "auto",
          px: 1,
          py: 0.5,
        }}
      >
        {items.map((item) => {
          const labelId = `transfer-list-item-${item.id}-label`;
          return (
            <ListItem
              key={item.id}
              button
              onClick={handleToggle(item)}
              sx={{
                borderRadius: 1,
                "&:hover": {
                  backgroundColor: theme.palette.action.hover,
                },
              }}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.some((i) => i.id === item.id)}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ "aria-labelledby": labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={item.name} />
            </ListItem>
          );
        })}
      </List>
    </Card>
  );

  return (
    <Stack
      direction="row"
      spacing={3}
      justifyContent="center"
      alignItems="center"
      sx={{ mt: 2 }}
    >
      {/* Card de Disponíveis (com busca) */}
      {renderCard(filteredAvailableItems, titleAvailable, true)}

      {/* Botões centrais */}
      <Stack spacing={2}>
        <Button
          variant="contained"
          size="small"
          onClick={handleAdd}
          disabled={availableChecked.length === 0}
          startIcon={<ArrowForwardIosIcon fontSize="small" />}
        >
          Adicionar
        </Button>
        <Button
          variant="contained"
          size="small"
          color="error"
          onClick={handleRemove}
          disabled={selectedChecked.length === 0}
          startIcon={<ArrowBackIosIcon fontSize="small" />}
        >
          Remover
        </Button>
      </Stack>

      {/* Card de Selecionados */}
      {renderCard(selectedItems, titleSelected)}
    </Stack>
  );
};

export default TransferList;
