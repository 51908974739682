import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import { Box, TextField, IconButton, CircularProgress, List, ListItem, ListItemText, InputAdornment, useTheme } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { debounce } from 'lodash';
import { buscarFundos } from '../services/fundosInvestimentos';
import { applyCNPJFormat } from '../components/utils/formatsUtils';

const SearchBoxFundos = ({ onSelectFund }) => {
  const theme = useTheme();
  const [searchText, setSearchText] = useState('');
  const [resultados, setResultados] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isListVisible, setIsListVisible] = useState(false);
  const searchBoxRef = useRef(null);
  const listRef = useRef(null);

  // Memoriza a função debounced para evitar recriações a cada render
  const debouncedSearch = useMemo(
    () =>
      debounce(async (value) => {
        setIsLoading(true);
        try {
          const response = await buscarFundos(value);
          const fundos = response.fundos || [];
          const listaFundos = fundos.map((fundo) => ({
            cnpj_fundo: fundo.CNPJ_Fundo,
            nome_fundo: fundo.Denominacao_Fundo,
            cnpj_classe: fundo.CNPJ_Classe,
            classe: fundo.Denominacao_Classe,
            id_subclasse: fundo.ID_Subclasse,
            subclasse: fundo.Denominacao_Subclasse || "Sem subclasse",
          }));
          setResultados(listaFundos);
          setIsLoading(false);
          setIsListVisible(listaFundos.length > 0);
        } catch (error) {
          console.error("Erro ao buscar fundos:", error);
          setIsLoading(false);
        }
      }, 300),
    []
  );

  const onSearchChange = (event) => {
    const value = event.target.value;
    setSearchText(value);

    if (value.length > 1) {
      setIsLoading(true);
      debouncedSearch(value);
    } else {
      setResultados([]);
      setIsListVisible(false);
      debouncedSearch.cancel();
      setIsLoading(false);
    }
  };

  const clearSearch = () => {
    setSearchText('');
    setResultados([]);
    setIsListVisible(false);
    debouncedSearch.cancel();
    setIsLoading(false);
  };

  const handleSelectFund = (cnpj_classe, classe, id_subclasse, subclasse) => {
    onSelectFund(cnpj_classe, classe, id_subclasse, subclasse);
    setIsListVisible(false); // Esconde a lista
    // Força o input a perder o foco
    searchBoxRef.current.querySelector('input')?.blur();
  };

  const handleClickOutside = useCallback((event) => {
    if (
      isListVisible &&
      searchBoxRef.current && !searchBoxRef.current.contains(event.target) &&
      listRef.current && !listRef.current.contains(event.target)
    ) {
      setIsListVisible(false);
    }
  }, [isListVisible]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      debouncedSearch.cancel(); // Cancela chamadas pendentes ao desmontar
    };
  }, [handleClickOutside, debouncedSearch]);

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', position: 'relative', width: '100%', maxWidth: '70%', margin: '0 auto' }} ref={searchBoxRef}>
      <TextField
        variant="outlined"
        placeholder="Digite o nome/CNPJ do fundo ou classe"
        value={searchText}
        onChange={onSearchChange}
        onFocus={() => setIsListVisible(searchText.length > 1)}
        autoComplete="off" // ← Aqui!
        fullWidth
        sx={{ 
          borderRadius: '8px',
          backgroundColor: theme.palette.background.default,
          color: theme.palette.text.primary,
        }}
        slotProps={{
          input: {
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={clearSearch}>
                  <CloseIcon sx={{ color: theme.palette.error.main }} />
                </IconButton>
              </InputAdornment>
            ),
          }
        }}
      />
      {isLoading && (
        <CircularProgress 
          size={24} 
          sx={{ position: 'absolute', right: 40, top: '50%', marginTop: '-12px', color: theme.palette.primary.main }} 
        />
      )}
      {isListVisible && (
        <Box 
          sx={{ 
            position: 'absolute', 
            top: '100%', 
            width: '100%', 
            mt: 1, 
            zIndex: 1300 
          }} 
          ref={listRef}>
          <List sx={{ 
            maxHeight: '300px',
            overflow: 'auto',
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: '8px',
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[3], // leve sombra
          }}>
            {resultados.map((fundo) => (
              <ListItem 
                key={`${fundo.cnpj_classe}-${fundo.id_subclasse}`} 
                sx={{
                  cursor: 'pointer',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'start',
                  gap: '4px',
                  padding: '8px 12px',
                  '&:hover': { backgroundColor: theme.palette.action.hover },
                }}
                onMouseDown={(e) => {
                  e.preventDefault();
                  handleSelectFund(
                    fundo.cnpj_classe, 
                    fundo.classe, 
                    fundo.id_subclasse, 
                    fundo.subclasse
                  );
                }}
              >
                <ListItemText 
                  primary={
                    <span style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                      <span style={{ fontWeight: 'bold', fontSize: '1rem', lineHeight: '1.3' }}>
                        {fundo.nome_fundo}
                      </span>
                      <span style={{ fontSize: '0.85rem', color: theme.palette.text.secondary }}>
                        <strong>CNPJ Fundo:</strong> {applyCNPJFormat(fundo.cnpj_fundo)}
                      </span>
                    </span>
                  }
                  secondary={
                    <span style={{ display: 'flex', flexDirection: 'column', gap: '6px', marginTop: '6px' }}>
                      <span style={{ fontSize: '0.85rem' }}>
                        <strong>Classe:</strong> {fundo.classe}
                      </span>
                      <span style={{ fontSize: '0.8rem', color: theme.palette.text.secondary }}>
                        <strong>CNPJ Classe:</strong> {applyCNPJFormat(fundo.cnpj_classe)}
                      </span>
                      <span style={{ fontSize: '0.85rem' }}>
                        <strong>Subclasse:</strong> {fundo.subclasse}
                      </span>
                      <span style={{ fontSize: '0.8rem', color: theme.palette.text.secondary }}>
                        <strong>ID Subclasse:</strong> {fundo.id_subclasse}
                      </span>
                    </span>
                  }
                  primaryTypographyProps={{ color: theme.palette.text.primary }}
                  secondaryTypographyProps={{ color: theme.palette.text.secondary }}
                />
              </ListItem>
            ))}
          </List>
        </Box>
      )} 
    </Box>
  );
};

export default React.memo(SearchBoxFundos);
