// Função de login que chama a API e retorna os tokens
import { apiWithoutAuth ,apiWithAuth, handleApiError } from './api';  // Importa as instâncias de API com e sem autenticação
import qs from 'qs';  // Importa a biblioteca para formatar os dados no estilo x-www-form-urlencoded


  
// Função de login que chama a API e retorna os tokens
export const login = async (username, password) => {
  try {
    const response = await apiWithoutAuth.post(
      '/auth/token',
      qs.stringify({ username, password }),  // Formata os dados para x-www-form-urlencoded
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    );

    return response.data;  // Retorna o access_token e refresh_token
  } catch (error) {
    // Identifica o tipo de erro e lança uma mensagem específica
    if (error.response?.status === 401) {
      throw new Error('Incorrect username or password');
    } else if (error.response?.status === 403) {
      throw new Error('User is disabled.');
    } else {
      throw new Error('Server is currently unavailable. Please try again later.');
    }
  }
};

// Função de logout que recebe a instância da API autenticada
export const logout = async (api) => {
  try {
    const response = await api.post('/auth/logout');  // Faz a requisição de logout
  //   sessionStorage.removeItem('access_token');  // Remove o access_token
  //   sessionStorage.removeItem('refresh_token');  // Remove o refresh_token
    return response.data;
  } catch (error) {
    console.error('Erro durante o logout:', error);
    throw error;
  }
};


// Função para verificar se o usuário tem escopo de admin
export const checkAdminScope = async () => {
try {
  const response = await apiWithAuth.get('/auth/is-admin'); // Chama o endpoint de verificação de admin
  return response.data.is_admin; // Retorna true se for admin, caso contrário false
} catch (error) {
  console.error('Erro ao verificar escopo de admin:', error);
  throw new Error('Erro ao verificar permissões. Tente novamente mais tarde.');
}
};


// Função para verificar se o usuário tem escopo de admin
export const fetchUsers= async () => {
  try {
    const response = await apiWithAuth.get('/auth/users/'); // Chama o endpoint de verificação de admin
    return response.data.users; // Retorna true se for admin, caso contrário false
  } catch (error) {
    console.error('Erro ao obter usuários:', error);
    throw new Error('Erro ao obter usuários. Tente novamente mais tarde.');
  }
};

export const createUsers = async (data) => {
  try {
    const response = await apiWithAuth.post('/auth/users/create', data);
    return response.data;
  } catch (error) {
    throw handleApiError(error, {
      400: "O nome de usuário já está em uso.",
      404: "Usuário não encontrado.",
    });
  }
};

// Criar usuários em massa , a partir de um arquivo CSV ou EXCEL
export const importUsers = async (file) => {
  const formData = new FormData();
  formData.append("file", file);

  const response = await apiWithAuth.post("/auth/users/create/import", formData, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });

  return response.data;
};


// Atualizar
export const updateUser = async (username, updateData) => {
  try {
    const response = await apiWithAuth.patch(`/auth/users/${username}`, updateData);
    return response.data;
  } catch (error) {
    console.error('Erro ao atualizar usuário :', error);
    throw error;
  }
};

// ===================== GRUPOS =====================

// Listar todos os grupos
export const fetchGroups = async () => {
  try {
    const response = await apiWithAuth.get('/auth/groups/');
    return response.data;
  } catch (error) {
    throw handleApiError(error, { 500: "Erro ao obter grupos." });
  }
};

// Criar um grupo
export const createGroup = async (data) => {
  try {
    const response = await apiWithAuth.post('/auth/groups/create', data);
    return response.data;
  } catch (error) {
    throw handleApiError(error, { 400: "O grupo já existe." });
  }
};

// Deletar um grupo
export const deleteGroup = async (groupName) => {
  try {
    const response = await apiWithAuth.delete(`/auth/groups/${groupName}`);
    return response.data;
  } catch (error) {
    throw handleApiError(error, {
      404: "Grupo não encontrado.",
      500: "Erro interno ao excluir o grupo.",
    });
  }
};

// Buscar usuários dentro de um grupo
export const fetchUsersInGroup = async (groupName) => {
  try {
    const response = await apiWithAuth.get(`/auth/groups/${groupName}/users`);
    return response.data;
  } catch (error) {
    throw handleApiError(error, { 404: "Grupo não encontrado." });
  }
};

// Adicionar usuários a um grupo
export const addUsersToGroup = async (groupName, usernames) => {
  try {
    const response = await apiWithAuth.post('/auth/groups/add_users', {
      group_name: groupName,
      usernames,
    });
    return response.data;
  } catch (error) {
    throw handleApiError(error, { 400: "Erro ao adicionar usuários." });
  }
};

// Remover usuários de um grupo
export const removeUsersFromGroup = async (groupName, usernames) => {
  try {
    const response = await apiWithAuth.post('/auth/groups/remove_users', {
      group_name: groupName,
      usernames,
    });
    return response.data;
  } catch (error) {
    throw handleApiError(error, { 400: "Erro ao remover usuários." });
  }
};

// ===================== PERMISSÕES =====================

// Listar todas as permissões disponíveis
export const fetchPermissions = async () => {
  try {
    const response = await apiWithAuth.get('/auth/permissions/');
    return response.data.permissions;
  } catch (error) {
    throw handleApiError(error, { 500: "Erro ao obter permissões." });
  }
};

// Buscar permissões dentro de um grupo
export const fetchGroupPermissions = async (groupName) => {
  try {
    const response = await apiWithAuth.get(`/auth/groups/${groupName}/permissions`);
    return response.data;
  } catch (error) {
    throw handleApiError(error, { 404: "Grupo não encontrado." });
  }
};

// Adicionar permissões a um grupo
export const addPermissionsToGroup = async (groupName, permissionNames) => {
  try {
    const response = await apiWithAuth.post('/auth/groups/add_permissions', {
      group_name: groupName,
      permission_names: permissionNames,
    });
    return response.data;
  } catch (error) {
    throw handleApiError(error, { 400: "Erro ao adicionar permissões." });
  }
};

// Remover permissões de um grupo
export const removePermissionsFromGroup = async (groupName, permissionNames) => {
  try {
    const response = await apiWithAuth.post('/auth/groups/remove_permissions', {
      group_name: groupName,
      permission_names: permissionNames,
    });
    return response.data;
  } catch (error) {
    throw handleApiError(error, { 400: "Erro ao remover permissões." });
  }
};

// Criar uma nova permissão
export const createPermission = async (data) => {
  try {
    const response = await apiWithAuth.post('/auth/permissions/create', data);
    return response.data;
  } catch (error) {
    throw handleApiError(error, { 400: "A permissão já existe." });
  }
};

// Deletar uma permissão
export const deletePermission = async (permissionName) => {
  try {
    const response = await apiWithAuth.delete(`/auth/permissions/${permissionName}`);
    return response.data;
  } catch (error) {
    throw handleApiError(error, {
      404: "Permissão não encontrada.",
      500: "Erro ao excluir permissão.",
    });
  }
};