import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, useTheme, Box } from '@mui/material';

const GenericTable = ({ 
    data,                    
    columns,                 
    renderActions,           
    emptyMessage = "Nenhum dado disponível."
}) => {
    const theme = useTheme();

    return (
        <TableContainer component={Paper} sx={{ mt: 2 }}>
            <Table sx={{ minWidth: 650 }} aria-label="Tabela Genérica">
                <TableHead>
                    <TableRow>
                        {columns.map((col, index) => (
                            <TableCell 
                                key={`${col.field}-${index}`} 
                                align="center"
                                sx={{ 
                                    width: col.width || 'auto', 
                                    backgroundColor: theme.palette.primary.main, 
                                    color: theme.palette.primary.contrastText,
                                    borderRight: index < columns.length - 1 || renderActions ? `1px solid ${theme.palette.divider}` : 'none', 
                                }}
                            >
                                <strong>{col.label}</strong>
                            </TableCell>
                        ))}
                        {renderActions && (
                            <TableCell 
                                align="center"
                                sx={{ 
                                    backgroundColor: theme.palette.primary.main, 
                                    color: theme.palette.primary.contrastText
                                }}
                            >
                                <strong>Ações</strong>
                            </TableCell>
                        )}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.length > 0 ? (
                        data.map((row, rowIndex) => (
                            <TableRow key={rowIndex} sx={{ height: 56 }}>
                                {columns.map((col, colIndex) => (
                                    <TableCell 
                                        key={`${rowIndex}-${colIndex}`} // Evita conflito de keys
                                        align="center"
                                        sx={{ 
                                            borderRight: colIndex < columns.length - 1 || renderActions ? `1px solid ${theme.palette.divider}` : 'none',
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis'
                                        }}
                                    >
                                        {row[col.field] ?? ""} {/* Retorna string vazia se for null/undefined */}
                                    </TableCell>
                                ))}
                                {renderActions && (
                                    <TableCell align="center">
                                        <Box display="flex" justifyContent="center" gap={1}>
                                            {renderActions(row) || null} {/* Evita renderizar undefined */}
                                        </Box>
                                    </TableCell>
                                )}
                            </TableRow>
                        ))
                    ) : (
                        <TableRow>
                            <TableCell 
                                colSpan={columns.length + (renderActions ? 1 : 0)} 
                                align="center"
                            >
                                {emptyMessage}
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default GenericTable;