// components/Charts/HighStockChart.jsx
import React from 'react';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import exporting from 'highcharts/modules/exporting';
import exportData from 'highcharts/modules/export-data';
import offlineExporting from 'highcharts/modules/offline-exporting';
import Accessibility from 'highcharts/modules/accessibility';

// Ativa os módulos de acessibilidade e exportação
Accessibility(Highcharts);
exporting(Highcharts);
exportData(Highcharts);
offlineExporting(Highcharts);

const HighStockChart = React.memo(({ title, series, yAxisTitle, suffix = '', height = 600 }) => {
  const options = {
    rangeSelector: {
      selected: 6,
    },
    chart: {
      height,
    },
    title: {
      text: title,
    },
    yAxis: {
      title: {
        text: yAxisTitle,
      },
    },
    series: series,
    tooltip: {
      valueDecimals: 2,
      valueSuffix: suffix, // Sufixo opcional para o tooltip
    },
    xAxis: {
      type: 'datetime',
    },
    plotOptions: {
      series: {
        showInNavigator: true,
      },
    },
    credits: {
      enabled: false, // Remove a marca d'água do Highcharts
    },
    legend: {
      enabled: true,
      layout: 'horizontal',
      align: 'center',
      verticalAlign: 'bottom',
    },
    exporting: {
      enabled: true, // Habilita o menu de exportação
      csv: {
        dateFormat: '%Y-%m-%d', // Define o formato da data no CSV
      },
      buttons: {
        contextButton: {
          menuItems: [
            'viewFullscreen',
            'printChart',
            'separator',
            'downloadPNG',
            'downloadJPEG',
            'downloadPDF',
            'downloadSVG',
            'separator',
            'downloadCSV',
            'downloadXLS'
          ],
        },
      },
      // Configurações adicionais para melhorar a qualidade das exportações de imagem
      sourceWidth: 800,
      sourceHeight: 600,
    },
    navigation: {
      buttonOptions: {
        theme: {
          fill: '#f7f7f7',
          stroke: '#e6e6e6',
          r: 4,
          states: {
            hover: {
              fill: '#e6e6e6',
            },
            select: {
              stroke: '#039',
              fill: '#bada55',
            },
          },
        },
      },
    },
  };

  return <HighchartsReact highcharts={Highcharts} constructorType={'stockChart'} options={options} />;
});

export default HighStockChart;
