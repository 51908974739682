// Formata CNPJ (pode conter letras e números)
export const applyCNPJFormat = (value) => {
    return value
        .replace(/[^a-zA-Z0-9]/g, '') // Remove caracteres que não são letras ou números
        .replace(/([a-zA-Z0-9]{2})([a-zA-Z0-9])/, '$1.$2')
        .replace(/([a-zA-Z0-9]{3})([a-zA-Z0-9])/, '$1.$2')
        .replace(/([a-zA-Z0-9]{3})([a-zA-Z0-9])/, '$1/$2')
        .replace(/([a-zA-Z0-9]{4})([a-zA-Z0-9])/, '$1-$2')
        .substring(0, 18); // Limita ao comprimento máximo do CNPJ
};

export const formatCNPJ = (cnpj) => cnpj.replace(/[./-]/g, '');


export const formatDate = (dateString) => {
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    return new Date(dateString).toLocaleDateString('pt-BR', options);
  };
  

