import React, { useState, useEffect } from 'react';
import { fundosRanking, fundosAprovados, enviarFundosAprovados, fundosStatsCnpjID } from '../../services/fundosInvestimentos';
import DataTable from '../../components/DataTable';
import { Box, Typography, Button } from '@mui/material';
import CustomSnackbar from '../../components/CustomSnackbar';
import ModalFundo from '../../components/Modal/ModalFundo';
import { criarChartData, RentSum } from '../../components/utils/chartUtils' // '../utils/chartUtils';
import NoDataMessage from '../../components/NoDataMessage';

const RankingFund = () => {
    const [rows, setRows] = useState([]);
    const [approvedRows, setApprovedRows] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedFunds, setSelectedFunds] = useState([]);
    const [, setApprovedCNPJs] = useState([]);
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
    const [modalOpen, setModalOpen] = useState(false);
    const [infoGerais, setInfoGerais] = useState([]);
    const [estatisticas, setEstatisticas] = useState([]);
    const [graficoData, setGraficoData] = useState(null);
    const [modalLoading, setModalLoading] = useState(false);

    const tipo_endpoint = "fund";


    // Definindo colunas padrão (sem checkbox)
    const defaultColumns = [
        { field: "CNPJ_Classe", headerName: "CNPJ da Classe", width: 200, description: "CNPJ da Classe do Fundo" },
        { field: "ID_Subclasse", headerName: "ID Subclasse", width: 150, description: "ID da Subclasse do Fundo" },
        { field: "RENTMED_AT%", headerName: "Rent. Média %", width: 150, description: "Rentabilidade Média Anual média do Fundo (%)" },
        { field: "RENT_%DI+", headerName: "Rent. %DI+", width: 150, description: "Rentabilidade (Spread Anual) média do Fundo em relação ao CDI (%)" },
        { field: "RENT_%IBOV+", headerName: "Rent. %IBOV+", width: 150, description: "Rentabilidade (Spread Anual) média do Fundo em relação ao IBOV (%)" },
        { field: "RENT_%IMABG+", headerName: "Rent. %IMABG+", width: 150, description: "Rentabilidade (Spread Anual) média do Fundo em relação ao IMAB Geral (%)" },
        { field: "DP(AT)%", headerName: "DP(AT)%", width: 150, description: "Média do desvio padrão anualizado do Fundo (%)" },
        { field: "CORR(AT,IBOV)", headerName: "Correlação (AT, IBOV)", width: 150, description: "Média da correlação do Fundo em relação ao Ibovespa (d.u.)" },
        { field: "BETA", headerName: "Beta", width: 150, description: "Média do Beta do Fundo (d.u.)" },
        { field: "SHARPE", headerName: "Sharpe", width: 150, description: "Média do Sharpe do Fundo" },
        { field: "alfa_jensen", headerName: "Alfa Jensen", width: 150, description: "Média do Alfa de Jensen Anual" },
        { field: "Risco_Fundo", headerName: "Risco do Fundo", width: 150, description: "Risco do Fundo" },
        { field: "MÍNIMO_SHARPE_ACEITAVEL%", headerName: "Mín. Sharpe Aceitável %", width: 180, description: "Porcentagem mínima para o Sharpe ser aceitável" },
        { field: "PORCENT_MAIOR_X_SHARPE", headerName: "Porcent. Maior x Sharpe", width: 180, description: "Porcentagem que o Fundo é maior que a referência estabelecida para Sharpe" },
        { field: "MÍNIMO_ALFA_JENSEN_ACEITAVEL%", headerName: "Mín. Alfa Jensen Aceitável %", width: 200, description: "Porcentagem mínima para o Alfa de Jensen ser aceitável" },
        { field: "PORCENT_MAIOR_X_ALFA_JENSEN", headerName: "Porcent. Maior x Alfa Jensen", width: 200, description: "Porcentagem que o Fundo é maior que a referência estabelecida para Alfa de Jensen" },
        { field: "Denominacao_Mont", headerName: "Nome do Fundo", width: 300, description: "Nomenclatura Mont do Fundo de Investimento" },
        { field: "Publico_Alvo", headerName: "Público-Alvo", width: 150, description: "Público Alvo do Fundo" },
        { field: "Classe_Cod_Mont", headerName: "Classe Código Mont", width: 150, description: "Código da Classe para Mont" },
        { field: "Classe_Mont", headerName: "Classe Mont", width: 150, description: "Substituições de nomenclatura para padrões Mont" },
        { field: "Tipo_Ativo(Gestora)", headerName: "Tipo Ativo (Gestora)", width: 200, description: "Tipo de Ativo" },
        { field: "CORRETORA(S/N)", headerName: "Corretora (S/N)", width: 150, description: "Está em alguma corretora parceira (Sim:true; Não:false)" , type: "boolean"},
        { field: "CORRETORAS(NOMES)", headerName: "Corretoras (Nomes)", width: 200, description: "Siglas das corretoras parceiras que o fundo está presente" },
        { field: "Indicador_Desempenho", headerName: "Rentabilidade do Fundo", width: 200, description: "Rentabilidade do Fundo" },
        { field: "Classe_Cotas", headerName: "Classe de Cotas", width: 150, description: "Fundo de Cotas (Sim:true; Não:false)", type: 'boolean'  },
        { field: "Tributacao_Longo_Prazo", headerName: "Tributação Longo Prazo", width: 200, description: "Tributação de Longo Prazo" , type: 'boolean' },
        { field: "Administrador", headerName: "Administrador", width: 200, description: "Nome da Administradora do Fundo" },
        { field: "Gestor", headerName: "Gestor", width: 200, description: "CPF/CNPJ da Empresa Gestora do Fundo" },
      ];
      
    // Colunas para a tabela de Ranking com checkbox de Aprovados
    const rankingColumns = [
        {
            field: 'Aprovados',
            headerName: 'Aprovados',
            width: 150,
            renderCell: (params) => (
                <input
                    type="checkbox"
                    id={`checkbox-${params.row.CNPJ_Classe}-${params.row.ID_Subclasse}`}
                    name={`checkbox-${params.row.CNPJ_Classe}-${params.row.ID_Subclasse}`}
                    checked={selectedFunds.some(fund => 
                        fund.CNPJ_Classe === params.row.CNPJ_Classe && 
                        fund.ID_Subclasse === params.row.ID_Subclasse
                    )}
                    onChange={() => handleSelect(params.row.CNPJ_Classe, params.row.ID_Subclasse)}
                />
            ),
            
            
        },
        ...defaultColumns,
    ];

    // Colunas para a tabela de Fundos Aprovados
    const approvedColumns = [
        { field: 'Aprovados', headerName: 'Aprovados', width: 100 },
        ...defaultColumns,
        { field: 'FUND/PREV', headerName: 'FUND/PREV', width: 100 },
    ];

    //----------------- Modal -------------------------------------//
    // Função para abrir o modal ao clicar na linha da tabela
    const handleRowClick = async (params) => {
        if (params.field !== "Aprovados" ) {
            setModalLoading(true); // Ativa o loading do modal
            setModalOpen(true);
            const cnpj = params.row.CNPJ_Classe.replace(/\.|\/|-/g, '');
            const id_subclasse = params.row.ID_Subclasse;
            const infoGeraisData = [
                { label: 'Nome do Fundo', value: params.row.Denominacao_Mont },
                { label: 'CNPJ', value: params.row.CNPJ_Classe },
                { label: 'ID Subclasse', value: id_subclasse },
                { label: 'Público Alvo', value: params.row.Publico_Alvo },
                { label: 'Corretoras', value: params.row['CORRETORAS(NOMES)'] },
                { label: 'Classe Código Mont', value: params.row.Classe_Cod_Mont },
                { label: 'Tipo Ativo', value: params.row['TIPO_ATIVO(GESTORA)'] },
                { label: 'Risco', value: params.row.Risco_Fundo },
            ];

            const estatisticasData = [
                { label: 'Rentabilidade Fundo', value: `${params.row['RENTMED_AT%']}%` },
                { label: 'Rentabilidade media em relação ao CDI (%)', value: `${params.row['RENT_%DI+']}%` },
                { label: 'Rentabilidade media em relação ao IBOV (%)', value: `${params.row['RENT_%IBOV+']}%` },
                { label: 'Rentabilidade media em relação ao IMABG (%)', value: `${params.row['RENT_%IMABG+']}%` },
                { label: 'Risco (Desvio Padrão)', value: `${params.row['DP(AT)%']}%` },
                { label: 'BETA', value: params.row.BETA },
                { label: 'SHARPE', value: params.row.SHARPE },
                { label: 'alfa de jensen', value: params.row['alfa_jensen'] },
            ];

            setInfoGerais(infoGeraisData);
            setEstatisticas(estatisticasData);

            try {
                const response = await fundosStatsCnpjID(cnpj,id_subclasse);

                const nomeFundo =  params.row.Denominacao_Mont;
        
                const RentMovelFundoGraph = [
                    { name: nomeFundo, data: criarChartData('RENTMED_AT%', response) },
                    { name: 'DI', data: criarChartData('RENTMED_DI%', response) },
                    { name: 'IBOV', data: criarChartData('RENTMED_IBOV%', response) },
                    { name: 'IMAB-GERAL', data: criarChartData('RENTMED_IMABG%', response) }
                ];   
                const RentSumFundoGraph = [
                    { name: nomeFundo, data: RentSum('RENTLN', response) },
                    { name: 'DI', data: RentSum('rentln_cdi', response) },
                    { name: 'IBOV', data: RentSum('rentln_IBOV', response) },
                    { name: 'IMAB-GERAL', data: RentSum('rentln_imab', response) }
                ];

                // Variavel do gráfico da volatilidade do Fundo
                const DPFundoGraph = [
                    { name: nomeFundo, data: criarChartData('DP(AT)%', response) }
                ];
                // Variavel do gráfico da volatilidade do Fundo
                const SharpeFundoGraph = [
                    { name: nomeFundo, data: criarChartData('SHARPE', response) }
                ];

                const BetaFundoGraph = [
                    { name: nomeFundo, data: criarChartData('BETA', response) }
                ];

                const AlfaJensenFundoGraph = [
                    { name: nomeFundo, data: criarChartData('alfa_jensen', response) }
                ];

                const CorrIndFundoGraph = [
                    { name: 'Corr IBOV', data: criarChartData('CORR(AT,IBOV)', response) },
                    { name: 'Corr IMAB-GERAL', data: criarChartData('CORR(AT,IMABG)', response) }
                ];



                const DrawdownFundoGraph = [
                    { name: nomeFundo, data: criarChartData('DRAWDOWN', response) }
                ];

                setGraficoData([
                    { title: 'Rentabilidade Média Móvel', series: RentMovelFundoGraph, yAxisTitle: 'Rentabilidade (%)' ,suffix: "%",height: "55%"},
                    { title: 'Rentabilidade Acumulada', series: RentSumFundoGraph, yAxisTitle: 'Rentabilidade (%)' ,suffix: "%",height: "55%"},
                    { title: 'Volatilidade do Fundo', series: DPFundoGraph, yAxisTitle: 'Volatilidade (%)' ,suffix: "%",height: "55%"},
                    { title: 'SHARPE do Fundo', series: SharpeFundoGraph, yAxisTitle: 'SHARPE' ,height: "55%"},
                    { title: 'BETA do Fundo', series: BetaFundoGraph, yAxisTitle: 'BETA' ,height: "55%"},
                    { title: 'Alda de Jensen do Fundo', series: AlfaJensenFundoGraph, yAxisTitle: 'Alfa de Jensen' ,height: "55%"},
                    { title: 'Correlação do Fundo', series: CorrIndFundoGraph, yAxisTitle: 'Correlação' ,height: "55%"},
                    { title: 'DRAWDOWN', series: DrawdownFundoGraph, yAxisTitle: 'DRAWDOWN (%)',suffix: "%" ,height: "55%"},
                    
                ]); // Dados para os gráficos


                // setModalOpen(true);
            } catch (error) {
                console.error('Erro ao carregar os dados do fundo:', error);
                setSnackbar({ open: true, message: 'Erro ao carregar os dados do fundo.', severity: 'error' , position: { vertical: 'top', horizontal: 'center' }});
            } finally {
                setModalLoading(false);
            }
        }
    };

    const handleCloseModal = () => {
        setModalOpen(false);
        setGraficoData(null);
    };

    //----------------- Modal -------------------------------------//

    // Função para buscar os dados de ranking e fundos aprovados
    const fetchData = async () => {
        setLoading(true);
        try {
            const rankingData = await fundosRanking(tipo_endpoint);
            const approvedData = await fundosAprovados(tipo_endpoint);
            // setRows(rankingData);
            setRows(rankingData.map(row => ({
                ...row,
                id: `${row.CNPJ_Classe}-${row.ID_Subclasse}` // Criando um identificador único
            })));
            // setApprovedRows(approvedData);
            setApprovedRows(approvedData.map(row => ({
                ...row,
                id: `${row.CNPJ_Classe}-${row.ID_Subclasse}` // Criando ID único para aprovados também
            })));
            setApprovedCNPJs(approvedData.map(fundo => ({
                CNPJ_Classe: fundo.CNPJ_Classe,
                ID_Subclasse: fundo.ID_Subclasse
            })));
            setSelectedFunds(approvedData.map(fundo => ({
                CNPJ_Classe: fundo.CNPJ_Classe,
                ID_Subclasse: fundo.ID_Subclasse
            })));
        } catch (error) {
            console.error('Erro ao buscar os dados:', error);
            setSnackbar({ open: true, message: 'Erro ao buscar os dados.', severity: 'error', position: { vertical: 'top', horizontal: 'center' } });
        } finally {
            setLoading(false);
        }
    };

    const handleSelect = (cnpj, idSubclasse) => {
        setSelectedFunds(prev =>
            prev.some(fund => fund.CNPJ_Classe === cnpj && fund.ID_Subclasse === idSubclasse)
                ? prev.filter(fund => !(fund.CNPJ_Classe === cnpj && fund.ID_Subclasse === idSubclasse))
                : [...prev, { CNPJ_Classe: cnpj, ID_Subclasse: idSubclasse }]
        );
    };
    

    const handleEnviarAprovados = async () => {
        const selectedData = rows
        .filter((row) => 
            selectedFunds.some(fund => 
                fund.CNPJ_Classe === row.CNPJ_Classe && 
                fund.ID_Subclasse === row.ID_Subclasse
            )
        )
        .map((row) => ({
            ...row,
            Aprovados: true,
            'FUND/PREV': 'FUND',
        }));
    
        
        try {
            await enviarFundosAprovados(tipo_endpoint,selectedData);
            setSnackbar({ open: true, message: 'Fundos enviados com sucesso!', severity: 'success', position: { vertical: 'top', horizontal: 'right' } });
            // fetchData();
            const approvedData = await fundosAprovados(tipo_endpoint);
            setApprovedRows(approvedData.map(row => ({
                ...row,
                id: `${row.CNPJ_Classe}-${row.ID_Subclasse}` // Criando ID único APENAS para uso no frontend
            })));
            setApprovedCNPJs(approvedData.map(fundo => ({
                CNPJ_Classe: fundo.CNPJ_Classe,
                ID_Subclasse: fundo.ID_Subclasse
            })));
            setSelectedFunds(approvedData.map(fundo => ({
                CNPJ_Classe: fundo.CNPJ_Classe,
                ID_Subclasse: fundo.ID_Subclasse
            })));
        } catch (error) {
            console.error('Erro ao enviar fundos aprovados:', error);
            setSnackbar({ open: true, message: error.message, severity: 'error', position: { vertical: 'top', horizontal: 'center' } });
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleCloseSnackbar = () => {
        setSnackbar({ ...snackbar, open: false });
    };

    return (
        <Box>
            <Typography variant="h4" gutterBottom>
                Ranking Fundos 
            </Typography>
            <Box mb={4}>
                <Typography component="p" variant="body1" sx={{ marginBottom: 3 }}>
                    Ranking dos Fundos de Investimentos, a partir dos dados estatísticos selecionados pelo usuário.
                    <br />
                    Obs: Selecionar na ordem de prioridade e se deseja que os dados sejam organizados em Ascendente (do maior para o menor) ou não.
                </Typography>
            </Box>
            <Box mb={4}>
                <DataTable
                    rows={rows}
                    columns={rankingColumns}
                    idColumn="id"  // Chave composta por CNPJ_Classe e ID_Subclasse
                    loading={loading}
                    pageSizeOptions={[10, 25, 50, 100]}
                    onCellClick={handleRowClick}
                />
            </Box>
            <Box mb={2}>
                <Button variant="contained" color="primary" onClick={handleEnviarAprovados}>
                    Enviar Selecionados
                </Button>
            </Box>
            <Typography variant="h4" gutterBottom>
                Fundos Aprovados
            </Typography>
            <Box mb={4}>
                <Typography component="p" variant="body1">
                    Lista de Fundos de Investimentos Aprovados pelo usuário utilizando tanto critérios quantitativos quanto qualitativo.
                </Typography>
            </Box>
            <Box mb={4}>
                {approvedRows.length === 0 && !loading ? (
                    <NoDataMessage
                    message="Nenhum Fundo  aprovado encontrado."
                    subMessage="Adicione ou selecione Fundos  para vê-los aqui."
                    />
                ) : (
                    <DataTable
                    rows={approvedRows}
                    columns={approvedColumns}
                    idColumn="id"
                    loading={loading}
                    pageSizeOptions={[10, 25, 50, 100]}
                    onCellClick={handleRowClick}
                    />
                )}
            </Box>

            <ModalFundo
                modalTitle={"Estatísticas do Fundo"}
                open={modalOpen}
                onClose={handleCloseModal}
                infoGerais={infoGerais}
                estatisticas={estatisticas}
                graficos={graficoData || []}
                loading={modalLoading}
            />

            <CustomSnackbar
                open={snackbar.open}
                message={snackbar.message}
                severity={snackbar.severity}
                position={snackbar.position}
                onClose={handleCloseSnackbar}
            />
        </Box>
    );
};

export default RankingFund;
