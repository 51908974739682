import React, { useState, useEffect, useCallback } from 'react';
import { Box, Typography } from '@mui/material';
import { getConfiguracao, updateConfiguracao } from '../../services/fundosInvestimentos';
import CustomSnackbar from '../../components/CustomSnackbar';
import LoadingIndicator from '../../components/LoadingIndicator';
import { FiltroSection, EstatisticasSection, IndicadoresSection } from '../../components/ParametresSections';

const observacaoRanking = `O Parâmetro de Público_Alvo é somente para o ordenamento de Fundos de Investimento FUND.
A sequência dos indicadores mostra a prioridade na hora de ranquear os Fundos.`;

const ParametrosFundo = () => {
  const [parametros, setParametros] = useState({});
  const [statsParametros, setStatsParametros] = useState({});
  const [publicoAlvo, setPublicoAlvo] = useState("SIM");
  const [indicadoresDisponiveis, setIndicadoresDisponiveis] = useState([]);
  const [indicadoresSelecionados, setIndicadoresSelecionados] = useState([]);
  const [orderAsc, setOrderAsc] = useState({});

  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [hasFiltroChanges, setHasFiltroChanges] = useState(false);
  const [hasStatsChanges, setHasStatsChanges] = useState(false);
  const [hasRankingChanges, setHasRankingChanges] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success', position: { vertical: 'top', horizontal: 'center' } });

  // Função de carregamento inicial dos parâmetros
  const fetchParametros = useCallback(async () => {
    setLoading(true);
    try {
      const filtroData = await getConfiguracao('Filtro_exclusao_ranking_Fundos');
      const statsData = await getConfiguracao('Parametros_Estatisticos');
      const rankingData = await getConfiguracao('Parametros_Ranking');

      const rankingConfig = rankingData?.[0]?.config_data || {
        indicadores_desejados: [],
        indicadores_desejados_asc: [],
      };

      setParametros(filtroData?.[0]?.config_data || {});
      setStatsParametros(statsData?.[0]?.config_data || {});
      setPublicoAlvo(rankingConfig.Publico_Alvo || "SIM");

      // Define indicadores disponíveis removendo os já selecionados
      const allIndicators = ["SHARPE", "alfa_jensen", "Risco_Fundo", "DP(AT)%", "BETA", "RENT_%DI+", "RENT_%IBOV+", "RENT_%IMABG+"];
      setIndicadoresDisponiveis(allIndicators.filter(ind => !rankingConfig.indicadores_desejados.includes(ind)));

      setIndicadoresSelecionados(rankingConfig.indicadores_desejados || []);

      // Define a ordem inicial dos indicadores (ascendente ou descendente)
      const initialOrderAsc = {};
      (rankingConfig.indicadores_desejados_asc || []).forEach((asc, idx) => {
        initialOrderAsc[rankingConfig.indicadores_desejados[idx]] = asc;
      });
      setOrderAsc(initialOrderAsc);

    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Erro ao carregar parâmetros.',
        severity: 'error',
        position: { vertical: 'top', horizontal: 'center' }
      });
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchParametros();
  }, [fetchParametros]);

  // Função unificada para salvar as configurações
  const handleSave = async (parametrosToSave, nome) => {
    setSaving(true);
    try {
      // Constrói o nome da configuração dinamicamente
      await updateConfiguracao(nome, parametrosToSave);
      setSnackbar({
        open: true,
        message: 'Parâmetros atualizados com sucesso!',
        severity: 'success',
        position: { vertical: 'top', horizontal: 'right' }
      });
      // Reseta os flags de alterações conforme o tipo
      switch (nome) {
        case 'Filtro_exclusao_ranking_Fundos':
          setHasFiltroChanges(false);
          break;
        case 'Parametros_Estatisticos':
          setHasStatsChanges(false);
          break;
        case 'Parametros_Ranking':
          setHasRankingChanges(false);
          break;
        default:
          break;
      }
    } catch (error) {
      setSnackbar({
        open: true,
        message: error.message,
        severity: 'error',
        position: { vertical: 'top', horizontal: 'center' }
      });
    } finally {
      setSaving(false);
    }
  };

  return (
    <Box p={4}>
      <Typography variant="h4" gutterBottom>Parâmetros de Fundos</Typography>
      {loading ? (
        <LoadingIndicator message="Carregando parâmetros..." />
      ) : (
        <>
          {/* Seção de Filtro */}
          <FiltroSection 
            parametros={parametros}
            setParametros={setParametros}
            hasFiltroChanges={hasFiltroChanges}
            setHasFiltroChanges={setHasFiltroChanges}
            onSave={() => handleSave(parametros, 'Filtro_exclusao_ranking_Fundos')}
          />

          {/* Seção Estatística */}
          <EstatisticasSection
            statsParametros={statsParametros}
            setStatsParametros={setStatsParametros}
            hasStatsChanges={hasStatsChanges}
            setHasStatsChanges={setHasStatsChanges}
            onSave={() => handleSave(statsParametros, 'Parametros_Estatisticos')}
            savingStats={saving}
          />

          {/* Seção de Indicadores e Ordenação */}
          <IndicadoresSection
            showPublicoAlvo={true}
            publicoAlvo={publicoAlvo}
            setPublicoAlvo={setPublicoAlvo}
            indicadoresDisponiveis={indicadoresDisponiveis}
            setIndicadoresDisponiveis={setIndicadoresDisponiveis}
            indicadoresSelecionados={indicadoresSelecionados}
            setIndicadoresSelecionados={setIndicadoresSelecionados}
            orderAsc={orderAsc}
            setOrderAsc={setOrderAsc}
            hasRankingChanges={hasRankingChanges}
            setHasRankingChanges={setHasRankingChanges}
            onSave={() =>
              handleSave(
                {
                  Publico_Alvo: publicoAlvo,
                  indicadores_desejados: indicadoresSelecionados,
                  indicadores_desejados_asc: indicadoresSelecionados.map(ind => orderAsc[ind])
                },
                'Parametros_Ranking'
              )
            }
            message={observacaoRanking}
          />
        </>
      )}

      <CustomSnackbar
        open={snackbar.open}
        message={snackbar.message}
        severity={snackbar.severity}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        position={snackbar.position}
      />
    </Box>
  );
};

export default ParametrosFundo;
