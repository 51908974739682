import React, { useEffect, useState, useCallback } from 'react';
import { Typography, Box, Paper, Select, MenuItem, TextField, Button,Divider, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, FormControlLabel, Checkbox, Autocomplete  } from '@mui/material';
import { obterFundosAdicionarRegra, obterFundosAdicionarClasseMont,getConfiguracao, atualizarConfiguracaoAdicionarPadraoRegra } from '../../services/fundosInvestimentos';
import DataTable from '../../components/DataTable';
import LoadingIndicator from '../../components/LoadingIndicator';
import CustomSnackbar from '../../components/CustomSnackbar';


const AdicionarPadraoFundosSection = ({ 
    titulo, 
    siglasOptions, 
    setSnackbar, 
    atualizarDados, 
    setFundosPadroes, 
    setPrioridadeSiglas, 
    setSiglasVariacoes 
}) => {
    const [sigla, setSigla] = useState('');
    const [novaSigla, setNovaSigla] = useState('');
    const [novoPadrao, setNovoPadrao] = useState('');
    const [usarSiglaPersonalizada, setUsarSiglaPersonalizada] = useState(false);

    // Estado para associar sigla (se "Outro" for selecionado)
    const [associarSigla, setAssociarSigla] = useState('');
    const [novaAssociarSigla, setNovaAssociarSigla] = useState('');
    const [usarAssociarPersonalizada, setUsarAssociarPersonalizada] = useState(false);

    // Checkbox "Ignorar Siglas" (aparece só se "Outro" for selecionado)
    const [ignorarSiglas, setIgnorarSiglas] = useState(false);

    const handleAddPadrao = async () => {
        const siglaFinal = usarSiglaPersonalizada ? novaSigla : sigla;
        const associarFinal = usarAssociarPersonalizada ? novaAssociarSigla : associarSigla;

        if (!siglaFinal || !novoPadrao) {
            setSnackbar({
                open: true,
                message: 'Preencha todos os campos antes de adicionar.',
                severity: 'warning',
                position: { vertical: 'top', horizontal: 'center' }
            });
            return;
        }

        try {
            const payload = {
                nome_config: "dict_padrao_fundos",
                novos_valores: { [siglaFinal]: [novoPadrao] },
                associar_a_chave: ignorarSiglas ? null : associarFinal || null,
                ignorar_siglas: ignorarSiglas
            };

            await atualizarConfiguracaoAdicionarPadraoRegra(payload);

            setSnackbar({
                open: true,
                message: 'Padrão adicionado com sucesso!',
                severity: 'success',
                position: { vertical: 'top', horizontal: 'right' }
            });

            // **Reseta os estados**
            setSigla('');
            setNovaSigla('');
            setNovoPadrao('');
            setUsarSiglaPersonalizada(false);
            setAssociarSigla('');
            setNovaAssociarSigla('');
            setUsarAssociarPersonalizada(false);
            setIgnorarSiglas(false);

            // **Atualiza os estados relevantes**
            atualizarDados("dict_padrao_fundos", setFundosPadroes);
            atualizarDados("prioridade_siglas", setPrioridadeSiglas);

            if (!ignorarSiglas) {
                atualizarDados("siglas_substituicoes_variacoes", setSiglasVariacoes);
            }
        } catch (error) {
            setSnackbar({
                open: true,
                message: `Erro ao adicionar padrão: ${error.message}`,
                severity: 'error',
                position: { vertical: 'top', horizontal: 'center' }
            });
        }
    };

    return (
        <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
            <Typography variant="h6">{titulo}</Typography>
            <Box display="flex" flexDirection="column" gap={2} mt={2}>

                {/* Autocomplete com Scroll para selecionar sigla */}
                <Autocomplete
                    options={[...siglasOptions, "Outro"]}
                    value={sigla || null}
                    onChange={(event, newValue) => {
                        setSigla(newValue);
                        setUsarSiglaPersonalizada(newValue === "Outro");
                    }}
                    disableCloseOnSelect
                    renderInput={(params) => (
                        <TextField 
                            {...params} 
                            label="Selecione a Sigla" 
                            variant="outlined"
                            fullWidth 
                        />
                    )}
                    sx={{ width: '100%' }}
                />

                {/* Se "Outro" for selecionado, mostra campo para digitar nova sigla */}
                {usarSiglaPersonalizada && (
                    <TextField
                        label="Digite a nova Sigla"
                        value={novaSigla}
                        onChange={(e) => setNovaSigla(e.target.value.toUpperCase())}
                        variant="outlined"
                        fullWidth
                    />
                )}

                {/* Input para novo padrão */}
                <TextField
                    label="Novo Padrão"
                    value={novoPadrao}
                    onChange={(e) => setNovoPadrao(e.target.value.toUpperCase())}
                    variant="outlined"
                    fullWidth
                />

                {/* Checkbox para ignorar siglas (Só aparece se "Outro" for selecionado) */}
                {usarSiglaPersonalizada && (
                    <FormControlLabel
                        control={
                            <Checkbox 
                                checked={ignorarSiglas} 
                                onChange={(e) => setIgnorarSiglas(e.target.checked)} 
                            />
                        }
                        label="Ignorar Siglas para Adição em Siglas Substituições e Prioridade Siglas"
                    />
                )}

                {/* Se NÃO ignorar siglas, exibe dropdown para associar sigla */}
                {usarSiglaPersonalizada && !ignorarSiglas && (
                    <Autocomplete
                        options={[...siglasOptions, "Outro"]}
                        value={associarSigla || null}
                        onChange={(event, newValue) => {
                            setAssociarSigla(newValue);
                            setUsarAssociarPersonalizada(newValue === "Outro");
                        }}
                        disableCloseOnSelect
                        renderInput={(params) => (
                            <TextField 
                                {...params} 
                                label="Associar à Sigla" 
                                variant="outlined"
                                fullWidth 
                            />
                        )}
                        sx={{ width: '100%' }}
                    />
                )}

                {/* Se associar sigla for "Outro", input para nova sigla */}
                {usarSiglaPersonalizada && !ignorarSiglas && usarAssociarPersonalizada && (
                    <TextField
                        label="Digite a sigla a ser associada"
                        value={novaAssociarSigla}
                        onChange={(e) => setNovaAssociarSigla(e.target.value.toUpperCase())}
                        variant="outlined"
                        fullWidth
                    />
                )}

                <Button variant="contained" color="primary" onClick={handleAddPadrao}>
                    Adicionar
                </Button>
            </Box>
        </Paper>
    );
};

const AdicionarPadraoFundosOutrosSection = ({ titulo, siglasOptions, tipoPadrao, setSnackbar, atualizarDados, setFundosOutrosPadroes ,setPrioridadeSiglas}) => {
    const [sigla, setSigla] = useState('');
    const [novaSigla, setNovaSigla] = useState(''); // Para armazenar a sigla personalizada
    const [novoPadrao, setNovoPadrao] = useState('');
    const [usarSiglaPersonalizada, setUsarSiglaPersonalizada] = useState(false);

    const handleAddPadrao = async () => {
        const siglaFinal = usarSiglaPersonalizada ? novaSigla : sigla;

        if (!siglaFinal || !novoPadrao) {
            setSnackbar({
                open: true,
                message: 'Preencha todos os campos antes de adicionar.',
                severity: 'warning',
                position: { vertical: 'top', horizontal: 'center' }
            });
            return;
        }

        try {
            const payload = {
                nome_config: tipoPadrao,
                novos_valores: { [siglaFinal]: [novoPadrao] } // Mantém a estrutura de array de strings
            };

            await atualizarConfiguracaoAdicionarPadraoRegra(payload);

            setSnackbar({
                open: true,
                message: 'Padrão adicionado com sucesso!',
                severity: 'success',
                position: { vertical: 'top', horizontal: 'right' }
            });

            setSigla('');
            setNovaSigla('');
            setNovoPadrao('');
            setUsarSiglaPersonalizada(false);

        // **Atualiza os estados necessários**
        atualizarDados(tipoPadrao, setFundosOutrosPadroes); // Atualiza apenas a configuração modificada
        atualizarDados("prioridade_siglas", setPrioridadeSiglas); // Sempre atualiza prioridade das siglas

        } catch (error) {
            setSnackbar({
                open: true,
                message: `Erro ao adicionar padrão: ${error.message}`,
                severity: 'error',
                position: { vertical: 'top', horizontal: 'center' }
            });
        }
    };

    return (
        <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
            <Typography variant="h6">{titulo}</Typography>
            <Box display="flex" flexDirection="column" gap={2} mt={2}>
                <Select
                    value={sigla}
                    onChange={(e) => {
                        const valorSelecionado = e.target.value;
                        setSigla(valorSelecionado);
                        setUsarSiglaPersonalizada(valorSelecionado === 'Outro');
                    }}
                    displayEmpty
                    variant="outlined"
                    fullWidth
                >
                    <MenuItem value="" disabled>
                        Selecione a Sigla
                    </MenuItem>
                    {siglasOptions.map((option) => (
                        <MenuItem key={option} value={option}>
                            {option}
                        </MenuItem>
                    ))}
                    <MenuItem value="Outro">Outro</MenuItem>
                </Select>

                {usarSiglaPersonalizada && (
                    <TextField
                        label="Digite a nova Sigla"
                        value={novaSigla}
                        onChange={(e) => setNovaSigla(e.target.value.toUpperCase())}
                        variant="outlined"
                        fullWidth
                    />
                )}

                <TextField
                    label="Novo Padrão"
                    value={novoPadrao}
                    onChange={(e) => setNovoPadrao(e.target.value.toUpperCase())}
                    variant="outlined"
                    fullWidth
                />
                <Button variant="contained" color="primary" onClick={handleAddPadrao}>
                    Adicionar
                </Button>
            </Box>
        </Paper>
    );
};

// Adaptado
const AdicionarClasseMontSection = ({ setSnackbar, atualizarDados ,setClasseMont}) => {
    const [sigla, setSigla] = useState('');
    const [novoPadrao, setNovoPadrao] = useState('');

    const handleAddClasseMont = async () => {
        if (!sigla || !novoPadrao) {
            setSnackbar({
                open: true,
                message: 'Preencha todos os campos antes de adicionar.',
                severity: 'warning',
                position: { vertical: 'top', horizontal: 'center' }
            });
            return;
        }

        try {
            const payload = {
                nome_config: "dict_classe_codigo_mont",
                novos_valores: { [sigla]: parseFloat(novoPadrao) }
            };

            await atualizarConfiguracaoAdicionarPadraoRegra(payload);

            setSnackbar({
                open: true,
                message: 'Classe Mont adicionada com sucesso!',
                severity: 'success',
                position: { vertical: 'top', horizontal: 'right' }
            });

            setSigla('');
            setNovoPadrao('');

            // Atualiza apenas `dict_classe_codigo_mont`
            atualizarDados("dict_classe_codigo_mont", setClasseMont);
        } catch (error) {
            setSnackbar({
                open: true,
                message: `Erro ao adicionar Classe Mont: ${error.message}`,
                severity: 'error',
                position: { vertical: 'top', horizontal: 'center' }
            });
        }
    };

    return (
        <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
            <Typography variant="h6">Adicionar Classe Mont</Typography>
            <Box display="flex" flexDirection="column" gap={2} mt={2}>
                <TextField
                    label="Sigla"
                    value={sigla}
                    onChange={(e) => setSigla(e.target.value.toUpperCase())}
                    placeholder="Ex: FIM-IE"
                    variant="outlined"
                    fullWidth
                />
                <TextField
                    label="Classificação Mont"
                    type="number"
                    value={novoPadrao}
                    onChange={(e) => setNovoPadrao(e.target.value)}
                    placeholder="Ex: 2.5"
                    variant="outlined"
                    fullWidth
                />
                <Button variant="contained" color="primary" onClick={handleAddClasseMont}>
                    Adicionar Classe Mont
                </Button>
            </Box>
        </Paper>
    );
};

// Adaptado
const AdicionarTipoAtivoSection = ({ setSnackbar, atualizarDados, setTipoGestora }) => {
    const [sigla, setSigla] = useState('');
    const [novoPadrao, setNovoPadrao] = useState('');

    const handleAddTipoAtivo = async () => {
        if (!sigla || !novoPadrao) {
            setSnackbar({
                open: true,
                message: 'Preencha todos os campos antes de adicionar.',
                severity: 'warning',
                position: { vertical: 'top', horizontal: 'center' }
            });
            return;
        }

        try {
            const payload = {
                nome_config: "dict_tipo_gestora",
                novos_valores: { [sigla]: novoPadrao }
            };

            await atualizarConfiguracaoAdicionarPadraoRegra(payload);

            setSnackbar({
                open: true,
                message: 'Tipo Ativo (Gestora) adicionado com sucesso!',
                severity: 'success',
                position: { vertical: 'top', horizontal: 'right' }
            });

            setSigla('');
            setNovoPadrao('');

            // Atualiza apenas `dict_tipo_gestora`
            atualizarDados("dict_tipo_gestora", setTipoGestora);
        } catch (error) {
            setSnackbar({
                open: true,
                message: `Erro ao adicionar Tipo Ativo: ${error.message}`,
                severity: 'error',
                position: { vertical: 'top', horizontal: 'center' }
            });
        }
    };

    return (
        <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
            <Typography variant="h6">Adicionar Tipo Ativo (Gestora)</Typography>
            <Box display="flex" flexDirection="column" gap={2} mt={2}>
                <TextField
                    label="Sigla"
                    value={sigla}
                    onChange={(e) => setSigla(e.target.value.toUpperCase())}
                    placeholder="Ex: FIA-IE"
                    variant="outlined"
                    fullWidth
                />
                <TextField
                    label="Descrição do Tipo Ativo"
                    value={novoPadrao}
                    onChange={(e) => setNovoPadrao(e.target.value)}
                    placeholder="Ex: Fundo de Ações - Investimento no Exterior"
                    variant="outlined"
                    fullWidth
                />
                <Button variant="contained" color="primary" onClick={handleAddTipoAtivo}>
                    Adicionar Tipo Ativo
                </Button>
            </Box>
        </Paper>
    );
};


const renderTable = (data, title, isSingleValue = false) => (
    <Box my={4}>
        <Typography variant="h6" gutterBottom>{title}</Typography>
        <TableContainer component={Paper} sx={{ maxHeight: 400, overflowY: 'auto' }}>
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell>{isSingleValue ? 'Sigla' : 'Categoria'}</TableCell>
                        <TableCell>{isSingleValue ? 'Descrição' : 'Padrões'}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Object.entries(data).map(([key, value]) => (
                        <TableRow key={key}>
                            <TableCell>{key}</TableCell>
                            <TableCell>{Array.isArray(value) ? value.join(', ') : value}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    </Box>
);

const FundosClasseMont = ({ setSnackbar }) => {
    const [fundosClasseMont, setFundosClasseMont] = useState([]);
    const [loading, setLoading] = useState(true);

    const columns = [
        { field: 'CNPJ_Classe', headerName: 'CNPJ da Classe', width: 200 },
        { field: 'Codigo_CVM', headerName: 'Código CVM da Classe', width: 200 },
        { field: 'Denominacao_Social', headerName: 'Denominação Social', width: 400 },
        { field: 'Denominacao_Mont', headerName: 'Denominação Mont', width: 300 },
        { field: 'Classe_Subst_Mont', headerName: 'Classe Substituta Mont', width: 180 },
        { field: 'Checagem_Denominacao_Mont', headerName: 'Checagem Denominação Mont', width: 250 },
        { field: 'CLASSE_MONT', headerName: 'Classe Mont', width: 150 },
        { field: 'CLASSE_COD_MONT', headerName: 'Código Classe Mont', width: 150 },
        { field: 'TIPO_ATIVO(GESTORA)', headerName: 'Tipo Ativo (Gestora)', width: 330 },
        { field: 'Classificacao', headerName: 'Classe', width: 150 },
        { field: 'Classe_Cotas', headerName: 'Classe de Cotas', width: 150 },
        { field: 'Permitido_Aplicacao_CemPorCento_Exterior', headerName: 'Permitido Aplicação no Exterior', width: 180 },
    ];

    useEffect(() => {
        const fetchFundosClasseMont = async () => {
            setLoading(true);
            try {
                const data = await obterFundosAdicionarClasseMont();
                setFundosClasseMont(data);
                setSnackbar({ open: true, message: 'Dados carregados com sucesso!', severity: 'success' , position: { vertical: 'top', horizontal: 'right' }});
            } catch (error) {
                setSnackbar({ open: true, message: 'Erro ao carregar dados dos fundos para Classificação Mont.', severity: 'error', position: { vertical: 'top', horizontal: 'center' } });
            } finally {
                setLoading(false);
            }
        };

        fetchFundosClasseMont();
    }, [setSnackbar]);

    return (
        <Box mt={5}>
            <Typography variant="h4" gutterBottom>
                Fundos para Classificação Mont
            </Typography>
            <Typography variant="body1" gutterBottom>
                Lista de fundos que não foram classificados corretamente.
            </Typography>

            {loading ? (
                <LoadingIndicator message="Carregando dados dos fundos para Classificação Mont..." />
            ) : (
                <DataTable 
                    columns={columns} 
                    rows={fundosClasseMont} 
                    idColumn="CNPJ_FUNDO" 
                    loading={loading} 
                    pageSizeOptions={[5, 10, 25, 50, 100]} 
                />
            )}
        </Box>
    );
};

const FundosAtencao = () => {
    const [fundos, setFundos] = useState([]);
    const [fundosPadroes, setFundosPadroes] = useState({});
    const [fundosOutrosPadroes, setFundosOutrosPadroes] = useState({});
    const [classeMont, setClasseMont] = useState({});
    const [tipoGestora, setTipoGestora] = useState({});
    const [prioridadeSiglas, setPrioridadeSiglas] = useState({});
    const [siglasVariacoes, setSiglasVariacoes] = useState({});
    const [loading, setLoading] = useState(true);
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });

    const setSnackbarCallback = useCallback((value) => {
        setSnackbar(value);
    }, []);

    // Função para carregar os dados de padrões
    const atualizarConfiguracao = useCallback(async (nomeConfig, setState) => {
        try {
            const data = await getConfiguracao(nomeConfig);
            setState(data[0]?.config_data || {}); // Garante que não é undefined
        } catch (error) {
            setSnackbarCallback({ open: true, message: `Erro ao atualizar ${nomeConfig}.`, severity: 'error', position: { vertical: 'top', horizontal: 'center' } });
        }
    }, [setSnackbarCallback]);
    

    const fetchFundos = useCallback(async () => {
        setLoading(true);
        try {
            const dataFundos = await obterFundosAdicionarRegra();
            setFundos(dataFundos);
            setSnackbarCallback({ open: true, message: 'Dados carregados com sucesso!', severity: 'success', position: { vertical: 'top', horizontal: 'right' } });
        } catch (error) {
            setSnackbarCallback({ open: true, message: 'Erro ao carregar dados dos fundos.', severity: 'error' , position: { vertical: 'top', horizontal: 'center' }});
        } finally {
            setLoading(false);
        }
    }, [setSnackbarCallback]);

    useEffect(() => {
        fetchFundos();
        atualizarConfiguracao("dict_padrao_fundos", setFundosPadroes);
        atualizarConfiguracao("dict_padrao_fundos_outros", setFundosOutrosPadroes);
        atualizarConfiguracao("dict_classe_codigo_mont", setClasseMont);
        atualizarConfiguracao("dict_tipo_gestora", setTipoGestora);
        atualizarConfiguracao("prioridade_siglas", setPrioridadeSiglas);
        atualizarConfiguracao("siglas_substituicoes_variacoes", setSiglasVariacoes);
    }, [fetchFundos, atualizarConfiguracao]);
    
    const handleCloseSnackbar = () => {
        setSnackbar({ ...snackbar, open: false });
    };
    

    return (
        <Box p={3}>
            <Typography variant="h4" gutterBottom>
                Fundos em Atenção
            </Typography>
            <Typography variant="body1" gutterBottom>
                Lista de fundos que não foram renomeados corretamente.
            </Typography>

            {loading ? (
                <LoadingIndicator message="Carregando dados dos fundos..." />
            ) : (
                <DataTable 
                    columns={[
                        { field: 'CNPJ_Classe', headerName: 'CNPJ da Classe', width: 200 },
                        { field: 'Codigo_CVM', headerName: 'Código CVM da Classe', width: 200 },
                        { field: 'Denominacao_Social', headerName: 'Denominação Social', width: 400 },
                        { field: 'Denominacao_Mont', headerName: 'Denominação Mont', width: 300 },
                        { field: 'Classe_Subst_Mont', headerName: 'Classe Substituta Mont', width: 180 },
                        { field: 'Checagem_Denominacao_Mont', headerName: 'Checagem Denominação Mont', width: 250 },
                        { field: 'CLASSE_MONT', headerName: 'Classe Mont', width: 150 },
                        { field: 'CLASSE_COD_MONT', headerName: 'Código Classe Mont', width: 150 },
                        { field: 'TIPO_ATIVO(GESTORA)', headerName: 'Tipo Ativo (Gestora)', width: 330 },
                        { field: 'Classificacao', headerName: 'Classe', width: 150 },
                        { field: 'Classe_Cotas', headerName: 'Classe de Cotas', width: 150 },
                        { field: 'Permitido_Aplicacao_CemPorCento_Exterior', headerName: 'Permitido Aplicação no Exterior', width: 180 },
                    ]} 
                    rows={fundos} 
                    idColumn="Identificacao_Classe" 
                    loading={loading} 
                    pageSizeOptions={[5, 10, 25, 50, 100]} 
                />
            )}

            <Typography variant="h4" gutterBottom mt={5}>
                Adicionar Novos Padrões
            </Typography>
            <Typography variant="body1" gutterBottom>
                Adicionar novos padrões para a lógica de renomeação dos Fundos
            </Typography>

            <AdicionarPadraoFundosSection
                titulo="Padrões para Classe do Fundo e Investimento no Exterior"
                siglasOptions={Object.keys(fundosPadroes)}
                setSnackbar={setSnackbarCallback}
                atualizarDados={atualizarConfiguracao}
                setFundosPadroes={setFundosPadroes}
                setPrioridadeSiglas={setPrioridadeSiglas}
                setSiglasVariacoes={setSiglasVariacoes} 
            />

            <AdicionarPadraoFundosOutrosSection
                titulo="Padrões para Outros Casos"
                siglasOptions={Object.keys(fundosOutrosPadroes)} // Pega as chaves do objeto
                tipoPadrao="dict_padrao_fundos_outros"
                setSnackbar={setSnackbarCallback}
                atualizarDados={atualizarConfiguracao}
                setFundosOutrosPadroes={setFundosOutrosPadroes}
                setPrioridadeSiglas={setPrioridadeSiglas}
            />

            <Divider
                variant="middle"
                sx={{
                    my: 6,
                    borderColor: 'primary.main',
                    borderBottomWidth: 2,
                    width: '90%',
                    mx: 'auto'
                }}
            />

            <FundosClasseMont setSnackbar={setSnackbarCallback} />

            <AdicionarClasseMontSection setSnackbar={setSnackbarCallback} atualizarDados={atualizarConfiguracao} setClasseMont={setClasseMont}/>
            <AdicionarTipoAtivoSection setSnackbar={setSnackbarCallback } atualizarDados={atualizarConfiguracao} setTipoGestora={setTipoGestora}/>

            <Divider
                variant="middle"
                sx={{
                    my: 6,
                    borderColor: 'primary.main',
                    borderBottomWidth: 2,
                    width: '90%',
                    mx: 'auto'
                }}
            />
            
            {/* Renderiza as quatro tabelas usando renderTable */}
            {renderTable(fundosPadroes, 'Padrões dos Fundos')}
            {renderTable(fundosOutrosPadroes, 'Outros Padrões dos Fundos')}
            {renderTable(classeMont, 'Classificação Mont', true)}
            {renderTable(tipoGestora, 'Tipo Ativo (Gestora)', true)}
            {/* Auxiliares */}
            {renderTable(siglasVariacoes, 'Variações para Siglas no Classe Mont', true)}
            {renderTable(prioridadeSiglas, 'Prioridade das Siglas no Classe Mont', true)}
            

            <CustomSnackbar
                open={snackbar.open}
                message={snackbar.message}
                severity={snackbar.severity}
                onClose={handleCloseSnackbar}
                position={snackbar.position}
            />
        </Box>
    );
};

export default FundosAtencao;