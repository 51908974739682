import React, { useState } from 'react';
import { Box } from '@mui/material';
import NavigationTabs from '../../../components/NavigationTabs';
import ConfiguracoesFundos from './ConfiguracoesFundos';
import ConfiguracoesETF from './ConfiguracoesETF';
import ConfiguracoesStock from './ConfiguracoesStock';

const ConfiguracoesRendaVariavelIndex = () => {
    const [currentTab, setCurrentTab] = useState('fundos');

    const tabs = [
        { label: 'Fundos', value: 'fundos' },
        { label: 'ETF', value: 'etf' },
        { label: 'Stock', value: 'stock' },
    ];

    const renderCurrentTabContent = () => {
        switch (currentTab) {
            case 'etf':
                return <ConfiguracoesETF />;
            case 'stock':
                return <ConfiguracoesStock />;
            case 'fundos':
            default:
                return <ConfiguracoesFundos />;
        }
    };

    return (
        <Box sx={{ width: '100%', padding: '20px', minHeight: '500px' }}>
            <NavigationTabs tabs={tabs} currentTab={currentTab} onChange={setCurrentTab} />
            <Box sx={{ width: '100%', minHeight: '400px', transition: 'all 0.3s ease-in-out' }}>
                {renderCurrentTabContent()}
            </Box>
        </Box>
    );
};

export default ConfiguracoesRendaVariavelIndex;
