import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { menuItems } from '../routes/routes';

const ProtectedRoute = () => {
  const { isAuthenticated, permissions } = useAuth();
  const location = useLocation();

  
  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  // Aguarda o carregamento das permissões
  if (permissions.length === 0) {
    return <div>Carregando permissões...</div>;
  }

  // Cria uma lista "achatada" de rotas, adicionando a permissão do módulo pai para submódulos
  const flatRoutes = menuItems.flatMap(module => {
    const routes = [];
    // Rota do módulo principal (não possui parentRequiredPermission)
    routes.push({ ...module, parentRequiredPermission: null });
    // Para cada submódulo, adiciona a propriedade com a permissão do módulo pai
    if (module.submodules) {
      routes.push(
        ...module.submodules.map(sub => ({
          ...sub,
          parentRequiredPermission: module.requiredPermission,
        }))
      );
    }
    return routes;
  });

  const currentRoute = flatRoutes.find(route => route.route === location.pathname);

  // Se a rota existir, verifica se o usuário tem ambas as permissões necessárias (rota e, se houver, do módulo pai)
  const isAllowed = currentRoute
    ? (
        (!currentRoute.requiredPermission || permissions.includes(currentRoute.requiredPermission)) &&
        (!currentRoute.parentRequiredPermission || permissions.includes(currentRoute.parentRequiredPermission))
      )
    : true;

  return isAllowed ? <Outlet /> : <Navigate to="/home" replace />;
};

export default ProtectedRoute;
