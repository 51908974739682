// src/pages/admin/CarteiraAdministrada.jsx
import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import ModuleCard from '../../components/ModuleCard'; // Importe o componente de card
import { adminMenuItems } from '../../routes/adminRoutes'; // Importa as rotas administrativas
import PeopleIcon from '@mui/icons-material/People';


import PersonAddIcon from '@mui/icons-material/PersonAdd';
import EditIcon from '@mui/icons-material/Edit';


const UsersIndex = ({ cardsPerRow = {   md: 1 } }) => {
  const theme = useTheme();

  // Encontra o módulo "Carteira Administrada" em adminMenuItems
  const carteiraModule = adminMenuItems.find(item => item.text === 'Usuários');
  const submodules = carteiraModule?.submodules?.filter(sub => !sub.hidden) || [];

  // Define os ícones específicos para cada submódulo
  const submoduleIcons = {
    'Informações Usuários': <PeopleIcon sx={{ fontSize: 60 }} />,
    'Criar Usuário': <PersonAddIcon sx={{ fontSize: 60 }} />,
    'Atualizar Usuário': <EditIcon sx={{ fontSize: 60 }} />,
  };

  return (
    <Box sx={{ flexGrow: 1, padding: 2 }}>
      <Typography variant="h4" align="center" gutterBottom sx={{ fontWeight: 'bold', color: theme.palette.text.primary }}>
        Usuários
      </Typography>

      {/* Grid layout para os cards */}
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: {
            sm: `repeat(${cardsPerRow.sm}, 1fr)`,
            md: `repeat(${cardsPerRow.md}, 1fr)`,
          },
          gap: 2,
          paddingX: {  md: 6 },
        }}
      >
        {submodules.map((submodule, index) => (
          <ModuleCard
            key={index}
            title={submodule.text}
            description={`Acesse o módulo ${submodule.text}.`}
            icon={submoduleIcons[submodule.text] || <PeopleIcon sx={{ fontSize: 60 }} />} // Ícone dinâmico baseado no nome do submódulo
            route={submodule.route}
          />
        ))}
      </Box>
    </Box>
  );
};

export default UsersIndex;
