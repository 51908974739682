import axios from 'axios';

let isRefreshing = false;
let refreshSubscribers = [];

// Função para adicionar callbacks para as requisições que falharam enquanto o token estava sendo renovado
function onRefreshed(token) {
  refreshSubscribers.map((callback) => callback(token));
  refreshSubscribers = [];
}

// Adiciona callbacks de requisições que aguardam a renovação do token
function addRefreshSubscriber(callback) {
  refreshSubscribers.push(callback);
}

// Instância para requisições autenticadas
const apiWithAuth = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

apiWithAuth.isRefreshing = isRefreshing;

// Interceptor para adicionar o token nas requisições autenticadas (antes de enviar a requisição)
apiWithAuth.interceptors.request.use((config) => {
  const token = sessionStorage.getItem('access_token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

// Interceptor para lidar com erros 401 (token expirado) e tentar renovar o token
apiWithAuth.interceptors.response.use(
  (response) => response,  // Retorna a resposta normalmente se não houver erro
  async (error) => {
    const originalRequest = error.config;
    const refreshToken = sessionStorage.getItem('refresh_token');

    // Se a resposta for 401 (token expirado) e houver um refresh_token
    if (error.response?.status === 401 && refreshToken && !originalRequest._retry) {
      originalRequest._retry = true; // Evita loops de requisições repetidas

      if (!apiWithAuth.isRefreshing) {
        apiWithAuth.isRefreshing = true;

        try {
          // Faz a requisição para renovar o token
          const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/auth/token/refresh`, null, {
            params: { refresh_token: refreshToken },
          });

          // Atualiza o novo access_token no sessionStorage
          sessionStorage.setItem('access_token', data.access_token);
          apiWithAuth.isRefreshing = false;

          // Executa todas as requisições que aguardavam o novo token
          onRefreshed(data.access_token);
          
          // Reenvia a requisição original com o novo token
          originalRequest.headers.Authorization = `Bearer ${data.access_token}`;
          return apiWithAuth(originalRequest);
        } catch (refreshError) {
          apiWithAuth.isRefreshing = false;
          sessionStorage.removeItem('access_token');
          sessionStorage.removeItem('refresh_token');
          window.location.href = '/login';  // Redireciona para login se a renovação falhar
        }
      }

      // Adiciona as requisições em uma fila para serem enviadas após o token ser renovado
      return new Promise((resolve) => {
        addRefreshSubscriber((newToken) => {
          originalRequest.headers.Authorization = `Bearer ${newToken}`;
          resolve(apiWithAuth(originalRequest));
        });
      });
    }

    return Promise.reject(error);  // Retorna o erro se não for possível renovar o token
  }
);

// Instância para requisições que não precisam de autenticação
const apiWithoutAuth = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});


/**
 * Função auxiliar para tratar erros da API.
 * @param {Object} error - Objeto de erro retornado pelo Axios.
 * @param {Object} [customMessages={}] - Mensagens personalizadas específicas para o endpoint.
 * @returns {Error} - Retorna um erro com uma mensagem específica para o usuário.
 */
const handleApiError = (error, customMessages = {}) => {
  if (error.response) {
    const status = error.response.status;
    const errorMessage =
    (typeof error.response?.data === "object" &&
      (error.response.data?.detail || error.response.data?.message)) ||
    "Erro desconhecido.";

    // Dicionário padrão de mensagens de erro
    const defaultMessages = {
      400: "Requisição inválida.",
      401: "Usuário não autenticado. Faça login novamente.",
      403: "Você não tem permissão para realizar esta ação.",
      404: "Recurso não encontrado.",
      500: "Erro interno no servidor. Tente novamente mais tarde.",
    };

    // Usa a mensagem customizada se existir, senão usa a padrão, senão usa a da API
    const userMessage = customMessages[status] || defaultMessages[status] || errorMessage;

    console.error(`Erro ${status}:`, errorMessage);
    return new Error(userMessage);
  } else if (error.request) {
    console.error("Nenhuma resposta recebida do servidor.");
    return new Error("Erro de conexão com o servidor. Verifique sua internet.");
  } else {
    console.error("Erro inesperado:", error.message);
    return new Error("Ocorreu um erro inesperado. Tente novamente.");
  }
};

export { apiWithAuth, apiWithoutAuth, handleApiError };


