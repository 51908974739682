// src/utils/routeHelper.js
import { adminMenuItems } from "../../routes/adminRoutes";
import { menuItems } from "../../routes/routes";

// Função para mapear todas as rotas para os textos corretos
const generateRouteMap = (menuItems) => {
  let routeMap = {};

  menuItems.forEach((item) => {
    routeMap[item.route] = item.text; // Adiciona o item principal
    if (item.submodules) {
      item.submodules.forEach((sub) => {
        routeMap[sub.route] = sub.text; // Adiciona submódulos
      });
    }
  });

  return routeMap;
};

// Mapa dinâmico de rotas
export const routeNamesAdmin = generateRouteMap(adminMenuItems);

export const routeNamesUser = generateRouteMap(menuItems);