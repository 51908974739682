import React from "react";
import { Breadcrumbs, Link, Typography, Box } from "@mui/material";
import { useLocation, Link as RouterLink } from "react-router-dom";
import { routeNamesUser } from "./utils/routerHelper"; // Importa o mapa de rotas para usuários

const UserBreadcrumbs = () => {
  const location = useLocation();
  let segments = location.pathname.split("/").filter(Boolean);

  // Se o primeiro segmento for "home", remova-o para evitar duplicação
  if (segments[0]?.toLowerCase() === "home") {
    segments = segments.slice(1);
  }

  const routeMap = routeNamesUser;

  return (
    <Box sx={{ mb: 2, mt: 1, pl: 2 }}>
      <Breadcrumbs aria-label="breadcrumb" sx={{ fontSize: "1rem" }}>
        <Link
          component={RouterLink}
          to="/home"
          underline="hover"
          color="inherit"
          sx={{ fontWeight: "bold" }}
        >
          Home
        </Link>
        {segments.map((value, index) => {
          const route = `/${segments.slice(0, index + 1).join("/")}`;
          const isLast = index === segments.length - 1;
          const name = routeMap[route] || value;
          return isLast ? (
            <Typography key={route} color="text.primary" sx={{ fontWeight: "bold" }}>
              {name}
            </Typography>
          ) : (
            <Link
              key={route}
              component={RouterLink}
              to={route}
              underline="hover"
              color="inherit"
              sx={{ fontWeight: "medium" }}
            >
              {name}
            </Link>
          );
        })}
      </Breadcrumbs>
    </Box>
  );
};

export default UserBreadcrumbs;
