import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Paper, useTheme } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const PlanejadoresTable = ({ planejadores, onEdit, onRemove }) => {
    const theme = useTheme();
    
    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="Tabela de Planejadores">
                <TableHead>
                    <TableRow>
                        {/* Definindo largura fixa para cada coluna */}
                        <TableCell sx={{ width: '30%' }}><strong>Nome do Planejador</strong></TableCell>
                        <TableCell sx={{ width: '40%' }}><strong>Caminho do Logo</strong></TableCell>
                        <TableCell sx={{ width: '30%' }}><strong>Ações</strong></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {planejadores.length > 0 ? (
                        planejadores.map((planejador) => (
                            <TableRow key={planejador.nome_mc}>
                                {/* Aplicando largura fixa nas células de dados também */}
                                <TableCell sx={{ width: '30%' }}>{planejador.nome_mc}</TableCell>
                                <TableCell sx={{ width: '40%' }}>{planejador.url_logo}</TableCell>
                                <TableCell sx={{ width: '30%' }}>
                                    {planejador.nome_mc === 'MONT(DEFAULT)' ? (
                                        <span>Não Permitido Alterar</span>
                                    ) : (
                                        <>
                                            <IconButton
                                                sx={{
                                                    color: theme.palette.mode === 'dark'
                                                        ? theme.palette.secondary.main
                                                        : theme.palette.primary.main,
                                                }}
                                                aria-label="editar"
                                                onClick={() => onEdit(planejador.nome_mc)}
                                            >
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton
                                                sx={{
                                                    color: 'red',
                                                }}
                                                aria-label="remover"
                                                onClick={() => onRemove(planejador.nome_mc)}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </>
                                    )}
                                </TableCell>
                            </TableRow>
                        ))
                    ) : (
                        <TableRow>
                            <TableCell colSpan={3} align="center">Nenhum planejador disponível.</TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default PlanejadoresTable;
