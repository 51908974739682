import { apiWithAuth } from './api';  // Importa as instâncias de API com e sem autenticação


export const buscarFundos = async (termo) => {
    try {
        const response = await apiWithAuth.get(`/fundos/dados_cadastrais_simplificados`, {
            params: { search: termo },
        });
        return response.data;
    } catch (error) {
        console.error('Erro ao buscar dados de fundos:', error);
        throw error;
    }
};

// --------------- Funções Ranking e Aprovados ----------------------------------------------//
export const fundosRanking = async (tipo) => {
    try {
        const response = await apiWithAuth.get(`/fundos/ranking/${tipo}`);
        return response.data;
    } catch (error) {
        console.error(`Erro ao obter os fundos de ${tipo}:`, error);
        throw error;
    }
};

export const fundosAprovados = async (tipo) => {
    try {
        const response = await apiWithAuth.get(`/fundos/aprovados/${tipo}`);
        return response.data;
    } catch (error) {
        console.error(`Erro ao obter os fundos aprovados de ${tipo}:`, error);
        throw error;
    }
};

export const enviarFundosAprovados = async (tipo, fundos) => {
    try {
        const response = await apiWithAuth.post(`/fundos/aprovados/${tipo}`, fundos);
        return response.data;
    } catch (error) {
        // Tratamento de erro com base na resposta HTTP
        if (error.response) {
            const status = error.response.status;
            const data = error.response.data;

            // Propagando uma mensagem específica com base no status HTTP
            switch (status) {
                case 400:
                    throw new Error('Erro de validação. Verifique os dados enviados.');
                case 401:
                    throw new Error('Não autorizado. Faça login novamente.');
                case 403:
                    throw new Error('Você não tem permissão para realizar essa ação.');
                case 404:
                    throw new Error('Endpoint não encontrado. Verifique a URL.');
                case 500:
                    throw new Error('Erro interno do servidor. Tente novamente mais tarde.');
                default:
                    throw new Error(data.message || 'Erro inesperado. Tente novamente mais tarde.');
            }
        } else if (error.request) {
            // Caso não haja resposta (problema na conexão)
            throw new Error('Não foi possível conectar ao servidor. Verifique sua conexão com a internet.');
        } else {
            // Erros fora do escopo de HTTP (erros de configuração, etc.)
            throw new Error(`Erro: ${error.message}`);
        }
    }
};

// --------------- Funções Ranking e Aprovados ----------------------------------------------//

// --------------- Estatistica ------------------------------------------------------//

export const fundosStatsCnpjID = async (cnpj, idSubclasse) =>  {
    try {
        const response = await apiWithAuth.get(`/fundos/stats/${cnpj}/${idSubclasse}`);
        return response.data;
    } catch (error) {
        console.error('Erro ao obter as estatísticas do fundo', error);
        throw error;
    }
}

// --------------- Estatistica ------------------------------------------------------//


export const gerarRelatorioRisco = async (data) => {
    try {
        const response = await apiWithAuth.post('/fundos/gerar_relatorio_risco', data, {
            responseType: 'blob' // Define o tipo de resposta como blob para manipular o arquivo de download
        });
        return response.data;
    } catch (error) {
        console.error('Erro ao gerar o relatório de risco:', error);

        let errorMessage = 'Erro ao gerar o relatório de risco. Por favor, tente novamente.';

        if (error.response) {
            const { status, data: errorData } = error.response;

            let errorDetails = errorData;
            // Verifica se o erro é um Blob e tenta converter para JSON para extrair o detalhe
            if (errorData instanceof Blob) {
                try {
                    const text = await errorData.text();
                    errorDetails = JSON.parse(text); // Converte para JSON
                } catch (parseError) {
                    console.error('Erro ao processar a resposta de erro:', parseError);
                }
            }

            // Definindo mensagens específicas para os diferentes status HTTP
            switch (status) {
                case 400:
                    errorMessage = errorDetails?.detail || "Requisição inválida. Verifique os dados fornecidos.";
                    break;

                case 401:
                    errorMessage = "Acesso não autorizado. Verifique suas credenciais.";
                    break;

                case 403:
                    errorMessage = "Permissão negada. Verifique se você possui as permissões necessárias.";
                    break;

                case 404:
                    errorMessage = errorDetails?.detail || "Recurso não encontrado. Verifique os dados ou tente novamente.";
                    break;

                case 500:
                    errorMessage = "Erro interno no servidor. Tente novamente mais tarde.";
                    break;

                default:
                    errorMessage = "Erro desconhecido. Por favor, tente novamente ou contate o suporte.";
            }
        } else if (error.request) {
            // Caso não haja resposta (problema de conexão)
            errorMessage = 'Não foi possível conectar ao servidor. Verifique sua conexão com a internet.';
        } else {
            // Erros fora do escopo HTTP (erros de configuração, etc.)
            errorMessage = `Erro: ${error.message}`;
        }

        throw new Error(errorMessage);
    }
};

// ----------- Configurações / Parametro ---------------------//

// Função para obter configurações específicas pelo nome
export const getConfiguracao = async (nome) => {
    try {
        const response = await apiWithAuth.get(`/fundos/configuracoes?nome=${nome}`);
        return response.data; // Retorna o primeiro elemento, já que a busca é por nome específico
    } catch (error) {
        console.error(`Erro ao obter a configuração para ${nome}`, error);
        throw error;
    }
  };

// Função para atualizar uma configuração específica
export const atualizarConfiguracaoAdicionarPadraoRegra = async (payload) => {
    try {
        const response = await apiWithAuth.post("/fundos/configuracoes/adicionar_padrao_regra", payload);
        // console.log("Configuração atualizada com sucesso:", response.data);
        return response.data;
    } catch (error) {
        console.error("Erro ao atualizar configuração:", error.response?.data || error.message);
        throw error;
    }
};

// Função para atualizar configurações específicas pelo nome
export const updateConfiguracao = async (nome, novosValores) => {
try {
    const response = await apiWithAuth.patch(`/fundos/configuracoes?nome=${nome}`, {  novos_valores: novosValores } );
    return response.data;
} catch (error) {
    console.error(`Erro ao atualizar a configuração para ${nome}`, error);
        // Tratamento de erro com base na resposta HTTP
        if (error.response) {
        const status = error.response.status;
        const data = error.response.data;

        // Propagando uma mensagem específica com base no status HTTP
        switch (status) {
            case 400:
                throw new Error('Erro de validação. Verifique os dados enviados.');
            case 401:
                throw new Error('Não autorizado. Faça login novamente.');
            case 403:
                throw new Error('Você não tem permissão para realizar essa ação.');
            case 404:
                throw new Error('Endpoint não encontrado. Verifique a URL.');
            case 500:
                throw new Error('Erro interno do servidor. Tente novamente mais tarde.');
            default:
                throw new Error(data.message || 'Erro inesperado. Tente novamente mais tarde.');
        }
    } else if (error.request) {
        // Caso não haja resposta (problema na conexão)
        throw new Error('Não foi possível conectar ao servidor. Verifique sua conexão com a internet.');
    } else {
        // Erros fora do escopo de HTTP (erros de configuração, etc.)
        throw new Error(`Erro: ${error.message}`);
    }
}
};

// ----------- Configurações / Parametro ---------------------//

export const obterFundosAdicionarRegra = async () =>  {
    try {
        const response = await apiWithAuth.get(`/fundos/adicionar_regra_padroes`);
        return response.data;
        } catch (error) {
            console.error('Erro ao obter dados de Fundos que não foram renomeados corretamente', error);
            throw error;
    }
}


export const obterFundosAdicionarClasseMont = async () =>  {
    try {
        const response = await apiWithAuth.get(`/fundos/adicionar_classe_mont`);
        return response.data;
        } catch (error) {
            console.error('Erro ao obter dados de Fundos que não foram classificados corretamente', error);
            throw error;
    }
}

// ----------- Configurações / Parametro ---------------------//

// ----------- Aprovados e Aceitos --------------------------- //

export const obterFundosStatsTabelaCNPJ = async (cnpj, id_subclasse) =>  {
    try {
        const response = await apiWithAuth.get(`/fundos/stats/tabela/${cnpj}/${id_subclasse}`);
        return response.data;
        } catch (error) {
            console.error('Erro ao obter dados de estatisticas', error);
            throw error;
    }
}

export const obterFundosAprovadosAceitos = async () =>  {
    try {
        const response = await apiWithAuth.get(`/fundos/aprovados_aceitos`);
        return response.data;
        } catch (error) {
            console.error('Erro ao obter dados de Fundos Aprovados e Aceitos', error);
            throw error;
    }
}

export const enviarFundosAprovadosAceitos = async (data) =>  {
    try {
        const response = await apiWithAuth.post(`/fundos/aprovados_aceitos`,data);
        return response.data;
        } catch (error) {
            console.error('Erro ao enviar dados de Fundos Aprovados e Aceitos', error);
            throw error;
    }
}


// Atualizar fundo aprovado ou aceito
export const atualizarFundoAprovadoAceito = async (cnpj, id_subclasse, updateData) => {
    try {
      const response = await apiWithAuth.patch(`/fundos/aprovados_aceitos/${cnpj}/${id_subclasse}`, updateData);
      return response.data;
    } catch (error) {
      console.error('Erro ao atualizar fundo aprovado/aceito:', error);
      throw error;
    }
  };

// Deletar fundo aprovado ou aceito
export const deletarFundoAprovadoAceito = async (cnpj, id_subclasse) => {
    try {
      const response = await apiWithAuth.delete(`/fundos/aprovados_aceitos/${cnpj}/${id_subclasse}`);
      return response.data;
    } catch (error) {
      console.error('Erro ao deletar fundo aprovado/aceito:', error);
      throw error;
    }
  };
  