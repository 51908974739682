import { apiWithAuth } from './api';  // Importa as instâncias de API com e sem autenticação

// Função para obter a lista de nomes dos planejadores, com query opcional
export const listaPlanejadorNomes = async (query = '') => {
    try {
        const url = query ? `/planejador/nomes?query=${query}` : '/planejador/nomes';
        const response = await apiWithAuth.get(url);
        return response.data.planejadores;
    } catch (error) {
        console.error('Erro ao obter os nomes dos planejadores:', error);
        throw error;
    }
};


export const tabelaPlanejadorLogo = async (page = 1, limit = 10) => {
    try {
        // Verifica se o limite excede 100 e lança erro
        if (limit > 100) {
            throw new Error('O valor máximo para "limit" é 100.');
        }

        // Monta a URL com os parâmetros de paginação
        const url = `/planejador/tabela?page=${page}&limit=${limit}`;
        const response = await apiWithAuth.get(url);
        return response.data;
    } catch (error) {
        console.error('Erro ao obter lista de planejadores paginados:', error);
        throw error;
    }
};



// Função para adicionar um novo planejador com nome e imagem de logo
export const adicionarPlanejador = async (nome, logoFile) => {
    try {
        // Cria um FormData para enviar o arquivo de imagem
        const formData = new FormData();
        formData.append('logo', logoFile);  // Adiciona o arquivo de imagem do logo

        // Faz a requisição POST para adicionar o planejador
        const response = await apiWithAuth.post(`/planejador/adicionar/${encodeURIComponent(nome)}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });

        return response.data;  // Retorna a resposta do backend
    } catch (error) {
        console.error('Erro ao adicionar o planejador:', error);
        throw error;
    }
};

export const removerPlanejador = async (nome) =>
{
    try {
        const response = await apiWithAuth.delete(`/planejador/remover/${nome}`);
        return response.data;
    } catch (error) {
        console.error(`Erro ao remover o planejador com nome ${nome}:`, error);
        throw error;
    }
};

export const atualizarPlanejadorLogo = async (nome, logoFile) => {
    try {
        // Cria um FormData para enviar o nome e o arquivo de imagem
        const formData = new FormData();
        formData.append('logo', logoFile);  // Adiciona o arquivo de imagem do logo

        // Faz a requisição POST para adicionar o planejador
        const response = await apiWithAuth.post(`/planejador/atualizar_logo/${nome}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });

        return response.data;  // Retorna a resposta do backend
    } catch (error) {
        console.error('Erro ao atualizar o logo:', error);
        throw error;
    }
};