import React, { useState } from 'react';
import { Box, TextField, Button } from '@mui/material';
import { downloadFile } from '../components/utils/downloadUtils';
import { gerarRelatorioPeriodico } from '../services/clientes';
import CustomSnackbar from '../components/CustomSnackbar';
import FullScreenLoader from '../components/FullScreenLoader';

const RelatorioPeriodico = () => {
    const [cpfCnpj, setCpfCnpj] = useState('');
    const [dataReferencia, setDataReferencia] = useState('');
    const [loading, setLoading] = useState(false);
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

    const handleCpfCnpjChange = (e) => {
        let inputValue = e.target.value.replace(/[^\w]/g, ''); // Remove caracteres especiais, mas permite letras e números

        if (inputValue.length <= 11) {
            setCpfCnpj(formatCpf(inputValue)); // Aplica a formatação de CPF
        } else {
            setCpfCnpj(formatCnpj(inputValue)); // Aplica a formatação de CNPJ
        }
    };

    const formatCpf = (value) => {
        return value
            .slice(0, 11)
            .replace(/^(.{3})(.{3})(.{3})(.{2})$/, '$1.$2.$3-$4'); // Formato de CPF: 000.000.000-00
    };

    const formatCnpj = (value) => {
        return value
            .slice(0, 14)
            .replace(/^(.{2})(.{3})(.{3})(.{4})(.{2})$/, '$1.$2.$3/$4-$5'); // Formato de CNPJ: 00.000.000/0000-00
    };

    const handleDataReferenciaChange = (e) => setDataReferencia(e.target.value);

    const handleSubmit = async (e) => {
        e.preventDefault();

        const cpfRegex = /^\w{3}\.\w{3}\.\w{3}-\w{2}$/;
        const cnpjRegex = /^\w{2}\.\w{3}\.\w{3}\/\w{4}-\w{2}$/;

        if (!cpfRegex.test(cpfCnpj) && !cnpjRegex.test(cpfCnpj)) {
            setSnackbar({ open: true, message: 'Por favor, insira um CPF ou CNPJ válido.', severity: 'error', position: { vertical: 'top', horizontal: 'center' } });
            return;
        }

        if (!dataReferencia) {
            setSnackbar({ open: true, message: 'Por favor, insira a data de referência.', severity: 'error', position: { vertical: 'top', horizontal: 'center' } });
            return;
        }

        setLoading(true);

        try {
            const dataRelatorio = {
                cpf_cnpj: [cpfCnpj],
                data_referencia: dataReferencia
            };

            const blob = await gerarRelatorioPeriodico(dataRelatorio);
            downloadFile(blob, `relatorio_de_remuneracao_periodica_${dataReferencia}.zip`);
            setSnackbar({ open: true, message: 'Relatório gerado e baixado com sucesso!', severity: 'success', position: { vertical: 'top', horizontal: 'right' } });
        } catch (error) {
            console.error('Erro ao gerar o relatório:', error);
            setSnackbar({ open: true, message: error.message || 'Erro ao gerar o relatório.', severity: 'error' , position: { vertical: 'top', horizontal: 'center' }});
        } finally {
            setLoading(false);
        }
    };

    const handleCloseSnackbar = () => {
        setSnackbar({ ...snackbar, open: false });
    };

    return (
        <Box p={4} display="flex" flexDirection="column" alignItems="center">
            <h1>Relatório Periódico de Cliente</h1>
            <form onSubmit={handleSubmit} style={{ width: '100%', maxWidth: '400px' }}>
                <TextField
                    label="CPF/CNPJ do Cliente"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    required
                    value={cpfCnpj}
                    onChange={handleCpfCnpjChange}
                    placeholder="Digite CPF ou CNPJ"
                    inputProps={{ maxLength: 18 }} // Limita o input total para ambos os formatos
                />
                                <TextField
                    label="Data de Referência"
                    type="month"
                    value={dataReferencia}
                    onChange={handleDataReferenciaChange}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    required
                    helperText="Selecione o ano e mês de referência"
                    slotProps={{
                        inputLabel: {
                            shrink: true, // Força o rótulo a ficar acima, sem sobrepor o valor
                        },
                    }}
                />
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    disabled={loading}
                    sx={{ mt: 2 }}
                >
                    {loading ? 'Gerando Relatório...' : 'Gerar Relatório'}
                </Button>
            </form>

            {/* FullScreenLoader aparece enquanto o relatório está sendo gerado */}
            <FullScreenLoader visible={loading} message="Gerando Relatório..." />

            <CustomSnackbar
                open={snackbar.open}
                message={snackbar.message}
                severity={snackbar.severity}
                onClose={handleCloseSnackbar}
                position={{ vertical: 'top', horizontal: 'center' }}
            />
        </Box>
    );
};

export default RelatorioPeriodico;
