import React from 'react';
import { Box, Typography } from '@mui/material';

const NoDataMessage = ({ message, subMessage }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '150px', // Altura para centralizar o conteúdo
        border: '1px dashed',
        borderColor: 'gray',
        borderRadius: '8px',
        backgroundColor: '#f9f9f9', // Fundo claro
        padding: '16px',
      }}
    >
      <Typography
        variant="h6"
        color="textSecondary"
        textAlign="center"
        sx={{
          fontStyle: 'italic',
          marginBottom: '8px',
        }}
      >
        {message}
      </Typography>
      {subMessage && (
        <Typography variant="body2" color="textSecondary" textAlign="center">
          {subMessage}
        </Typography>
      )}
    </Box>
  );
};

export default NoDataMessage;
