import React, { useState, useEffect } from 'react';
import {fetchCreditoPrivadoRanking,fetchCreditoPrivadoRankingAprovados,sendCreditoPrivadoAprovados,fetchCreditoPrivadoStatsCodigoIF } from '../../services/creditoPrivado';
import DataTable from '../../components/DataTable';
import { Box, Typography, Button } from '@mui/material';
import CustomSnackbar from '../../components/CustomSnackbar';
import ModalFundo from '../../components/Modal/ModalFundo';
import { criarChartData, RentSum } from '../../components/utils/chartUtils' // '../utils/chartUtils';
import NoDataMessage from '../../components/NoDataMessage';

const RendaVariavelStock = () => {
    const [rows, setRows] = useState([]);
    const [approvedRows, setApprovedRows] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedFunds, setSelectedFunds] = useState([]);
    const [, setApprovedTICKERs] = useState([]);
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
    const [modalOpen, setModalOpen] = useState(false);
    const [infoGerais, setInfoGerais] = useState([]);
    const [estatisticas, setEstatisticas] = useState([]);
    const [graficoData, setGraficoData] = useState(null);
    const [modalLoading, setModalLoading] = useState(false);


    // Definindo colunas padrão (sem checkbox)
    const defaultColumns =  [
            { field: 'Código IF', headerName: 'Código IF', width: 200 , description: "Código IF do Ativo",},
            { field: 'Instrumento Financeiro', headerName: 'Instrumento Financeiro', width: 200 , description: "Tipo de Instrumento negociado",},
            { field: 'Código ISIN', headerName: 'Código ISIN', width: 200 , description: "Código ISIN do ativo",},
            { field: 'Emissor', headerName: 'Emissor', width: 200 },
            {
                field: "Vencimento",
                headerName: "Data de Vencimento",
                width: 150,
                description: "Formato ANO-MES-DIA",
              },


            { field: 'RENTMED_AT%', headerName: 'Rent. Média %', width: 150 , description: "Rentabilidade Média Anual media do Ativo (%)"},
            { field: 'RENT_%DI+', headerName: 'Rent. %DI+', width: 150, description: "Rentabilidade(Spread Anual) media  do Ativo em relação ao CDI (%)" },
            { field: 'RENT_%IBOV+', headerName: 'Rent. %IBOV+', width: 150, description: "Rentabilidade(Spread Anual) media  do Ativo em relação ao IBOV (%)"},
            { field: 'RENT_%IMABG+', headerName: 'Rent. %IMABG+', width: 150 , description: "Rentabilidade(Spread Anual) media  do Ativo em relação ao IMAB Geral (%)"},
            { field: 'RENT_%IHFA+', headerName: 'Rent. %IHFA+', width: 150, description: "Rentabilidade(Spread Anual) media  do Ativo em relação ao IHFA (%)"},
            { field: 'RENT_%IFIX+', headerName: 'Rent. %IFIX+', width: 150 , description: "Rentabilidade(Spread Anual) media  do Ativo em relação ao IFIX (%)"},
            { field: 'DP(AT)%', headerName: 'DP(AT)%', width: 150 , description: "Média do desvio Padrão Anualizado do Ativo (%)"},
            { field: 'CORR(AT,IBOV)', headerName: 'Correlação (AT, IBOV)', width: 150 , description:"Média da Correlação do Ativo em relação ao Ibovespa do Ativo (d.u)"},
            { field: 'BETA', headerName: 'Beta', width: 150 ,description:"Média do Beta do Ativo (d.u)"},
            { field: 'SHARPE', headerName: 'Sharpe', width: 150 , description:"Média do Sharpe do Ativo"},
            { field: 'alfa_jensen', headerName: 'Alfa Jensen', width: 150 , description:"Média do Alfa de Jensen  Anual"},
            { field: 'Volume Financeiro (R$)', headerName: 'Volume Negociado (R$)', width: 150, description:"Média do Volume Negociado (R$)"},
            { field: 'Risco', headerName: 'Risco do Ativo', width: 150 },
            { field: 'MÍNIMO_SHARPE_ACEITAVEL%', headerName: 'Mín. Sharpe Aceitável %', width: 180 , description:"Porcentagem Mínima para o Sharpe ser aceitável "},
            { field: 'PORCENT_MAIOR_X_SHARPE', headerName: 'Porcent. Maior x Sharpe', width: 180 , description:"Porcentagem que o Ativo é maior que a referência estabelecida para Sharpe"},
            { field: 'MÍNIMO_ALFA_JENSEN_ACEITAVEL%', headerName: 'Mín. Alfa Jensen Aceitável %', width: 200 , description:"Porcentagem Mínima para o Alfa de Jensen ser aceitável "},
            { field: 'PORCENT_MAIOR_X_ALFA_JENSEN', headerName: 'Porcent. Maior x Alfa Jensen', width: 200, description:"Porcentagem que o Ativo é maior que a referência estabelecida para Alfa de Jensen " },
          ];

    // Colunas para a tabela de Ranking com checkbox de Aprovados
    const rankingColumns = [
        {
            field: 'Aprovados',
            headerName: 'Aprovados',
            width: 150,
            renderCell: (params) => (
                <input
                    type="checkbox"
                    id={`checkbox-${params.row["Código IF"]}`}
                    name={`checkbox-${params.row["Código IF"]}`}
                    checked={selectedFunds.includes(params.row["Código IF"])}
                    onChange={() => handleSelect(params.row["Código IF"])}
                />
            ),
        },
        ...defaultColumns,
    ];

    // Colunas para a tabela de Ativos Aprovados
    const approvedColumns = [
        { field: 'Aprovados', headerName: 'Aprovados', width: 100 },
        ...defaultColumns,
    ];

    //----------------- Modal -------------------------------------//
    // Função para abrir o modal ao clicar na linha da tabela
    const handleRowClick = async (params) => {
        if (params.field !== "Aprovados" ) {
            setModalLoading(true); // Ativa o loading do modal
            setModalOpen(true);
            const codigo_if = params.row["Código IF"]
            const infoGeraisData = [
                { label: 'Nome do Ativo', value: '' },
                { label: 'Código IF', value: codigo_if },
                { label: 'Instrumento Financeiro', value: params.row["Instrumento Financeiro"] },
                { label: 'Código ISIN', value: params.row["Código ISIN"] },
                { label: 'Emissor', value: params.row["Emissor"] },
                { label: 'Vencimento', value: params.row["Vencimento"] },
                { label: 'Risco', value: params.row.Risco },
            ];

            const estatisticasData = [
                { label: 'Rentabilidade Ativo', value: `${params.row['RENTMED_AT%']}%` },
                { label: 'Rentabilidade media em relação ao CDI (%)', value: `${params.row['RENT_%DI+']}%` },
                { label: 'Rentabilidade media em relação ao IBOV (%)', value: `${params.row['RENT_%IBOV+']}%` },
                { label: 'Rentabilidade media em relação ao IMABG (%)', value: `${params.row['RENT_%IMABG+']}%` },
                { label: 'Rentabilidade media em relação ao IHFA (%)', value: `${params.row['RENT_%IHFA+']}%` },
                { label: 'Rentabilidade media em relação ao IFIX(%)', value: `${params.row['RENT_%IFIX+']}%` },
                { label: 'Risco (Desvio Padrão)', value: `${params.row['DP(AT)%']}%` },
                { label: 'BETA', value: params.row.BETA },
                { label: 'SHARPE', value: params.row.SHARPE },
                { label: 'alfa de jensen', value: params.row['alfa_jensen'] },
            ];

            setInfoGerais(infoGeraisData);
            setEstatisticas(estatisticasData);

            try {
     
                const response = await fetchCreditoPrivadoStatsCodigoIF(codigo_if);

                const nomeAtivo =  response[0].shortName;

                // Atualiza o infoGeraisData com o nome do ativo
                setInfoGerais((prevInfoGeraisData) => 
                    prevInfoGeraisData.map(item => 
                        item.label === 'Nome do Ativo' ? { ...item, value: nomeAtivo } : item
                    )
                );
        
                const RentMovelFundoGraph = [
                    { name: codigo_if, data: criarChartData('RENTMED_AT%', response) },
                    { name: 'DI', data: criarChartData('RENTMED_DI%', response) },
                    { name: 'IBOV', data: criarChartData('RENTMED_IBOV%', response) },
                    { name: 'IMAB-GERAL', data: criarChartData('RENTMED_IMABG%', response) },
                    { name: 'IHFA', data: criarChartData('RENTMED_IHFA%', response) },
                    { name: 'IFIX', data: criarChartData('RENTMED_IFIX%',response) }
                ];   
                const RentSumFundoGraph = [
                    { name: codigo_if, data: RentSum('RENTLN', response) },
                    { name: 'DI', data: RentSum('rentln_cdi', response) },
                    { name: 'IBOV', data: RentSum('rentln_IBOV', response) },
                    { name: 'IMAB-GERAL', data: RentSum('rentln_imab', response) },
                    { name: 'IHFA', data: RentSum('rentln_ihfa', response) },
                    { name: 'IFIX', data: RentSum('rentln_IFIX', response) }
                ];

                // Variavel do gráfico da volatilidade do Fundo
                const DPFundoGraph = [
                    { name: codigo_if, data: criarChartData('DP(AT)%', response) }
                ];
                // Variavel do gráfico da volatilidade do Fundo
                const SharpeFundoGraph = [
                    { name: codigo_if, data: criarChartData('SHARPE', response) }
                ];

                const BetaFundoGraph = [
                    { name: codigo_if, data: criarChartData('BETA', response) }
                ];

                const AlfaJensenFundoGraph = [
                    { name: codigo_if, data: criarChartData('alfa_jensen', response) }
                ];

                const CorrIndFundoGraph = [
                    { name: 'Corr IBOV', data: criarChartData('CORR(AT,IBOV)', response) },
                    { name: 'Corr IMAB-GERAL', data: criarChartData('CORR(AT,IMABG)', response) },
                    { name: 'Corr IHFA', data: criarChartData('CORR(AT,IHFA)', response) },
                    { name: 'Corr IFIX', data: criarChartData('CORR(AT,IFIX)', response) }

                ];

                const DrawdownFundoGraph = [
                    { name: codigo_if, data: criarChartData('DRAWDOWN', response) }
                ];

                setGraficoData([
                    { title: 'Rentabilidade Média Móvel', series: RentMovelFundoGraph, yAxisTitle: 'Rentabilidade (%)' ,suffix: "%",height: "55%"},
                    { title: 'Rentabilidade Acumulada', series: RentSumFundoGraph, yAxisTitle: 'Rentabilidade (%)' ,suffix: "%",height: "55%"},
                    { title: 'Volatilidade do Ativo', series: DPFundoGraph, yAxisTitle: 'Volatilidade (%)' ,suffix: "%",height: "55%"},
                    { title: 'SHARPE do Ativo', series: SharpeFundoGraph, yAxisTitle: 'SHARPE' ,height: "55%"},
                    { title: 'BETA do Ativo', series: BetaFundoGraph, yAxisTitle: 'BETA' ,height: "55%"},
                    { title: 'Alda de Jensen do Ativo', series: AlfaJensenFundoGraph, yAxisTitle: 'Alfa de Jensen' ,height: "55%"},
                    { title: 'Correlação do Ativo', series: CorrIndFundoGraph, yAxisTitle: 'Correlação' ,height: "55%"},
                    { title: 'DRAWDOWN', series: DrawdownFundoGraph, yAxisTitle: 'DRAWDOWN (%)',suffix: "%" ,height: "55%"},
                    
                ]); // Dados para os gráficos


                // setModalOpen(true);
            } catch (error) {
                console.error('Erro ao carregar os dados do ETF:', error);
                setSnackbar({ open: true, message: 'Erro ao carregar os dados do ETF.', severity: 'error' , position: { vertical: 'top', horizontal: 'center' }});
            } finally {
                setModalLoading(false);
            }
        }
    };

    const handleCloseModal = () => {
        setModalOpen(false);
        setGraficoData(null);
    };

    //----------------- Modal -------------------------------------//

    // Função para buscar os dados de ranking e fundos aprovados
    const fetchData = async () => {
        setLoading(true);
        let rankingData = [];
        let approvedData = [];
    
        try {
          
            rankingData = await fetchCreditoPrivadoRanking();
            setRows(rankingData);
        } catch (error) {
            console.error('Erro ao buscar os dados do ranking:', error);
            setSnackbar({
                open: true,
                message: 'Erro ao buscar os dados do ranking.',
                severity: 'error',
                position: { vertical: 'top', horizontal: 'center' },
            });
        }
       
    
        try {
            approvedData = await fetchCreditoPrivadoRankingAprovados();
            setApprovedRows(approvedData);
            setApprovedTICKERs(approvedData.map((fundo) => fundo["Código IF"]));
            setSelectedFunds(approvedData.map((fundo) => fundo["Código IF"]));
        } catch (error) {
            console.error('Erro ao buscar os dados aprovados:', error);
            setSnackbar({
                open: true,
                message: 'Erro ao buscar os dados aprovados.',
                severity: 'error',
                position: { vertical: 'top', horizontal: 'center' },
            });
        } finally {
            setLoading(false);
        }
    };
    const handleSelect = (ticker) => {
        setSelectedFunds((prevSelected) => {
            if (prevSelected.includes(ticker)) {
                return prevSelected.filter((fund) => fund !== ticker);
            } else {
                return [...prevSelected, ticker];
            }
        });
    };

    const handleEnviarAprovados = async () => {
        const selectedData = rows
            .filter((row) => selectedFunds.includes(row["Código IF"]))
            .map((row) => ({
                ...row,
                Aprovados: true,
            }));
        
        try {
            
            await sendCreditoPrivadoAprovados(selectedData);
            setSnackbar({ open: true, message: 'Creditos Privados enviados com sucesso!', severity: 'success', position: { vertical: 'top', horizontal: 'right' } });
            // fetchData();
            const approvedData = await fetchCreditoPrivadoRankingAprovados();
            setApprovedRows(approvedData);
            setApprovedTICKERs(approvedData.map(fundo => fundo["Código IF"]));
            setSelectedFunds(approvedData.map(fundo => fundo["Código IF"]));
        } catch (error) {
            console.error('Erro ao enviar Créditos Privados aprovados:', error);
            setSnackbar({ open: true, message: error.message, severity: 'error', position: { vertical: 'top', horizontal: 'center' } });
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleCloseSnackbar = () => {
        setSnackbar({ ...snackbar, open: false });
    };

    return (
        <Box>
            <Typography variant="h4" gutterBottom>
                Ranking Crédito Privado
            </Typography>
            <Box mb={4}>
                <Typography component="p" variant="body1" sx={{ marginBottom: 3 }}>
                Ranking Crédito Privado, a partir dos dados estatísticos selecionados pelo usuário.
                    <br />
                    Obs: Selecionar na ordem de prioridade e se deseja que os dados sejam organizados em Ascendente (do maior para o menor) ou não.
                </Typography>
            </Box>
            <Box mb={4}>
            {rows.length === 0 && !loading ? (
                <NoDataMessage
                message="Nenhum dado disponível no momento."
                subMessage="Aguarde ou tente novamente mais tarde."
                />
            ) : (
                <DataTable
                rows={rows}
                columns={rankingColumns}
                idColumn="Código IF"
                loading={loading}
                pageSizeOptions={[10, 25, 50, 100]}
                onCellClick={handleRowClick}
                />
            )}
            </Box>
            <Box mb={2}>
                <Button variant="contained" color="primary" onClick={handleEnviarAprovados}>
               Enviar Selecionados
                </Button>
            </Box>
            <Typography variant="h4" gutterBottom>
            Crédito Privado Aprovados
            </Typography>
            <Box mb={4}>
                <Typography component="p" variant="body1">
                Lista de  Créditos Privados Aprovados pelo usuário utilizando tanto critérios quantitativos quanto qualitativos.
                </Typography>
            </Box>
            <Box mb={4}>
            {approvedRows.length === 0 && !loading ? (
                <NoDataMessage
                message="Nenhum Crédito Privado aprovado encontrado."
                subMessage="Adicione ou selecione Crédito Privado para vê-los aqui."
                />
            ) : (
                <DataTable
                rows={approvedRows}
                columns={approvedColumns}
                idColumn="Código IF"
                loading={loading}
                pageSizeOptions={[10, 25, 50, 100]}
                onCellClick={handleRowClick}
                />
            )}
            </Box>

            <ModalFundo
                modalTitle={"Estatísticas do Crédito Privado"}
                open={modalOpen}
                onClose={handleCloseModal}
                infoGerais={infoGerais}
                estatisticas={estatisticas}
                graficos={graficoData || []}
                loading={modalLoading}
            />

            <CustomSnackbar
                open={snackbar.open}
                message={snackbar.message}
                severity={snackbar.severity}
                position={snackbar.position}
                onClose={handleCloseSnackbar}
            />
        </Box>
    );
};

export default RendaVariavelStock;
