import React, { useState, useEffect } from 'react';
import { Box, Typography, TextField, Button, Paper} from '@mui/material';
import CustomSnackbar from '../../../components/CustomSnackbar';
import LoadingIndicator from '../../../components/LoadingIndicator';
import { getConfiguracao, updateConfiguracao } from '../../../services/rendaVariavel';
import GenericTable from '../../../components/GenericTable';
import { EstatisticasSection, IndicadoresSection } from '../../../components/ParametresSections';

const ConfiguracoesBase = ({ nomeConfiguracao, titulo }) => {
    const [parametrosClasse, setParametrosClasse] = useState({});
    const [parametrosEstatisticos, setParametrosEstatisticos] = useState({});
    const [parametrosRanking, setParametrosRanking] = useState({
        indicadores_desejados: [],
        indicadores_desejados_asc: [],
    });
    const [indicadoresDisponiveis, setIndicadoresDisponiveis] = useState([]);
    const [indicadoresSelecionados, setIndicadoresSelecionados] = useState([]);
    const [orderAsc, setOrderAsc] = useState({});
    const [hasStatsChanges, setHasStatsChanges] = useState(false);
    const [hasRankingChanges, setHasRankingChanges] = useState(false);
    const [newEntry, setNewEntry] = useState({ sigla: '', classificacao: '' });
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

    useEffect(() => {
        const fetchConfiguracoes = async () => {
            setLoading(true);
            try {
                const nomeClasse = nomeConfiguracao === "Fundos_Renda_Variavel"
                    ? `classe_codigo_mont_${nomeConfiguracao}`.toLowerCase()
                    : `classe_codigo_mont_${nomeConfiguracao}`;

                const [classeData, estatisticosData, rankingData] = await Promise.all([
                    getConfiguracao(nomeClasse),
                    getConfiguracao(`Parametros_Estatisticos_${nomeConfiguracao}`),
                    getConfiguracao(`Parametros_Ranking_${nomeConfiguracao}`)
                ]);

                setParametrosClasse(classeData?.[0]?.config_data || {});
                setParametrosEstatisticos(estatisticosData?.[0]?.config_data || {});

                const rankingConfig = rankingData?.[0]?.config_data || {
                    indicadores_desejados: [],
                    indicadores_desejados_asc: [],
                };

                setParametrosRanking(rankingConfig);
                setIndicadoresSelecionados(rankingConfig.indicadores_desejados || []);
                const initialOrderAsc = {};
                (rankingConfig.indicadores_desejados_asc || []).forEach((asc, idx) => {
                    initialOrderAsc[rankingConfig.indicadores_desejados[idx]] = asc;
                });
                setOrderAsc(initialOrderAsc);

                const allIndicators = ["SHARPE", "alfa_jensen", "Risco_Fundo", "DP(AT)%", "BETA", "RENT_%DI+", "RENT_%IBOV+", "RENT_%IMABG+","RENT_%IHFA+","RENT_%IFIX+"];
                setIndicadoresDisponiveis(allIndicators.filter(ind => !rankingConfig.indicadores_desejados.includes(ind)));
            } catch (error) {
                setSnackbar({ open: true, message: `Erro ao carregar configurações de ${titulo}.`, severity: 'error' });
            } finally {
                setLoading(false);
            }
        };

        fetchConfiguracoes();
    }, [nomeConfiguracao, titulo]);

    const handleAddOrUpdateEntryAndSave = async () => {
        const { sigla, classificacao } = newEntry;
        if (sigla && classificacao) {
            // Novo valor a ser enviado, incluindo apenas a nova entrada
            const novosValores = { [sigla]: Number(classificacao) };
    
            setSaving(true);
            try {
                const nomeClasse = nomeConfiguracao === "Fundos_Renda_Variavel"
                    ? `classe_codigo_mont_${nomeConfiguracao}`.toLowerCase()
                    : `classe_codigo_mont_${nomeConfiguracao}`;
                
                // Enviar apenas a nova entrada no body
                await updateConfiguracao(nomeClasse, novosValores);
    
                // Atualizar localmente a tabela com o novo valor, após sucesso no backend
                setParametrosClasse(prev => ({ ...prev, [sigla]: parseFloat(classificacao).toFixed(1)  }));
                setSnackbar({ open: true, message: 'Configuração de Classe Mont atualizada com sucesso!', severity: 'success' , position: { vertical: 'top', horizontal: 'right' }});
                setNewEntry({ sigla: '', classificacao: '' });   
            } catch (error) {
                console.error(error.message);
                setSnackbar({ open: true, message: error.message, severity: 'error' , position: { vertical: 'top', horizontal: 'center' }});
            } finally {
                setSaving(false);
            }
        }
    };
    

    const handleSave = async (parametros, tipo) => {
        setSaving(true);
        try {
            await updateConfiguracao(`Parametros_${tipo}_${nomeConfiguracao}`, parametros);
            setSnackbar({ open: true, message: `${titulo} atualizado com sucesso!`, severity: 'success', position: { vertical: 'top', horizontal: 'right' } });
                // Reset flags
                if (tipo === 'Estatisticos') setHasStatsChanges(false);
                if (tipo === 'Ranking') setHasRankingChanges(false);
        } catch (error) {
            setSnackbar({ open: true, message: error.message, severity: 'error', position: { vertical: 'top', horizontal: 'center' } });
        } finally {
            setSaving(false);
        }
    };

    const handleStatsChange = (updatedStats) => {
        setParametrosEstatisticos(updatedStats);
        setHasStatsChanges(true);
    };

    const handleRankingChange = (updatedRanking) => {
        setParametrosRanking(updatedRanking);
        setHasRankingChanges(true);
    };

    const columns = [
        { label: 'Sigla', field: 'sigla', width: '50%' },
        { label: 'Classificação Código Mont', field: 'classificacao', width: '50%' }
    ];

    const tableData = Object.entries(parametrosClasse).map(([sigla, classificacao]) => ({
        sigla,
        classificacao: parseFloat(classificacao).toFixed(1) // Formata o valor como float com uma casa decimal
    }));

    return (
        <Box p={4}>
            <Typography variant="h4" gutterBottom>{titulo}</Typography>

            {loading ? (
                <LoadingIndicator message={`Carregando configurações de ${titulo}...`} />
            ) : (
                <>
                    <GenericTable
                        data={tableData}
                        columns={columns}
                        emptyMessage="Nenhuma classe mont disponível."
                    />

                    <Box component="form" sx={{ mb: 4, mt: 2 }} onSubmit={(e) => e.preventDefault()}>
                        <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
                            <Typography variant="h6">Adicionar Classe  Código Mont</Typography>
                            <TextField
                                label="Sigla"
                                value={newEntry.sigla}
                                onChange={(e) => setNewEntry({ ...newEntry, sigla: e.target.value })}
                                fullWidth
                                sx={{ mb: 2 }}
                            />
                            <TextField
                                label="Classificação Mont"
                                value={newEntry.classificacao}
                                onChange={(e) => setNewEntry({ ...newEntry, classificacao: e.target.value })}
                                fullWidth
                                sx={{ mb: 2 }}
                            />
                            <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                onClick={handleAddOrUpdateEntryAndSave}
                                disabled={saving}
                            >
                                {saving ? 'Salvando...' : 'Adicionar e Salvar Classe Mont'}
                            </Button>
                        </Paper>
                    </Box>

                    <EstatisticasSection
                        statsParametros={parametrosEstatisticos}
                        setStatsParametros= {handleStatsChange}//{setParametrosEstatisticos}
                        hasStatsChanges={hasStatsChanges}
                        setHasStatsChanges={setHasStatsChanges}
                        onSave={() => handleSave(parametrosEstatisticos, 'Estatisticos')}
                        savingStats={saving}
                    />
                    <IndicadoresSection
                        showPublicoAlvo={false}
                        publicoAlvo={parametrosRanking.publicoAlvo || "SIM"}
                        setPublicoAlvo={(value) => handleRankingChange({ ...parametrosRanking, publicoAlvo: value })}
                        indicadoresDisponiveis={indicadoresDisponiveis}
                        setIndicadoresDisponiveis={setIndicadoresDisponiveis}
                        indicadoresSelecionados={indicadoresSelecionados}
                        setIndicadoresSelecionados={setIndicadoresSelecionados}
                        orderAsc={orderAsc}
                        setOrderAsc={setOrderAsc}
                        hasRankingChanges={hasRankingChanges}
                        setHasRankingChanges={setHasRankingChanges}
                        onSave={() => handleSave({
                            indicadores_desejados: indicadoresSelecionados,
                            indicadores_desejados_asc: indicadoresSelecionados.map((ind) => orderAsc[ind]),
                        }, 'Ranking')}
                        message="Configure os indicadores e a ordem para o ranking de ativos."
                    />
                </>
            )}

            <CustomSnackbar
                open={snackbar.open}
                message={snackbar.message}
                severity={snackbar.severity}
                onClose={() => setSnackbar({ ...snackbar, open: false })}
                position={snackbar.position}
            />
        </Box>
    );
};

export default ConfiguracoesBase;
