import React, { useState, useEffect, useCallback } from 'react';
import { Box, Typography, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CustomDialog from '../../components/Modal/CustomDialog';
import FullScreenLoader from '../../components/FullScreenLoader';
import CustomSnackbar from '../../components/CustomSnackbar';
import Pagination from '../../components/Pagination';
import GenericTable from '../../components/GenericTable';
import { AddUpdateClassificacaoAtivos, uploadClassificacaoAtivos, fetchClassificacaoAtivosPaginado, deleteClassificacaoAtivos } from '../../services/rendaVariavel';
import EditButton from '../../components/ActionButton/EditButton';
import RemoveButton from '../../components/ActionButton/RemoveButton';

const ClassificacaoRendaVariavel = () => {
    const [dialogOpen, setDialogOpen] = useState(false);
    const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
    const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [file, setFile] = useState(null);
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success',
        title: '',
    });
    const [formData, setFormData] = useState({
        "TICKER B3": "",
        "DENOM_MONT(igual da B3)": "",
        "Segmentos(GESTORA)": "",
        "Tipo Ativo(GESTORA)": "",
    });
    const [editMode, setEditMode] = useState(false);
    const [tickerToDelete, setTickerToDelete] = useState(null);

    // Estados para paginação
    const [rows, setRows] = useState([]);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [totalPages, setTotalPages] = useState(1);

    // Colunas da tabela
    const columns = [
        { label: 'Ticker B3', field: 'TICKER B3', width: '20%' },
        { label: 'Nomenclatura', field: 'DENOM_MONT(igual da B3)', width: '30%' },
        { label: 'Segmento', field: 'Segmentos(GESTORA)', width: '25%' },
        { label: 'Tipo de Ativo', field: 'Tipo Ativo(GESTORA)', width: '25%' },
    ];

    // Função para carregar dados da API
    const fetchRows = useCallback(async () => {
        try {
            const data = await fetchClassificacaoAtivosPaginado(page, limit);
            setRows(data.ticker_b3);
            setTotalPages(data.totalPages || 1);
        } catch (error) {
            setSnackbar({
                open: true,
                message: 'Erro ao carregar dados da tabela.',
                severity: 'error',
                title: 'Erro',
            });
        }
    }, [page, limit]);

    useEffect(() => {
        fetchRows();
    }, [fetchRows]);

    const handleAddTicker = async () => {
        setLoading(true);
        try {
            await AddUpdateClassificacaoAtivos(formData);
            setDialogOpen(false);
            setSnackbar({
                open: true,
                message: 'Ticker adicionado ou atualizado com sucesso!',
                severity: 'success',
                title: 'Sucesso',
            });
            fetchRows();
            setFormData({
                "TICKER B3": "",
                "DENOM_MONT(igual da B3)": "",
                "Segmentos(GESTORA)": "",
                "Tipo Ativo(GESTORA)": "",
            });
        } catch (error) {
            setSnackbar({
                open: true,
                message: error.message || 'Erro ao adicionar o ticker. Por favor, tente novamente.',
                severity: 'error',
                title: 'Erro',
            });
        } finally {
            setLoading(false);
        }
    };

    const handleFileUpload = async () => {
        if (!file) {
            setSnackbar({
                open: true,
                message: 'Por favor, selecione um arquivo para fazer o upload.',
                severity: 'error',
                title: 'Erro',
            });
            return;
        }
        
        setLoading(true);
        try {
            await uploadClassificacaoAtivos(file);
            setUploadDialogOpen(false);
            setSnackbar({
                open: true,
                message: 'Arquivo importado com sucesso!',
                severity: 'success',
                title: 'Sucesso',
            });
            setFile(null);
            fetchRows();
        } catch (error) {
            setSnackbar({
                open: true,
                message: error.message || 'Erro ao fazer upload do arquivo. Por favor, tente novamente.',
                severity: 'error',
                title: 'Erro',
            });
        } finally {
            setLoading(false);
        }
    };

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const handleFieldChange = (field, value) => {
        setFormData({
            ...formData,
            [field]: value,
        });
    };

    const handleSnackbarClose = () => {
        setSnackbar({ ...snackbar, open: false });
    };

    const handleEdit = (ticker) => {
        const row = rows.find((r) => r["TICKER B3"] === ticker);
        if (row) {
            setFormData(row);
            setEditMode(true);
            setDialogOpen(true);
        }
    };

    const handleDeleteConfirmation = (ticker) => {
        setTickerToDelete(ticker);
        setRemoveDialogOpen(true);
    };

    const handleDelete = async () => {
        try {
            await deleteClassificacaoAtivos(tickerToDelete);
            setSnackbar({
                open: true,
                message: `Classificação de ${tickerToDelete} removida com sucesso!`,
                severity: 'success',
                title: 'Sucesso',
            });
            fetchRows();
        } catch (error) {
            setSnackbar({
                open: true,
                message: `Erro ao remover classificação de ${tickerToDelete}.`,
                severity: 'error',
                title: 'Erro',
            });
        } finally {
            setRemoveDialogOpen(false);
            setTickerToDelete(null);
        }
    };

    const renderActions = (row) => (
        <Box display="flex" justifyContent="center" gap={1}>
            <EditButton onEdit={() => handleEdit(row["TICKER B3"])} />
            <RemoveButton onRemove={() => handleDeleteConfirmation(row["TICKER B3"])} />
        </Box>
    );

    return (
        <Box sx={{ width: '100%' }}>
            <Typography variant="h4" gutterBottom>
                Classificação Renda Variável
            </Typography>
            <Box display="flex" gap={2} mt={2}>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={() => {
                        setFormData({
                            "TICKER B3": "",
                            "DENOM_MONT(igual da B3)": "",
                            "Segmentos(GESTORA)": "",
                            "Tipo Ativo(GESTORA)": "",
                        });
                        setEditMode(false);
                        setDialogOpen(true);
                    }}
                >
                    Adicionar Ticker
                </Button>
                <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<CloudUploadIcon />}
                    onClick={() => setUploadDialogOpen(true)}
                >
                    Importar Arquivo
                </Button>
            </Box>

            <GenericTable
                data={rows}
                columns={columns}
                emptyMessage="Nenhuma classificação disponível."
                renderActions={renderActions}
            />

            <Pagination
                totalPages={totalPages}
                currentPage={page}
                limit={limit}
                onPageChange={(newPage) => setPage(newPage)}
                onLimitChange={(newLimit) => {
                    setLimit(newLimit);
                    setPage(1);
                }}
            />

            <CustomDialog
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
                onConfirm={handleAddTicker}
                title={editMode ? `Atualizar Ticker: ${formData["TICKER B3"]}` : "Adicionar Classificação de Ticker"}
                confirmButtonText={editMode ? "Atualizar" : "Adicionar"}
                cancelButtonText="Cancelar"
                fields={editMode ? [
                    { name: "DENOM_MONT(igual da B3)", label: "Nomenclatura", type: "text", value: formData["DENOM_MONT(igual da B3)"] },
                    { name: "Segmentos(GESTORA)", label: "Segmento", type: "text", value: formData["Segmentos(GESTORA)"] },
                    { name: "Tipo Ativo(GESTORA)", label: "Tipo do Ativo", type: "text", value: formData["Tipo Ativo(GESTORA)"] },
                ] : [
                    { name: "TICKER B3", label: "Ticker B3", type: "text", value: formData["TICKER B3"] },
                    { name: "DENOM_MONT(igual da B3)", label: "Nomenclatura", type: "text", value: formData["DENOM_MONT(igual da B3)"] },
                    { name: "Segmentos(GESTORA)", label: "Segmento", type: "text", value: formData["Segmentos(GESTORA)"] },
                    { name: "Tipo Ativo(GESTORA)", label: "Tipo do Ativo", type: "text", value: formData["Tipo Ativo(GESTORA)"] },
                ]}
                onFieldChange={handleFieldChange}
            />

            <CustomDialog
                open={uploadDialogOpen}
                onClose={() => setUploadDialogOpen(false)}
                onConfirm={handleFileUpload}
                title="Importar Classificação de Ativos"
                confirmButtonText="Importar"
                cancelButtonText="Cancelar"
                fields={[
                    {
                        name: "file",
                        label: "Selecione o arquivo CSV ou XLSX com as classificações:",
                        type: "file",
                        accept: ".csv, .xlsx",
                        onChange: handleFileChange,
                    },
                ]}
            />

            <CustomDialog
                open={removeDialogOpen}
                onClose={() => setRemoveDialogOpen(false)}
                onConfirm={handleDelete}
                title={`Confirmação de Remoção`}
                confirmButtonText="Remover"
                cancelButtonText="Cancelar"
                description={
                    <>
                        Tem certeza de que deseja remover o ticker <span style={{ color: '#d32f2f', fontWeight: 'bold' }}>{tickerToDelete}</span>?
                    </>
                }
            />

            <CustomSnackbar
                open={snackbar.open}
                message={snackbar.message}
                severity={snackbar.severity}
                title={snackbar.title}
                onClose={handleSnackbarClose}
            />

            <FullScreenLoader message="Carregando..." visible={loading} />
        </Box>
    );
};

export default ClassificacaoRendaVariavel;
