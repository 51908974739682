import React, { useState, useEffect } from 'react';
import { fetchRendaVariavelStatsTicker,fetchRendaVariavelAtivoRanking, fetchRendaVariavelAtivoAprovados,sendRendaVariavelAtivoAprovados } from '../../services/rendaVariavel';
import DataTable from '../../components/DataTable';
import { Box, Typography, Button } from '@mui/material';
import CustomSnackbar from '../../components/CustomSnackbar';
import ModalFundo from '../../components/Modal/ModalFundo';
import { criarChartData, RentSum } from '../../components/utils/chartUtils' // '../utils/chartUtils';
import NoDataMessage from '../../components/NoDataMessage';

const RendaVariavelETF = () => {
    const [rows, setRows] = useState([]);
    const [approvedRows, setApprovedRows] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedFunds, setSelectedFunds] = useState([]);
    const [, setApprovedTICKERs] = useState([]);
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
    const [modalOpen, setModalOpen] = useState(false);
    const [infoGerais, setInfoGerais] = useState([]);
    const [estatisticas, setEstatisticas] = useState([]);
    const [graficoData, setGraficoData] = useState(null);
    const [modalLoading, setModalLoading] = useState(false);

    const tipo_endpoint = "etf";

    // Definindo colunas padrão (sem checkbox)
    const defaultColumns =  [
            { field: 'TICKER B3', headerName: 'TICKER B3', width: 200 , description: "Ticker do Ativo",},
            { field: 'RENTMED_AT%', headerName: 'Rent. Média %', width: 150 , description: "Rentabilidade Média Anual media do Ativo (%)"},
            { field: 'RENT_%DI+', headerName: 'Rent. %DI+', width: 150, description: "Rentabilidade(Spread Anual) media  do Ativo em relação ao CDI (%)" },
            { field: 'RENT_%IBOV+', headerName: 'Rent. %IBOV+', width: 150, description: "Rentabilidade(Spread Anual) media  do Ativo em relação ao IBOV (%)"},
            { field: 'RENT_%IMABG+', headerName: 'Rent. %IMABG+', width: 150 , description: "Rentabilidade(Spread Anual) media  do Ativo em relação ao IMAB Geral (%)"},
            { field: 'RENT_%IHFA+', headerName: 'Rent. %IHFA+', width: 150, description: "Rentabilidade(Spread Anual) media  do Ativo em relação ao IHFA (%)"},
            { field: 'RENT_%IFIX+', headerName: 'Rent. %IFIX+', width: 150 , description: "Rentabilidade(Spread Anual) media  do Ativo em relação ao IFIX (%)"},
            { field: 'DP(AT)%', headerName: 'DP(AT)%', width: 150 , description: "Média do desvio Padrão Anualizado do Ativo (%)"},
            { field: 'CORR(AT,IBOV)', headerName: 'Correlação (AT, IBOV)', width: 150 , description:"Média da Correlação do Ativo em relação ao Ibovespa do Ativo (d.u)"},
            { field: 'BETA', headerName: 'Beta', width: 150 ,description:"Média do Beta do Ativo (d.u)"},
            { field: 'SHARPE', headerName: 'Sharpe', width: 150 , description:"Média do Sharpe do Ativo"},
            { field: 'alfa_jensen', headerName: 'Alfa Jensen', width: 150 , description:"Média do Alfa de Jensen  Anual"},
            { field: 'volume', headerName: 'Volume Negociado (R$)', width: 150 , description:"Média do Volume Negociado (R$)"},
            { field: 'Risco_Fundo', headerName: 'Risco do Ativo', width: 150 },
            { field: 'MÍNIMO_SHARPE_ACEITAVEL%', headerName: 'Mín. Sharpe Aceitável %', width: 180 , description:"Porcentagem Mínima para o Sharpe ser aceitável "},
            { field: 'PORCENT_MAIOR_X_SHARPE', headerName: 'Porcent. Maior x Sharpe', width: 180 , description:"Porcentagem que o Ativo é maior que a referência estabelecida para Sharpe"},
            { field: 'MÍNIMO_ALFA_JENSEN_ACEITAVEL%', headerName: 'Mín. Alfa Jensen Aceitável %', width: 200 , description:"Porcentagem Mínima para o Alfa de Jensen ser aceitável "},
            { field: 'PORCENT_MAIOR_X_ALFA_JENSEN', headerName: 'Porcent. Maior x Alfa Jensen', width: 200, description:"Porcentagem que o Ativo é maior que a referência estabelecida para Alfa de Jensen " },
          ];

    // Colunas para a tabela de Ranking com checkbox de Aprovados
    const rankingColumns = [
        {
            field: 'Aprovados',
            headerName: 'Aprovados',
            width: 150,
            renderCell: (params) => (
                <input
                    type="checkbox"
                    id={`checkbox-${params.row["TICKER B3"]}`}
                    name={`checkbox-${params.row["TICKER B3"]}`}
                    checked={selectedFunds.includes(params.row["TICKER B3"])}
                    onChange={() => handleSelect(params.row["TICKER B3"])}
                />
            ),
        },
        ...defaultColumns,
    ];

    // Colunas para a tabela de Ativos Aprovados
    const approvedColumns = [
        { field: 'Aprovados', headerName: 'Aprovados', width: 100 },
        ...defaultColumns,
    ];

    //----------------- Modal -------------------------------------//
    // Função para abrir o modal ao clicar na linha da tabela
    const handleRowClick = async (params) => {
        if (params.field !== "Aprovados" ) {
            setModalLoading(true); // Ativa o loading do modal
            setModalOpen(true);
            const ticker = params.row["TICKER B3"]
            const infoGeraisData = [
                { label: 'Nome do Ativo', value: '' },
                { label: 'Ticker', value: ticker },
                { label: 'Risco', value: params.row.Risco_Fundo },
            ];

            const estatisticasData = [
                { label: 'Rentabilidade Ativo', value: `${params.row['RENTMED_AT%']}%` },
                { label: 'Rentabilidade media em relação ao CDI (%)', value: `${params.row['RENT_%DI+']}%` },
                { label: 'Rentabilidade media em relação ao IBOV (%)', value: `${params.row['RENT_%IBOV+']}%` },
                { label: 'Rentabilidade media em relação ao IMABG (%)', value: `${params.row['RENT_%IMABG+']}%` },
                { label: 'Rentabilidade media em relação ao IHFA (%)', value: `${params.row['RENT_%IHFA+']}%` },
                { label: 'Rentabilidade media em relação ao IFIX(%)', value: `${params.row['RENT_%IFIX+']}%` },
                { label: 'Risco (Desvio Padrão)', value: `${params.row['DP(AT)%']}%` },
                { label: 'BETA', value: params.row.BETA },
                { label: 'SHARPE', value: params.row.SHARPE },
                { label: 'alfa de jensen', value: params.row['alfa_jensen'] },
            ];

            setInfoGerais(infoGeraisData);
            setEstatisticas(estatisticasData);

            try {
                const response = await fetchRendaVariavelStatsTicker(tipo_endpoint,ticker);

                const nomeAtivo =  response[0].shortName;

                // Atualiza o infoGeraisData com o nome do ativo
                setInfoGerais((prevInfoGeraisData) => 
                    prevInfoGeraisData.map(item => 
                        item.label === 'Nome do Ativo' ? { ...item, value: nomeAtivo } : item
                    )
                );
        
                const RentMovelFundoGraph = [
                    { name: ticker, data: criarChartData('RENTMED_AT%', response) },
                    { name: 'DI', data: criarChartData('RENTMED_DI%', response) },
                    { name: 'IBOV', data: criarChartData('RENTMED_IBOV%', response) },
                    { name: 'IMAB-GERAL', data: criarChartData('RENTMED_IMABG%', response) },
                    { name: 'IHFA', data: criarChartData('RENTMED_IHFA%', response) },
                    { name: 'IFIX', data: criarChartData('RENTMED_IFIX%',response) }
                ];   
                const RentSumFundoGraph = [
                    { name: ticker, data: RentSum('RENTLN', response) },
                    { name: 'DI', data: RentSum('rentln_cdi', response) },
                    { name: 'IBOV', data: RentSum('rentln_IBOV', response) },
                    { name: 'IMAB-GERAL', data: RentSum('rentln_imab', response) },
                    { name: 'IHFA', data: RentSum('rentln_ihfa', response) },
                    { name: 'IFIX', data: RentSum('rentln_IFIX', response) }
                ];

                // Variavel do gráfico da volatilidade do Fundo
                const DPFundoGraph = [
                    { name: ticker, data: criarChartData('DP(AT)%', response) }
                ];
                // Variavel do gráfico da volatilidade do Fundo
                const SharpeFundoGraph = [
                    { name: ticker, data: criarChartData('SHARPE', response) }
                ];

                const BetaFundoGraph = [
                    { name: ticker, data: criarChartData('BETA', response) }
                ];

                const AlfaJensenFundoGraph = [
                    { name: ticker, data: criarChartData('alfa_jensen', response) }
                ];

                const CorrIndFundoGraph = [
                    { name: 'Corr IBOV', data: criarChartData('CORR(AT,IBOV)', response) },
                    { name: 'Corr IMAB-GERAL', data: criarChartData('CORR(AT,IMABG)', response) },
                    { name: 'Corr IHFA', data: criarChartData('CORR(AT,IHFA)', response) },
                    { name: 'Corr IFIX', data: criarChartData('CORR(AT,IFIX)', response) }

                ];

                const DrawdownFundoGraph = [
                    { name: ticker, data: criarChartData('DRAWDOWN', response) }
                ];

                setGraficoData([
                    { title: 'Rentabilidade Média Móvel', series: RentMovelFundoGraph, yAxisTitle: 'Rentabilidade (%)' ,suffix: "%",height: "55%"},
                    { title: 'Rentabilidade Acumulada', series: RentSumFundoGraph, yAxisTitle: 'Rentabilidade (%)' ,suffix: "%",height: "55%"},
                    { title: 'Volatilidade do Ativo', series: DPFundoGraph, yAxisTitle: 'Volatilidade (%)' ,suffix: "%",height: "55%"},
                    { title: 'SHARPE do Ativo', series: SharpeFundoGraph, yAxisTitle: 'SHARPE' ,height: "55%"},
                    { title: 'BETA do Ativo', series: BetaFundoGraph, yAxisTitle: 'BETA' ,height: "55%"},
                    { title: 'Alda de Jensen do Ativo', series: AlfaJensenFundoGraph, yAxisTitle: 'Alfa de Jensen' ,height: "55%"},
                    { title: 'Correlação do Ativo', series: CorrIndFundoGraph, yAxisTitle: 'Correlação' ,height: "55%"},
                    { title: 'DRAWDOWN', series: DrawdownFundoGraph, yAxisTitle: 'DRAWDOWN (%)',suffix: "%" ,height: "55%"},
                    
                ]); // Dados para os gráficos


                // setModalOpen(true);
            } catch (error) {
                console.error('Erro ao carregar os dados do ETF:', error);
                setSnackbar({ open: true, message: 'Erro ao carregar os dados do ETF.', severity: 'error' , position: { vertical: 'top', horizontal: 'center' }});
            } finally {
                setModalLoading(false);
            }
        }
    };

    const handleCloseModal = () => {
        setModalOpen(false);
        setGraficoData(null);
    };

    //----------------- Modal -------------------------------------//

    // Função para buscar os dados de ranking e fundos aprovados
    // const fetchData = async () => {
    //     setLoading(true);
    //     try {
    //         const rankingData = await fetchRendaVariavelAtivoRanking(tipo_endpoint);
    //         const approvedData = await fetchRendaVariavelAtivoAprovados(tipo_endpoint);
    //         setRows(rankingData);
    //         setApprovedRows(approvedData);
    //         setApprovedTICKERs(approvedData.map(fundo => fundo["TICKER B3"]));
    //         setSelectedFunds(approvedData.map(fundo => fundo["TICKER B3"]));
    //     } catch (error) {
    //         console.error('Erro ao buscar os dados:', error);
    //         setSnackbar({ open: true, message: 'Erro ao buscar os dados.', severity: 'error', position: { vertical: 'top', horizontal: 'center' } });
    //     } finally {
    //         setLoading(false);
    //     }
    // };

    const fetchData = async () => {
        setLoading(true);
        let rankingData = [];
        let approvedData = [];
    
        try {
            rankingData = await fetchRendaVariavelAtivoRanking(tipo_endpoint);
            setRows(rankingData);
        } catch (error) {
            console.error('Erro ao buscar os dados do ranking:', error);
            setSnackbar({
                open: true,
                message: 'Erro ao buscar os dados do ranking.',
                severity: 'error',
                position: { vertical: 'top', horizontal: 'center' },
            });
        }
    
        try {
            approvedData = await fetchRendaVariavelAtivoAprovados(tipo_endpoint);
            setApprovedRows(approvedData);
            setApprovedTICKERs(approvedData.map((fundo) => fundo["TICKER B3"]));
            setSelectedFunds(approvedData.map((fundo) => fundo["TICKER B3"]));
        } catch (error) {
            console.error('Erro ao buscar os dados aprovados:', error);
            setSnackbar({
                open: true,
                message: 'Erro ao buscar os dados aprovados.',
                severity: 'error',
                position: { vertical: 'top', horizontal: 'center' },
            });
        } finally {
            setLoading(false);
        }
    };

    const handleSelect = (ticker) => {
        setSelectedFunds((prevSelected) => {
            if (prevSelected.includes(ticker)) {
                return prevSelected.filter((fund) => fund !== ticker);
            } else {
                return [...prevSelected, ticker];
            }
        });
    };

    const handleEnviarAprovados = async () => {
        const selectedData = rows
            .filter((row) => selectedFunds.includes(row["TICKER B3"]))
            .map((row) => ({
                ...row,
                Aprovados: true,
            }));
        
        try {
            await sendRendaVariavelAtivoAprovados(tipo_endpoint,selectedData);
            setSnackbar({ open: true, message: 'ETFs enviados com sucesso!', severity: 'success', position: { vertical: 'top', horizontal: 'right' } });
            // fetchData();
            const approvedData = await fetchRendaVariavelAtivoAprovados(tipo_endpoint);
            setApprovedRows(approvedData);
            setApprovedTICKERs(approvedData.map(fundo => fundo["TICKER B3"]));
            setSelectedFunds(approvedData.map(fundo => fundo["TICKER B3"]));
        } catch (error) {
            console.error('Erro ao enviar fundos aprovados:', error);
            setSnackbar({ open: true, message: error.message, severity: 'error', position: { vertical: 'top', horizontal: 'center' } });
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleCloseSnackbar = () => {
        setSnackbar({ ...snackbar, open: false });
    };

    return (
        <Box>
            <Typography variant="h4" gutterBottom>
                Ranking ETFs
            </Typography>
            <Box mb={4}>
                <Typography component="p" variant="body1" sx={{ marginBottom: 3 }}>
                Ranking dos ETFs, a partir dos dados estatísticos selecionados pelo usuário.
                    <br />
                    Obs: Selecionar na ordem de prioridade e se deseja que os dados sejam organizados em Ascendente (do maior para o menor) ou não.
                </Typography>
            </Box>

            
            <Box mb={4}>
                {rows.length === 0 && !loading ? (
                    <NoDataMessage
                    message="Nenhum dado disponível no momento."
                    subMessage="Aguarde ou tente novamente mais tarde."
                    />
                ) : (
                    <DataTable
                    rows={rows}
                    columns={rankingColumns}
                    idColumn="TICKER B3"
                    loading={loading}
                    pageSizeOptions={[10, 25, 50, 100]}
                    onCellClick={handleRowClick}
                    />
                )}
            </Box>

            <Box mb={2}>
                <Button variant="contained" color="primary" onClick={handleEnviarAprovados}>
                    Enviar Selecionados
                </Button>
            </Box>
            <Typography variant="h4" gutterBottom>
                ETFs Aprovados
            </Typography>
            <Box mb={4}>
                <Typography component="p" variant="body1">
                Lista de ETFs Aprovados pelo usuário utilizando tanto critérios quantitativos quanto qualitativos.
                </Typography>
            </Box>

            <Box mb={4}>
                {approvedRows.length === 0 && !loading ? (
                    <NoDataMessage
                    message="Nenhum ETF aprovado encontrado."
                    subMessage="Adicione ou selecione ETFs para vê-los aqui."
                    />
                ) : (
                    <DataTable
                    rows={approvedRows}
                    columns={approvedColumns}
                    idColumn="TICKER B3"
                    loading={loading}
                    pageSizeOptions={[10, 25, 50, 100]}
                    onCellClick={handleRowClick}
                    />
                )}
            </Box>

            <ModalFundo
                modalTitle={"Estatísticas do ETF"}
                open={modalOpen}
                onClose={handleCloseModal}
                infoGerais={infoGerais}
                estatisticas={estatisticas}
                graficos={graficoData || []}
                loading={modalLoading}
            />

            <CustomSnackbar
                open={snackbar.open}
                message={snackbar.message}
                severity={snackbar.severity}
                position={snackbar.position}
                onClose={handleCloseSnackbar}
            />
        </Box>
    );
};

export default RendaVariavelETF;
