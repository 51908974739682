// Função para calcular a rentabilidade acumulada dos indicadores
export function calcularRentabilidadeAcumuladaIndicadores(data, propriedade, minTimestamp, maxTimestamp) {
    let rentabilidadeAcumulada = [];
    let retornoAcumulado = 0;
    let contador = 0;

    data.forEach(item => {
        const timestamp = new Date(item.date).getTime();

        if (timestamp >= minTimestamp && timestamp <= maxTimestamp) {
            let retornoLN = item[propriedade];
            if (contador === 0) {
                retornoLN = 0; // Iniciar no 0
                contador += 1;
            }

            retornoAcumulado += retornoLN;
            const rentabilidade = (Math.exp(retornoAcumulado) - 1) * 100;
            rentabilidadeAcumulada.push([timestamp, rentabilidade]);
        }
    });

    return rentabilidadeAcumulada;
}

// Função para calcular a rentabilidade móvel (média móvel acumulada)
export function calcularRentabilidadeMovel(data, propriedade, n_periodos) {
    let rentabilidadeMovel = [];
    let rentln = data.map(item => item[propriedade]);
    let dates = data.map(item => new Date(item.date).getTime());

    for (let i = 0; i < rentln.length; i++) {
        if (i >= n_periodos - 1) {
            let somaMovel = 0;
            for (let j = i; j > i - n_periodos; j--) {
                somaMovel += rentln[j];
            }

            let mediaRetornos = (somaMovel / n_periodos) * 252;
            let rentabilidade = (Math.exp(mediaRetornos) - 1) * 100;

            // Arredondar o resultado para 2 casas decimais
            rentabilidade = Math.round(rentabilidade * 100) / 100;
            
            rentabilidadeMovel.push([dates[i], rentabilidade]);
        } else {
            rentabilidadeMovel.push([dates[i], null]);
        }
    }

    return rentabilidadeMovel;
}


