import React, { createContext, useContext, useState, useCallback, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode'; 
import { login as loginService, logout as logoutService, checkAdminScope } from '../services/auth';
import { apiWithAuth } from '../services/api';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(!!sessionStorage.getItem('access_token'));
  const [isAdmin, setIsAdmin] = useState(null);
  const [permissions, setPermissions] = useState([]);
  const [isAdminLogin, setIsAdminLogin] = useState(() => {
    const storedValue = sessionStorage.getItem('isAdminLogin');
    return storedValue !== null ? storedValue === "true" : null;
  });
  

  // Envolvendo a função em useCallback para evitar recriação em cada render
  const extractPermissionsFromToken = useCallback(() => {
    const token = sessionStorage.getItem('access_token');
    if (!token) return [];

    try {
      const decoded = jwtDecode(token);
      return decoded.permissions || [];
    } catch (error) {
      console.error('Erro ao decodificar o token:', error);
      return [];
    }
  }, []); // Sem dependências, pois `sessionStorage.getItem` não muda automaticamente
  
  const verifyAdminStatus = useCallback(async () => {
    if (isAuthenticated && isAdmin === null && isAdminLogin) {
      await new Promise((resolve) => {
        const checkRefreshStatus = () => {
          if (!apiWithAuth.isRefreshing) resolve();
          else setTimeout(checkRefreshStatus, 50);
        };
        checkRefreshStatus();
      });
  
      try {
        const adminStatus = await checkAdminScope();
        setIsAdmin(adminStatus);
      } catch (error) {
        console.error('Erro ao verificar escopo de admin:', error);
        setIsAdmin(false);
      }
    }
  }, [isAuthenticated, isAdmin, isAdminLogin]);
  
  useEffect(() => {
    const checkAuth = async () => {
      if (isAuthenticated) {
        // **Só verifica admin se o usuário estiver na dashboard admin**
        if (isAdminLogin) {
          await verifyAdminStatus();
        }
        setPermissions(extractPermissionsFromToken());
      }
    };
    checkAuth();
  }, [isAuthenticated, isAdminLogin, extractPermissionsFromToken, verifyAdminStatus]);
  

  const login = async (username, password, isAdminLogin = false) => {
    try {
      const data = await loginService(username, password);
      setIsAuthenticated(true);
      sessionStorage.setItem('access_token', data.access_token);
      sessionStorage.setItem('refresh_token', data.refresh_token);

      setIsAdminLogin(isAdminLogin);
      sessionStorage.setItem('isAdminLogin', isAdminLogin.toString());
      // Atualiza as permissões
      setPermissions(extractPermissionsFromToken());

      if (isAdminLogin) {
        const adminCheck = await checkAdminScope(username, password);
        setIsAdmin(adminCheck);
        if (!adminCheck) throw new Error("Não autorizado para a área administrativa");
        window.location.href = '/admin/home';
      } else {
        setIsAdmin(false);
        window.location.href = '/home';
      }
    } catch (error) {
      setIsAuthenticated(false);
      setIsAdmin(false);
      setPermissions([]);
      throw error;
    }
  };

  const logout = async () => {
    try {
      await logoutService(apiWithAuth);
      setIsAuthenticated(false);
      setIsAdmin(false);
      setPermissions([]);
      sessionStorage.removeItem('access_token');
      sessionStorage.removeItem('refresh_token');
      sessionStorage.removeItem('isAdminLogin');
      window.location.href = '/login';
    } catch (error) {
      console.error('Erro no logout:', error);
    }
  };

  // Aguarda até que `isAuthenticated` e `permissions` sejam definidos antes de renderizar as rotas
  if (isAuthenticated === null || (isAuthenticated && !isAdmin && permissions.length === 0)) {
    return null;
  }


  return (
    <AuthContext.Provider value={{ isAuthenticated, isAdmin, permissions, login, logout, verifyAdminStatus, isAdminLogin }}>
      {children}
    </AuthContext.Provider>
  );
};

