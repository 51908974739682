// Mesma pagina 
import React, { useState } from 'react';
import { Box } from '@mui/material';
import NavigationTabs from '../../components/NavigationTabs';
import ClassificacaoRendaVariavel from './ClassificacaoRendaVariavel';
import AtivosClassificadosRendaVariavel from './AtivosClassificadosRendaVariavel';
import AtivosNaoClassificadosRendaVariavel from './AtivosNaoClassificadosRendaVariavel';

const ClassificacaoRendaVariavelIndex = () => {
    const [currentTab, setCurrentTab] = useState('classificacao');

    const tabs = [
        { label: 'Classificação Ativos', value: 'classificacao' },
        { label: 'Ativos Classificados', value: 'classificados' },
        { label: 'Ativos Não Classificados', value: 'nao-classificados' },
    ];

    const renderCurrentTabContent = () => {
        switch (currentTab) {
            case 'classificados':
                return <AtivosClassificadosRendaVariavel />;
            case 'nao-classificados':
                return <AtivosNaoClassificadosRendaVariavel />;
            case 'classificacao':
            default:
                return <ClassificacaoRendaVariavel />;
        }
    };

    return (
        <Box sx={{ width: '100%', padding: '20px' }}>
            <NavigationTabs tabs={tabs} currentTab={currentTab} onChange={setCurrentTab} />
            {renderCurrentTabContent()}
        </Box>
    );
};

export default ClassificacaoRendaVariavelIndex;
