import React from 'react';
import { Box, Paper, Typography, Divider, TextField, Button,Tooltip } from '@mui/material';

const EstatisticasSection = ({ 
    statsParametros, 
    setStatsParametros, 
    hasStatsChanges, 
    setHasStatsChanges, 
    onSave, 
    savingStats 
}) => {
    const handleStatsChange = (e) => {
        const { name, value } = e.target;
        setStatsParametros((prevStats) => ({ ...prevStats, [name]: Number(value) }));
        setHasStatsChanges(true);
    };

    const handleConfigChange = (configType, id, field, value) => {
        setStatsParametros((prevStats) => ({
            ...prevStats,
            [configType]: {
                ...prevStats[configType],
                [id]: { ...prevStats[configType][id], [field]: Number(value) },
            },
        }));
        setHasStatsChanges(true);
    };

    return (
        <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
            <Typography variant="h6">Parâmetros Estatísticos</Typography>
            <Divider sx={{ my: 2 }} />

            <Tooltip title="Valor de Referência para Sharpe.">
                <TextField
                    label="X_SHARPE"
                    type="number"
                    name="X_SHARPE"
                    value={statsParametros?.X_SHARPE ?? 0}
                    onChange={handleStatsChange}
                    fullWidth
                    sx={{ mb: 2 }}
                />
            </Tooltip>

            <Tooltip title="Valor de Referência para Alfa de Jensen.">
                <TextField
                    label="X_ALFA_JENSEN"
                    type="number"
                    name="X_ALFA_JENSEN"
                    value={statsParametros?.X_ALFA_JENSEN ?? 0}
                    onChange={handleStatsChange}
                    fullWidth
                    sx={{ mb: 2 }}
                />
            </Tooltip>

            {/* Configurações de X_SHARPE */}
            <Typography variant="h6">Configurações de X_SHARPE</Typography>
            <Typography variant="body2" color="textSecondary" sx={{mb:2}}>
                Configuração dos Parâmetros de Mínimo Aceitável para Sharpe para cada Nivel de Risco
            </Typography>
            <Divider sx={{ my: 2 }} />
            {statsParametros?.X_SHARPE_CONFIG && Object.entries(statsParametros.X_SHARPE_CONFIG).map(([id, config]) => (
                <Box key={`sharpe-${id}`} display="flex" gap={2} sx={{ mb: 2 }}>
                    <TextField
                        label={`Data Inicial (Ano) ${id}`}
                        type="number"
                        value={config.Data_Inicial ?? 0}
                        onChange={(e) => handleConfigChange("X_SHARPE_CONFIG", id, "Data_Inicial", e.target.value)}
                    />
                    <TextField
                        label="Data Final (Ano)"
                        type="number"
                        value={config.Data_Final ?? 0}
                        onChange={(e) => handleConfigChange("X_SHARPE_CONFIG", id, "Data_Final", e.target.value)}
                    />
                    <TextField
                        label="Valor Inicial (%)"
                        type="number"
                        value={config.Valor_Inicial ?? 0}
                        onChange={(e) => handleConfigChange("X_SHARPE_CONFIG", id, "Valor_Inicial", e.target.value)}
                    />
                    <TextField
                        label="Valor Final (%)"
                        type="number"
                        value={config.Valor_Final ?? 0}
                        onChange={(e) => handleConfigChange("X_SHARPE_CONFIG", id, "Valor_Final", e.target.value)}
                    />
                </Box>
            ))}

            {/* Configurações de X_ALFA_JENSEN */}
            <Typography variant="h6">Configurações de X_ALFA_JENSEN</Typography>
            <Typography variant="body2" color="textSecondary" sx={{mb:2}}>
               Configuração dos Parâmetros de Mínimo Aceitável para Alfa de Jensen para cada Nivel de Risco
            </Typography>
            <Divider sx={{ my: 2 }} />
            {statsParametros?.X_ALFA_JENSEN_CONFIG && Object.entries(statsParametros.X_ALFA_JENSEN_CONFIG).map(([id, config]) => (
                <Box key={`alfa-${id}`} display="flex" gap={2} sx={{ mb: 2 }}>
                    <TextField
                        label={`Data Inicial (Ano) ${id}`}
                        type="number"
                        value={config.Data_Inicial ?? 0}
                        onChange={(e) => handleConfigChange("X_ALFA_JENSEN_CONFIG", id, "Data_Inicial", e.target.value)}
                    />
                    <TextField
                        label="Data Final (Ano)"
                        type="number"
                        value={config.Data_Final ?? 0}
                        onChange={(e) => handleConfigChange("X_ALFA_JENSEN_CONFIG", id, "Data_Final", e.target.value)}
                    />
                    <TextField
                        label="Valor Inicial (%)"
                        type="number"
                        value={config.Valor_Inicial ?? 0}
                        onChange={(e) => handleConfigChange("X_ALFA_JENSEN_CONFIG", id, "Valor_Inicial", e.target.value)}
                    />
                    <TextField
                        label="Valor Final (%)"
                        type="number"
                        value={config.Valor_Final ?? 0}
                        onChange={(e) => handleConfigChange("X_ALFA_JENSEN_CONFIG", id, "Valor_Final", e.target.value)}
                    />
                </Box>
            ))}

            <Button
                variant="contained"
                color="primary"
                onClick={onSave}
                disabled={!hasStatsChanges || savingStats}
                sx={{ mt: 3 }}
            >
                {savingStats ? 'Salvando...' : 'Atualizar Parâmetros Estatísticos'}
            </Button>
        </Paper>
    );
};

export default EstatisticasSection;
