import React from 'react';
import { Box, Paper, Typography, Divider, Select, MenuItem, List, ListItem, ListItemText, IconButton, Button, Switch } from '@mui/material';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { ArrowForward, ArrowBack } from '@mui/icons-material';

const IndicadoresSection = ({
    showPublicoAlvo = true,
    publicoAlvo,
    setPublicoAlvo,
    indicadoresDisponiveis,
    setIndicadoresDisponiveis,
    indicadoresSelecionados,
    setIndicadoresSelecionados,
    orderAsc,
    setOrderAsc,
    hasRankingChanges,
    setHasRankingChanges,
    onSave,
    message,
}) => {
    const handlePublicoAlvoChange = (event) => {
        setPublicoAlvo(event.target.value);
        setHasRankingChanges(true);
    };

    const handleSelectIndicator = (indicator) => {
        setIndicadoresSelecionados((prev) => [...prev, indicator]);
        setIndicadoresDisponiveis((prev) => prev.filter((ind) => ind !== indicator));
        setOrderAsc((prev) => ({ ...prev, [indicator]: true }));
        setHasRankingChanges(true);
    };

    const handleDeselectIndicator = (indicator) => {
        setIndicadoresDisponiveis((prev) => [...prev, indicator]);
        setIndicadoresSelecionados((prev) => prev.filter((ind) => ind !== indicator));
        const newOrderAsc = { ...orderAsc };
        delete newOrderAsc[indicator];
        setOrderAsc(newOrderAsc);
        setHasRankingChanges(true);
    };

    const handleToggleOrder = (indicator) => {
        setOrderAsc((prev) => ({ ...prev, [indicator]: !prev[indicator] }));
        setHasRankingChanges(true);
    };

    const handleDragEnd = (result) => {
        if (!result.destination) return;
        const reorderedList = Array.from(indicadoresSelecionados);
        const [movedItem] = reorderedList.splice(result.source.index, 1);
        reorderedList.splice(result.destination.index, 0, movedItem);
        setIndicadoresSelecionados(reorderedList);
        setHasRankingChanges(true);
    };

    return (
        <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
            <Typography variant="h6">Parâmetros Ranking</Typography>
            <Divider sx={{ my: 2 }} />

            {/* Mensagem opcional abaixo do título */}
            {message && (
                <Box mb={2} p={1} bgcolor="#f9f9f9" borderRadius={2}>
                    <Typography variant="body2" color="textSecondary" sx={{ fontWeight: 'bold', mb: 1 }}>
                        Observações:
                    </Typography>
                    {message.split("\n").map((line, index) => (
                        <Typography key={index} variant="body2" color="textSecondary" sx={{ pl: 2, display: 'list-item', listStyleType: 'disc' }}>
                            {line.trim()}
                        </Typography>
                    ))}
                </Box>
            )}

            {showPublicoAlvo && (
                <Box mb={3}>
                    <Typography variant="subtitle1">Público Alvo</Typography>
                    <Select value={publicoAlvo ?? "SIM"} onChange={handlePublicoAlvoChange} fullWidth>
                        <MenuItem value="SIM">SIM</MenuItem>
                        <MenuItem value="NÃO">NÃO</MenuItem>
                    </Select>
                </Box>
            )}

            <Box display="flex" gap={4}>
                <Box flex={1}>
                    <Typography variant="subtitle1">Indicadores Disponíveis</Typography>
                    <List dense>
                        {indicadoresDisponiveis.map((indicator) => (
                            <ListItem key={indicator}>
                                <ListItemText primary={indicator} />
                                <IconButton onClick={() => handleSelectIndicator(indicator)}>
                                    <ArrowForward />
                                </IconButton>
                            </ListItem>
                        ))}
                    </List>
                </Box>

                <Box flex={1}>
                    <Typography variant="subtitle1">Indicadores Selecionados (Arraste para ordenar)</Typography>
                    <DragDropContext onDragEnd={handleDragEnd}>
                        <Droppable droppableId="selected-indicators">
                            {(provided) => (
                                <List dense {...provided.droppableProps} ref={provided.innerRef}>
                                    {indicadoresSelecionados.map((indicator, index) => (
                                        <Draggable key={indicator} draggableId={indicator} index={index}>
                                            {(provided) => (
                                                <ListItem ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                    <ListItemText primary={indicator} />
                                                    <Switch
                                                        checked={orderAsc[indicator] ?? true}
                                                        onChange={() => handleToggleOrder(indicator)}
                                                        color="primary"
                                                    />
                                                    <Typography variant="body2" color="textSecondary">
                                                        {orderAsc[indicator] ? 'Ascendente' : 'Descendente'}
                                                    </Typography>

                                                    <IconButton onClick={() => handleDeselectIndicator(indicator)}>
                                                        <ArrowBack />
                                                    </IconButton>
                                                </ListItem>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </List>
                            )}
                        </Droppable>
                    </DragDropContext>
                </Box>
            </Box>

            <Button
                variant="contained"
                color="primary"
                onClick={onSave}
                disabled={!hasRankingChanges}
                sx={{ mt: 3 }}
            >
                Salvar Parâmetros de Ranking
            </Button>

        </Paper>
    );
};

export default IndicadoresSection;
