import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import {
  Tabs,
  Tab,
  Box,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import DataTable from "../../components/DataTable";
import RemoveButton from "../../components/ActionButton/RemoveButton";
import TransferList from "../../components/TransferList";
import CustomSnackbar from "../../components/CustomSnackbar";
import CustomDialog from "../../components/Modal/CustomDialog"; 
import {
  fetchUsersInGroup,
  fetchGroupPermissions,
  fetchUsers,
  fetchPermissions,
  addUsersToGroup,
  addPermissionsToGroup,
  removeUsersFromGroup,
  removePermissionsFromGroup,
} from "../../services/auth";

export default function GroupDetails() {
  const { groupName } = useParams();
  const [tab, setTab] = useState("users");
  const [users, setUsers] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [loadingUsers, setLoadingUsers] = useState(true);
  const [loadingPermissions, setLoadingPermissions] = useState(true);

  const [allUsers, setAllUsers] = useState([]);
  const [allPermissions, setAllPermissions] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogType, setDialogType] = useState(""); // "users" ou "permissions"
  // Estado para os dados do TransferList: available e selected
  const [transferData, setTransferData] = useState({ available: [], selected: [] });
  // Estados para remoção em lote via checkbox (usando a coluna customizada)
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [selectedPermissionIds, setSelectedPermissionIds] = useState([]);
  const [confirmPlannerDialogOpen, setConfirmPlannerDialogOpen] = useState(false);
  // Estado do Snackbar
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "warning",
  });
  // Estado para o diálogo de remoção
  const [removalDialog, setRemovalDialog] = useState({
    open: false,
    removalType: "", // 'individual' ou 'batch'
    itemType: "", // 'users' ou 'permissions'
    target: [], // IDs a serem removidos
  });

  // Carregar usuários do grupo
  const loadUsers = useCallback(async () => {
    setLoadingUsers(true);
    try {
      const data = await fetchUsersInGroup(groupName);
      setUsers(data.users);
    } catch (error) {
      console.error("Erro ao carregar usuários:", error);
    } finally {
      setLoadingUsers(false);
    }
  }, [groupName]);

  // Carregar permissões do grupo
  const loadPermissions = useCallback(async () => {
    setLoadingPermissions(true);
    try {
      const data = await fetchGroupPermissions(groupName);
      const formatted = data.permissions.map((permission) => ({
        id: permission,
        name: permission,
      }));
      setPermissions(formatted);
    } catch (error) {
      console.error("Erro ao carregar permissões:", error);
    } finally {
      setLoadingPermissions(false);
    }
  }, [groupName]);

  const loadAllUsers = useCallback(async () => {
    try {
      const allUsersData = await fetchUsers();
      const formatted = allUsersData.map((user) => ({
        id: user.username,
        name: user.username,
      }));
      setAllUsers(formatted);
    } catch (error) {
      console.error("Erro ao carregar todos os usuários:", error);
    }
  }, []);

  const loadAllPermissions = useCallback(async () => {
    try {
      const allPermissionsData = await fetchPermissions();
      const formatted = allPermissionsData.map((perm) => ({
        id: perm,
        name: perm,
      }));
      setAllPermissions(formatted);
    } catch (error) {
      console.error("Erro ao carregar todas as permissões:", error);
    }
  }, []);

  useEffect(() => {
    loadUsers();
    loadPermissions();
    loadAllUsers();
    loadAllPermissions();
  }, [loadUsers, loadPermissions, loadAllUsers, loadAllPermissions]);

  // Permissões padrão para o planejador
  const defaultPlannerPermissions = [
    "fundos_investimento:gerar_relatorio",
    "dashboard:fundos_investimento",
    "dashboard:fundos_investimento:relatorio_risco",
    "fundos_investimento:cadastro_simplificado:read",
    "planejador:nomes:read",
  ];

  // Abre o diálogo de remoção para remoção em lote
  const openRemovalDialogBatch = (itemType) => {
    if (itemType === "users") {
      if (selectedUserIds.length === 0) return;
      setRemovalDialog({
        open: true,
        removalType: "batch",
        itemType: "users",
        target: selectedUserIds,
      });
    } else if (itemType === "permissions") {
      if (selectedPermissionIds.length === 0) return;
      setRemovalDialog({
        open: true,
        removalType: "batch",
        itemType: "permissions",
        target: selectedPermissionIds,
      });
    }
  };

  // Abre o diálogo de remoção para remoção individual
  const openRemovalDialogIndividual = (itemType, id) => {
    setRemovalDialog({
      open: true,
      removalType: "individual",
      itemType,
      target: [id],
    });
  };

  // Confirma a remoção (batch ou individual)
  const handleConfirmRemoval = async () => {
    try {
      if (removalDialog.itemType === "users") {
        await removeUsersFromGroup(groupName, removalDialog.target);
        loadUsers();
        if (removalDialog.removalType === "batch") {
          setSelectedUserIds([]);
        }
        setSnackbar({
          open: true,
          message: "Usuário(s) removido(s) com sucesso!",
          severity: "success",
          position: { vertical: "top", horizontal: "right" }
        });
      } else if (removalDialog.itemType === "permissions") {
        await removePermissionsFromGroup(groupName, removalDialog.target);
        loadPermissions();
        if (removalDialog.removalType === "batch") {
          setSelectedPermissionIds([]);
        }
        setSnackbar({
          open: true,
          message: "Permissão(ões) removida(s) com sucesso!",
          severity: "success",
          position: { vertical: "top", horizontal: "right" }
        });
      }
    } catch (error) {
      console.error("Erro ao remover:", error);
      setSnackbar({
        open: true,
        message: "Erro ao remover os itens.",
        severity: "error",
        position: { vertical: "top", horizontal: "center" }
      });
    } finally {
      setRemovalDialog({ ...removalDialog, open: false });
    }
  };

  // Funções para alternar a seleção dos itens na coluna customizada
  const toggleUserSelection = (username) => {
    setSelectedUserIds((prev) =>
      prev.includes(username)
        ? prev.filter((id) => id !== username)
        : [...prev, username]
    );
  };

  const togglePermissionSelection = (permissionName) => {
    setSelectedPermissionIds((prev) =>
      prev.includes(permissionName)
        ? prev.filter((id) => id !== permissionName)
        : [...prev, permissionName]
    );
  };

  // Abre o diálogo de adição via TransferList
  const openAddDialog = (type) => {
    setDialogType(type);
    if (type === "users") {
      const available = allUsers.filter(
        (user) => !users.some((groupUser) => groupUser.username === user.id)
      );
      setTransferData({ available, selected: [] });
    } else if (type === "permissions") {
      const available = allPermissions.filter(
        (perm) => !permissions.some((groupPerm) => groupPerm.name === perm.id)
      );
      setTransferData({ available, selected: [] });
    }
    setDialogOpen(true);
  };

  // Confirma a adição via TransferList
  const handleConfirmAdd = async () => {
    if (transferData.selected.length === 0) {
      setSnackbar({
        open: true,
        message: "Selecione pelo menos um item para adicionar.",
        severity: "warning",
        position: { vertical: "top", horizontal: "center" }
      });
      return;
    }
    try {
      if (dialogType === "users") {
        const ids = transferData.selected.map((item) => item.id);
        await addUsersToGroup(groupName, ids);
        loadUsers();
        setSnackbar({
          open: true,
          message: "Usuário(s) adicionado(s) com sucesso!",
          severity: "success",
          position: { vertical: "top", horizontal: "right" }
        });
      } else if (dialogType === "permissions") {
        const ids = transferData.selected.map((item) => item.id);
        await addPermissionsToGroup(groupName, ids);
        loadPermissions();
        setSnackbar({
          open: true,
          message: "Permissão(ões) adicionada(s) com sucesso!",
          severity: "success",
          position: { vertical: "top", horizontal: "right" }
        });
      }
      setDialogOpen(false);
    } catch (error) {
      console.error(`Erro ao adicionar ${dialogType}:`, error);
    }
  };

  // Colunas para a aba de usuários com coluna de checkbox e botão para remoção individual
  const userColumns = [
    {
      field: "selected",
      headerName: "Selecionar",
      width: 100,
      renderCell: (params) => (
        <input
          type="checkbox"
          checked={selectedUserIds.includes(params.row.username)}
          onChange={() => toggleUserSelection(params.row.username)}
        />
      ),
    },
    { field: "username", headerName: "Usuário", flex: 2 },
    {
      field: "joined_at",
      headerName: "Data de Entrada",
      flex: 2,
      renderCell: (params) =>
        new Date(params.value).toLocaleDateString(),
    },
    {
      field: "actions",
      headerName: "Ações",
      flex: 1,
      renderCell: (params) => (
        <RemoveButton
          // Em vez de remover diretamente, abre o diálogo de confirmação individual
          onRemove={() => openRemovalDialogIndividual("users", params.row.username)}
          nome={params.row.username}
        />
      ),
    },
  ];

  // Colunas para a aba de permissões com coluna de checkbox
  const permissionColumns = [
    {
      field: "selected",
      headerName: "Selecionar",
      width: 100,
      renderCell: (params) => (
        <input
          type="checkbox"
          checked={selectedPermissionIds.includes(params.row.name)}
          onChange={() => togglePermissionSelection(params.row.name)}
        />
      ),
    },
    { field: "name", headerName: "Permissão", flex: 2 },
    {
      field: "actions",
      headerName: "Ações",
      flex: 1,
      renderCell: (params) => (
        <RemoveButton
          onRemove={() =>
            openRemovalDialogIndividual("permissions", params.row.name)
          }
          nome={params.row.name}
        />
      ),
    },
  ];


  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4">Grupo: {groupName}</Typography>

    <Tabs
        value={tab}
        onChange={(event, newValue) => {
            setTab(newValue);
            // Limpa as seleções ao mudar de aba
            setSelectedUserIds([]);
            setSelectedPermissionIds([]);
        }}
        sx={{ mb: 3 }}
        >
        <Tab label="Usuários" value="users" />
        <Tab label="Permissões" value="permissions" />
    </Tabs>

      {tab === "users" && (
        <Box sx={{ mt: 2 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => openAddDialog("users")}
            sx={{ mb: 2 }}
          >
            Adicionar Usuário
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => openRemovalDialogBatch("users")}
            disabled={selectedUserIds.length === 0}
            sx={{ mb: 2, ml: 2, opacity: selectedUserIds.length === 0 ? 0.5 : 1 }}
          >
            Remover Selecionados
          </Button>
          <DataTable
            columns={userColumns}
            rows={users}
            idColumn="username"
            loading={loadingUsers}
          />
        </Box>
      )}

      {tab === "permissions" && (
        <Box sx={{ mt: 2 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            {/* Botões à esquerda */}
            <Box>
              <Button
                variant="contained"
                color="primary"
                onClick={() => openAddDialog("permissions")}
                sx={{ mr: 2 }}
              >
                Adicionar Permissão
              </Button>
              <Button
                variant="contained"
                color="error"
                onClick={() => openRemovalDialogBatch("permissions")}
                disabled={selectedPermissionIds.length === 0}
                sx={{ mr: 2 }}
              >
                Remover Selecionadas
              </Button>
            </Box>
            {/* Botão à direita: renderiza somente se não houver permissões no grupo */}
            {permissions.length === 0 && (
              <Button
                variant="contained"
                color="primary"
                onClick={() => setConfirmPlannerDialogOpen(true)}
              >
                Aplicar Modelo Padrão para Planejadores
              </Button>
            )}
          </Box>
          <DataTable
            columns={permissionColumns}
            rows={permissions}
            idColumn="name"
            loading={loadingPermissions}
          />
        </Box>
      )}

      {/* Modal para adicionar itens via TransferList */}
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          Adicionar {dialogType === "users" ? "Usuários" : "Permissões"}
        </DialogTitle>
        <DialogContent>
          {dialogType === "users" && (
            <TransferList
              availableItems={transferData.available}
              selectedItems={transferData.selected}
              onChange={(data) => setTransferData(data)}
              titleAvailable="Usuários Disponíveis"
              titleSelected="Usuários a Adicionar"
            />
          )}
          {dialogType === "permissions" && (
            <TransferList
              availableItems={transferData.available}
              selectedItems={transferData.selected}
              onChange={(data) => setTransferData(data)}
              titleAvailable="Permissões Disponíveis"
              titleSelected="Permissões a Adicionar"
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)}>Cancelar</Button>
          <Button variant="contained" onClick={handleConfirmAdd}>
            Adicionar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Diálogo de Confirmação para remoção */}
      <CustomDialog
        open={removalDialog.open}
        title={`Confirmar remoção de ${
          removalDialog.itemType === "users" ? "usuário(s)" : "permissão(ões)"
        }`}
        description={`Você tem certeza que deseja remover o(s) item(ns) selecionado(s)?`}
        confirmButtonText="Sim"
        cancelButtonText="Não"
        onConfirm={handleConfirmRemoval}
        onClose={() => setRemovalDialog({ ...removalDialog, open: false })}
      />
      <CustomDialog
        open={confirmPlannerDialogOpen}
        onClose={() => setConfirmPlannerDialogOpen(false)}
        onConfirm={async () => {
          try {
            await addPermissionsToGroup(groupName, defaultPlannerPermissions);
            setSnackbar({
              open: true,
              message: "Modelo de permissões aplicado com sucesso!",
              severity: "success",
              title: "Sucesso",
              position: { vertical: "top", horizontal: "right" },
            });
            loadPermissions(); // Atualiza a lista de permissões do grupo
          } catch (error) {
            setSnackbar({
              open: true,
              message: "Erro ao aplicar modelo de permissões.",
              severity: "error",
              title: "Erro",
              position: { vertical: "top", horizontal: "center" },
            });
          } finally {
            setConfirmPlannerDialogOpen(false);
          }
        }}
        title="Aplicar Modelo de Permissões"
        description="Você deseja aplicar o modelo padrão de permissões para planejadores a este grupo?"
        confirmButtonText="Sim"
        cancelButtonText="Cancelar"
      />

      <CustomSnackbar
        open={snackbar.open}
        message={snackbar.message}
        severity={snackbar.severity}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        duration={3000}
        position={snackbar.position}
      />
    </Box>
  );
}
