import React from 'react';
import { Box, Paper, Typography, Divider, TextField, Button, Tooltip } from '@mui/material';

const FiltroSection = ({ 
    parametros, 
    setParametros, 
    hasFiltroChanges, 
    setHasFiltroChanges, 
    savingFiltro, 
    onSave 
}) => {
    const handleChange = (e) => {
        const { name, value } = e.target;
        setParametros((prev) => ({ ...prev, [name]: Number(value) }));
        setHasFiltroChanges(true);  // Marcar mudanças
    };

    return (
        <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
            <Typography variant="h6">Parâmetros Filtro</Typography>
            <Typography variant="body2" color="textSecondary" sx={{mb:2}}>
            Condições que caso o Ativo atenda pelo menos uma, será excluído da análise.
            </Typography>
            <Divider sx={{ my: 2 }} />

            <Box display="flex" flexDirection="column" gap={2}>
                <Tooltip title="Define o patrimônio mínimo do fundo">
                    <TextField
                        label="Patrimônio Líquido (menor que)"
                        type="number"
                        name="Patrimonio_Liquido"
                        value={parametros?.Patrimonio_Liquido ?? 0}
                        onChange={handleChange}
                        fullWidth
                        disabled={savingFiltro}
                    />
                </Tooltip>

                <Tooltip title="Define o número mínimo de cotistas do fundo">
                    <TextField
                        label="Número de Cotistas (menor que)"
                        type="number"
                        name="Nr_Cotista"
                        value={parametros?.Nr_Cotista ?? 0}
                        onChange={handleChange}
                        fullWidth
                        disabled={savingFiltro}
                    />
                </Tooltip>

                <Tooltip title="Define o valor mínimo do patrimônio líquido por cotista">
                    <TextField
                        label="Patrimônio Líquido/Número de Cotistas (maior que)"
                        type="number"
                        name="Patrimonio_Liquido/Nr_Cotista"
                        value={parametros?.['Patrimonio_Liquido/Nr_Cotista'] ?? 0}
                        onChange={handleChange}
                        fullWidth
                        disabled={savingFiltro}
                    />
                </Tooltip>
            </Box>

            <Button
                variant="contained"
                color="primary"
                onClick={onSave}
                disabled={!hasFiltroChanges || savingFiltro}
                sx={{ mt: 3 }}
            >
                {savingFiltro ? 'Salvando...' : 'Atualizar Parâmetros de Filtro'}
            </Button>
        </Paper>
    );
};

export default FiltroSection;
