// CustomDialogV2.jsx
import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';

const CustomDialogV2 = ({
  open,
  onClose,
  onConfirm,
  title,
  description,
  confirmButtonText,
  cancelButtonText,
}) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent>
        {/* Aqui usamos o Typography com component="div" para não renderizar um <p> */}
        <Typography component="div">
          {description}
        </Typography>
      </DialogContent>
      <DialogActions>
        {cancelButtonText && (
          <Button onClick={onClose}>
            {cancelButtonText}
          </Button>
        )}
        {confirmButtonText && (
          <Button onClick={onConfirm} autoFocus>
            {confirmButtonText}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default CustomDialogV2;
