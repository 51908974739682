import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import Select from '@mui/material/Select'; // Adicionando Select
import MenuItem from '@mui/material/MenuItem'; // Para opções do Select
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

const CustomFormDialog = ({
    open,
    onClose,
    onConfirm,
    title,
    description,
    confirmButtonText = "Confirmar",
    cancelButtonText = "Cancelar",
    fields = [],
    onFieldChange,
}) => {
    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>{title}</DialogTitle>
            <form onSubmit={(e) => { e.preventDefault(); onConfirm(); }}>
                <DialogContent>
                    {description && (
                        <Typography variant="body1" gutterBottom>
                            {description}
                        </Typography>
                    )}
                    {fields.map((field) => (
                        <div key={field.name} style={{ marginBottom: 16 }}>
                            <Typography variant="body2" gutterBottom>
                                {field.label}
                            </Typography>

                            {field.type === "select" ? (
                                // Adicionando suporte a Select (Dropdown)
                                <FormControl fullWidth variant="outlined">
                                    <InputLabel>{field.label}</InputLabel>
                                    <Select
                                        value={field.value || ""}
                                        onChange={(e) => onFieldChange(field.name, e.target.value)}
                                    >
                                        {field.options.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            ) : field.type === "radio" ? (
                                <RadioGroup
                                    value={field.value}
                                    onChange={(e) => onFieldChange(field.name, e.target.value)}
                                >
                                    {field.options.map((option) => (
                                        <FormControlLabel
                                            key={option.value}
                                            value={option.value}
                                            control={<Radio />}
                                            label={option.label}
                                        />
                                    ))}
                                </RadioGroup>
                            ) : field.type === "file" ? (
                                <input
                                    type="file"
                                    accept={field.accept}
                                    onChange={(e) => onFieldChange(field.name, e.target.files[0])}
                                    style={{ width: '100%', padding: '10px', border: '1px solid #ccc', borderRadius: '4px' }}
                                />
                            ) : (
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    placeholder={field.placeholder || ""}
                                    type={field.type}
                                    value={field.value || ""}
                                    onChange={(e) => onFieldChange(field.name, e.target.value)}
                                    error={field.error}
                                    helperText={field.error ? "Campo obrigatório" : ""}
                                    required={field.required || false}
                                    autoComplete={field.autocomplete || "off"}
                                />
                            )}
                        </div>
                    ))}
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} variant="outlined" color="secondary">
                        {cancelButtonText}
                    </Button>
                    <Button type="submit" variant="contained" color="primary">
                        {confirmButtonText}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default CustomFormDialog;
