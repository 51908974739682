import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import AssessmentIcon from '@mui/icons-material/Assessment';  
import SettingsTwoToneIcon from '@mui/icons-material/SettingsTwoTone';
import TrendingUpRoundedIcon from '@mui/icons-material/TrendingUpRounded';
import ModuleCard from '../../components/ModuleCard';
import { menuItems } from '../../routes/routes';
import { useAuth } from '../../context/AuthContext';

const CreditoPrivado = () => {
  const theme = useTheme();
  const { permissions } = useAuth(); // Obtém as permissões do usuário

  const creditoPrivadoModule = menuItems.find(item => item.text === 'Crédito Privado');

  // Filtra apenas os submódulos que o usuário tem permissão para acessar
  const allowedSubmodules =
    creditoPrivadoModule?.submodules?.filter(
      sub => !sub.requiredPermission || permissions.includes(sub.requiredPermission)
    ) || [];

  const submoduleIcons = {
    "Configurações": <SettingsTwoToneIcon sx={{ fontSize: 60 }} />,
    "Ranking": <TrendingUpRoundedIcon sx={{ fontSize: 60 }} />,
  };

  // Calcula o total de colunas com base em 1 coluna para cada grupo de 3 submódulos
  const totalColumns = Math.ceil(allowedSubmodules.length / 3);

  // Função auxiliar para limitar o número de colunas conforme o breakpoint:
  // xs: máximo 1 coluna, sm: máximo 2, md: máximo 3, lg: utiliza o total calculado.
  const getColumnsForBreakpoint = (breakpoint) => {
    if (breakpoint === 'xs') return Math.min(totalColumns, 1);
    if (breakpoint === 'sm') return Math.min(totalColumns, 2);
    if (breakpoint === 'md') return Math.min(totalColumns, 3);
    return totalColumns;
  };

  return (
    <Box sx={{ flexGrow: 1, padding: 2 }}>
      <Typography
        variant="h4"
        align="center"
        gutterBottom
        sx={{ fontWeight: 'bold', color: theme.palette.text.primary }}
      >
        Crédito Privado
      </Typography>

      {/* Se o usuário não tiver permissão para nenhum submódulo, exibe um aviso */}
      {allowedSubmodules.length === 0 ? (
        <Typography align="center" sx={{ color: theme.palette.text.secondary }}>
          Você não tem permissão para acessar os submódulos deste módulo.
        </Typography>
      ) : (
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: {
              xs: `repeat(${getColumnsForBreakpoint('xs')}, 1fr)`,
              sm: `repeat(${getColumnsForBreakpoint('sm')}, 1fr)`,
              md: `repeat(${getColumnsForBreakpoint('md')}, 1fr)`,
              lg: `repeat(${getColumnsForBreakpoint('lg')}, 1fr)`,
            },
            gap: 2,
            paddingX: { xs: 2, md: 6 },
          }}
        >
          {allowedSubmodules.map((submodule, index) => (
            <ModuleCard
              key={index}
              title={submodule.text}
              description={`Acesse o módulo ${submodule.text}.`}
              icon={submoduleIcons[submodule.text] || <AssessmentIcon sx={{ fontSize: 60 }} />}
              route={submodule.route}
            />
          ))}
        </Box>
      )}
    </Box>
  );
};

export default CreditoPrivado;
