import React from 'react';
import { Pagination as MuiPagination, Box, Typography, Select, MenuItem, useTheme } from '@mui/material';

const Pagination = ({ totalPages, currentPage, limit, onPageChange, onLimitChange, limitOptions = [5, 10, 20, 50] }) => {
    const theme = useTheme();  // Usa o tema atual (light/dark)

    const handlePageChange = (event, newPage) => {
        onPageChange(newPage);  // Chama a função de mudança de página
    };

    const handleLimitChange = (event) => {
        onLimitChange(event.target.value);  // Chama a função de mudança do limit
    };

    return (
        <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mt={2}
            flexDirection={{ xs: 'column', sm: 'row' }}  // Responsivo para telas menores
            width="100%"
        >
            {/* Dropdown para selecionar quantos itens por página */}
            <Box display="flex" alignItems="center" mb={{ xs: 2, sm: 0 }}>
                <Typography variant="body1" fontWeight={theme.typography.body1.fontWeight} mr={1}>
                    Registros por página:
                </Typography>
                <Select
                    id="limit-page-select"
                    name="limit-row-page"
                    value={limit}
                    onChange={handleLimitChange}
                    size="small"
                    sx={{
                        minWidth: 70,
                        backgroundColor: theme.palette.background.paper,
                    }}
                >
                    {limitOptions.map(option => (
                        <MenuItem key={option} value={option}>
                            {option}
                        </MenuItem>
                    ))}
                </Select>
            </Box>

            {/* Texto com a tipografia e peso de fonte definidos no tema */}
            <Typography
                variant="body1"
                fontWeight={theme.typography.h4.fontWeight}  // Usa o peso de fonte do tema
                mt={{ xs: 2, sm: 0 }}
                color={theme.palette.text.primary}  // Usa a cor de texto do tema
            >
                Página {currentPage} de {totalPages}
            </Typography>

            <MuiPagination
                count={totalPages}
                page={currentPage}
                onChange={handlePageChange}
                color="primary"
                showFirstButton
                showLastButton
                size="large"
                siblingCount={1}
                boundaryCount={1}
                sx={{
                    '& .Mui-selected': {
                        backgroundColor: theme.palette.primary.main,  // Cor principal do tema
                        color: theme.palette.primary.contrastText,  // Texto com cor de contraste
                    },
                    '& .MuiPaginationItem-root': {
                        borderRadius: '50%',  // Botões arredondados
                        margin: '0 4px',
                    },
                }}
            />
        </Box>
    );
};

export default Pagination;
