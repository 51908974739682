// components/Modal/ModalFundo.jsx
import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Box, useTheme } from '@mui/material';
import HighStockChart from '../Charts/HighStockChart';
import LoadingIndicator from '../LoadingIndicator'; // Importe o componente de loading

const ModalFundo = ({ modalTitle,open, onClose, infoGerais, estatisticas, graficos = [], loading }) => {
  const theme = useTheme(); // Acessa o tema da aplicação

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="dialog-title"
      fullWidth
      maxWidth="lg"
      sx={{
        '& .MuiDialog-paper': {
          backgroundColor: theme.palette.background.default,
          color: theme.palette.text.primary,
          borderRadius: '8px',
        },
      }}
    >
      <DialogTitle
        id="dialog-title"
        sx={{
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
          fontWeight: 'bold',
          borderBottom: `1px solid ${theme.palette.divider}`,
        }}
      >
        {modalTitle}
      </DialogTitle>
      <DialogContent dividers>
        {loading ? (
          <LoadingIndicator message="Carregando dados do ativo..." />
        ) : (
          <>
            <Box display="flex" flexDirection="row" justifyContent="space-between" mb={2}>
              <Box
                flex={1}
                mr={2}
                p={2}
                border={`1px solid ${theme.palette.divider}`}
                borderRadius="4px"
                bgcolor={theme.palette.background.paper}
              >
                <Typography variant="h6" gutterBottom sx={{ color: theme.palette.text.primary }}>
                  Informações Gerais
                </Typography>
                {infoGerais.map((info, index) => (
                  <Typography key={index} variant="body2" gutterBottom sx={{ color: theme.palette.text.secondary }}>
                    <strong>{info.label}:</strong> {info.value}
                  </Typography>
                ))}
              </Box>
              <Box
                flex={1}
                p={2}
                border={`1px solid ${theme.palette.divider}`}
                borderRadius="4px"
                bgcolor={theme.palette.background.paper}
              >
                <Typography variant="h6" gutterBottom sx={{ color: theme.palette.text.primary }}>
                  Estatísticas Médias
                </Typography>
                {estatisticas.map((stat, index) => (
                  <Typography key={index} variant="body2" gutterBottom sx={{ color: theme.palette.text.secondary }}>
                    <strong>{stat.label}:</strong> {stat.value}
                  </Typography>
                ))}
              </Box>
            </Box>
            <Box mt={4}>
              {graficos.length > 0 ? (
                graficos.map((grafico, index) => (
                  <Box key={index} mb={4}>
                    <HighStockChart
                      title={grafico.title}
                      series={grafico.series}
                      yAxisTitle={grafico.yAxisTitle}
                      suffix={grafico.suffix}
                      height={grafico.height}
                    />
                  </Box>
                ))
              ) : (
                <Typography variant="body2" color="textSecondary">Sem gráficos disponíveis.</Typography>
              )}
            </Box>
          </>
        )}
      </DialogContent>
      <DialogActions
        sx={{
          borderTop: `1px solid ${theme.palette.divider}`,
          backgroundColor: theme.palette.background.default,
        }}
      >
        <Button onClick={onClose} color="primary" variant="contained">
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalFundo;
