// App.js
import React, { Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { ThemeProvider as MUIThemeProvider, createTheme } from '@mui/material/styles';
import { CssBaseline, Box } from '@mui/material';
import { ThemeProvider, useThemeContext } from './context/ThemeContext';
import { AuthProvider, useAuth } from './context/AuthContext';
import Login from './pages/Login';
import AdminLogin from './pages/admin/LoginAdmin';
import UserLayout from './layouts/UserLayout';
import AdminLayout from './layouts/AdminLayout';
import { adminMenuItems } from './routes/adminRoutes';
import { menuItems } from './routes/routes';
import AdminProtectedRoute from './routes/AdminProtectedRoute';
import ProtectedRoute from './routes/ProtectedRoute';



// Definindo os temas para Light e Dark Mode com tipografia Montserrat
const getThemes = (darkMode) => ({
  lightTheme: createTheme({
    palette: {
      mode: 'light',
      primary: {
        main: '#2C91AD',  // Teal Principal
        light: '#54B8D3',  // Teal Light
        dark: '#206A7F',   // Teal Dark
        contrastText: '#FFFFFF',  // Branco
      },
      secondary: {
        main: '#29A152',  // Green Principal
        light: '#58D493',  // Green Light
        dark: '#1D7246',   // Green Dark
      },
      background: {
        default: '#F5F7FA',  // Gray 800
        paper: '#FFFFFF',    // Branco
      },
      text: {
        primary: '#1D1D26',  // Gray 100
        secondary: '#4D5766',  // Gray 300
      },
      auxiliary: {
        negative: '#E51739',    // Negative (erro)
        warning: '#F27A00',     // Warning (alerta)
        informational: '#29A152',  // Informational (informação)
        successful: '#29A152',  // Successful (sucesso)
      },
    },
    typography: {
      fontFamily: 'Montserrat, sans-serif',
      h4: {
        fontWeight: 700,
      },
      body1: {
        fontWeight: 400,
      },
    },
  }),
  darkTheme: createTheme({
    palette: {
      mode: 'dark',
      primary: {
        main: '#4A4A4A',  // Cinza médio para elementos primários
        light: '#707070', // Cinza claro para hover
        dark: '#1E1E1E',  // Cinza escuro para fundo
        contrastText: '#FFFFFF', // Texto branco
      },
      secondary: {
        main: '#03DAC6',  // Verde suave para destacar
        light: '#66FFF9', // Verde mais claro no hover
        dark: '#00A396',  // Verde escuro para botões ou destaques
      },
      background: {
        default: '#121212',  // Fundo preto suave
        paper: '#1F1F1F',    // Fundo cinza escuro para cartões e elementos
      },
      text: {
        primary: '#E0E0E0',  // Branco suave
        secondary: '#B0BEC5', // Cinza claro para texto secundário
      },
      auxiliary: {
        negative: '#E51739',    // Negative (erro)
        warning: '#F27A00',     // Warning (alerta)
        informational: '#2983CC',  // Informational (informação)
        successful: '#29A152',  // Successful (sucesso)
      },
    },
    typography: {
      fontFamily: 'Montserrat, sans-serif',
      h4: {
        fontWeight: 700,
      },
      body1: {
        fontWeight: 400,
      },
    },
  }),
});



const AppContent = () => {
  const { darkMode } = useThemeContext();
  const { isAuthenticated, isAdmin, permissions, isAdminLogin } = useAuth();
  const { lightTheme, darkTheme } = getThemes(darkMode);

  return (
    <MUIThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      <CssBaseline />
      <Router>
        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
          <Suspense fallback={<div>Loading...</div>}>
            <Routes>
              {/* Rotas de Login */}
              <Route path="/login" element={<Login />} />
              <Route path="/admin/login_admin" element={<AdminLogin />} />

              {/* Redirecionamento baseado em autenticação */}
              <Route path="/" element={
                  isAuthenticated ? (isAdmin ? <Navigate to="/admin/home" /> : <Navigate to="/home" />) : <Navigate to="/login" />
                } />


              {/* Rotas de Usuário com Proteção */}
              {isAuthenticated  && permissions.length > 0 && isAdminLogin === false && (

                <Route element={<ProtectedRoute />}>
                  <Route element={<UserLayout />}>

                    {/* Mapeamento das rotas do menu */}
                    {menuItems
                      .filter(item => !item.requiredPermission || permissions.includes(item.requiredPermission))
                      .map(item => (
                        <Route key={item.route} path={item.route} element={item.component} />
                      ))
                    }

                    {/* Mapeamento dos submódulos, garantindo que o usuário tenha também a permissão do módulo pai */}
                    {menuItems.flatMap(item =>
                      (item.submodules || [])
                        .filter(sub =>
                          (!sub.requiredPermission || permissions.includes(sub.requiredPermission)) &&
                          (!item.requiredPermission || permissions.includes(item.requiredPermission))
                        )
                        .map(sub => (
                          <Route key={sub.route} path={sub.route} element={sub.component} />
                        ))
                    )}
                      {/* Rota coringa para redirecionar para /home */}
                      {/* <Route path="*" element={<Navigate to={isAdmin ? "/admin/home" : "/home"} replace />} /> */}
                    {/* Rota coringa para usuário */}
                    <Route path="*" element={<Navigate to="/home" replace />} />

                  </Route>
                </Route>
              )}

              {/* Rotas de Administrador */}
              {isAuthenticated && isAdmin && isAdminLogin === true && (
                <Route element={<AdminLayout />}>
                  {adminMenuItems.map(item => (
                    <Route
                      key={item.route}
                      path={item.route}
                      element={<AdminProtectedRoute>{item.component}</AdminProtectedRoute>}
                    />
                  ))}
                  {adminMenuItems.flatMap(item =>
                    item.submodules?.map(sub => (
                      <Route
                        key={sub.route}
                        path={sub.route}
                        element={<AdminProtectedRoute>{sub.component}</AdminProtectedRoute>}
                      />
                    ))
                  )}
                  {/* Rota coringa para admin */}
                  <Route path="*" element={<Navigate to="/admin/home" replace />} />
                </Route>
                
              )}

              {/* Redirecionamento para usuários não autenticados */}
              {!isAuthenticated && <Route path="*" element={<Navigate to="/login" />} />}
            </Routes>
          </Suspense>
        </Box>
      </Router>
    </MUIThemeProvider>
  );
};

const App = () => (
  <ThemeProvider>
    <AuthProvider>
      <AppContent />
    </AuthProvider>
  </ThemeProvider>
);

export default App;



