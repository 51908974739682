import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { fetchRendaVariavelProventos } from '../../services/rendaVariavel';
import DataTable from '../../components/DataTable';
import { formatDate } from '../../components/utils/formatsUtils';

const RendaVariavelProventos = () => {
  const [cashDividends, setCashDividends] = useState([]);
  const [stockDividends, setStockDividends] = useState([]);
  const [subscriptions, setSubscriptions] = useState([]);
  const [loading, setLoading] = useState(true);

  // Funções de busca de dados para cada tipo de provento, adicionando IDs únicos
  const fetchDataWithFormatting = async (tipo_proventos, setDataCallback) => {
    try {
      const data = await fetchRendaVariavelProventos(tipo_proventos);
      const formattedData = data.map((item) => ({
        ...item,
        id: `${item["TICKER B3"]}_${item.date}`, // ID exclusivo
        date: formatDate(item.date) // Formatação dd/mm/yyyy
      }));
      setDataCallback(formattedData);
    } catch (error) {
      console.error(`Erro ao buscar dados de ${tipo_proventos}:`, error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await Promise.all([
        fetchDataWithFormatting('cash_dividends', setCashDividends),
        fetchDataWithFormatting('stock_dividends', setStockDividends),
        fetchDataWithFormatting('subscriptions', setSubscriptions)
      ]);
      setLoading(false);
    };
    fetchData();
  }, []);

  const commonColumns = [
    { field: 'TICKER B3', headerName: 'Ticker', flex: 1 },
    { field: 'date', headerName: 'Data', flex: 1 },
  ];

  const cashDividendsColumns = [
    ...commonColumns,
    { field: 'cashDividends', headerName: 'Dividendos em Dinheiro', flex: 1 },
  ];

  const stockDividendsColumns = [
    ...commonColumns,
    { field: 'stockDividends', headerName: 'Dividendos em Ações', flex: 1 },
  ];

  const subscriptionsColumns = [
    ...commonColumns,
    { field: 'subscriptions', headerName: 'Subscrições', flex: 1 },
  ];

  return (
    <Box sx={{ padding: '20px' }}>
      <Typography variant="h4" gutterBottom>
        Proventos de Renda Variável
      </Typography>

      <Box mb={4}>
        <Typography variant="h5" gutterBottom>
          Dividendos em Dinheiro
        </Typography>
        {!loading && cashDividends.length === 0 ? (
          <Typography variant="body1" sx={{ color: 'red', fontStyle: 'italic' }}>
            Sem dados para Dividendos em Dinheiro
          </Typography>
        ) : (
          <DataTable
            rows={cashDividends}
            columns={cashDividendsColumns}
            idColumn="id"  // Usando a coluna 'id' gerada como identificador
            loading={loading}
            pageSizeOptions={[10, 25, 50, 100]}
          />
        )}
      </Box>

      <Box mb={4}>
        <Typography variant="h5" gutterBottom>
          Dividendos em Ações
        </Typography>
        {!loading && stockDividends.length === 0 ? (
          <Typography variant="body1" sx={{ color: 'red', fontStyle: 'italic' }}>
            Sem dados para Dividendos em Ações
          </Typography>
        ) : (
          <DataTable
            rows={stockDividends}
            columns={stockDividendsColumns}
            idColumn="id"  // Usando a coluna 'id' gerada como identificador
            loading={loading}
            pageSizeOptions={[10, 25, 50, 100]}
          />
        )}
      </Box>

      <Box mb={4}>
        <Typography variant="h5" gutterBottom>
          Subscrições
        </Typography>
        {!loading && subscriptions.length === 0 ? (
          <Typography variant="body1" sx={{ color: 'red', fontStyle: 'italic' }}>
            Sem dados para Subscrições
          </Typography>
        ) : (
          <DataTable
            rows={subscriptions}
            columns={subscriptionsColumns}
            idColumn="id"  // Usando a coluna 'id' gerada como identificador
            loading={loading}
            pageSizeOptions={[10, 25, 50, 100]}
          />
        )}
      </Box>
    </Box>
  );
};

export default RendaVariavelProventos;
