import React from 'react';
import { Card, CardContent, Typography, IconButton, useTheme } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { applyCNPJFormat } from './utils/formatsUtils';

const FundCard = React.memo(({ cnpj_classe, id_subclasse, classe, subclasse, onRemove }) => {
  const theme = useTheme(); // Hook para acessar o tema atual da aplicação

  return (
    <Card sx={{
      width: 320,
      minHeight: 140,
      boxShadow: `0px 4px 16px ${theme.palette.action.disabledBackground}`, // Sombra usando uma cor do tema
      borderRadius: '16px',
      padding: 2,
      position: 'relative',
      borderLeft: `5px solid ${theme.palette.success.main}`, // Cor da borda usando a cor de sucesso do tema
      transition: 'transform 0.2s ease-in-out',
      backgroundColor: theme.palette.background.paper, // Fundo do card conforme o tema
      '&:hover': { 
        transform: 'scale(1.02)', 
        boxShadow: `0px 8px 24px ${theme.palette.action.hover}`, // Sombra ao passar o mouse
      }
    }}>
      <IconButton onClick={() => onRemove(cnpj_classe, id_subclasse)} size="small" sx={{
        position: 'absolute',
        top: 12,
        right: 12,
        color: theme.palette.error.main, // Cor de erro para o ícone de fechamento
        backgroundColor: theme.palette.background.default, // Fundo do ícone de acordo com o tema
        boxShadow: `0px 2px 6px ${theme.palette.divider}`, // Sombra do botão de fechamento
        '&:hover': { backgroundColor: theme.palette.error.light }, // Fundo ao passar o mouse
      }}>
        <CloseIcon fontSize="small" />
      </IconButton>

      <CardContent sx={{ textAlign: 'center', paddingTop: '20px' }}>
        {/* Nome da Classe */}
        <Typography variant="h6" sx={{ 
          fontWeight: '600', 
          lineHeight: 1.4, 
          fontSize: '0.85rem', 
          color: theme.palette.text.primary // Cor do texto principal conforme o tema
        }}>
          {classe}
        </Typography>

        {/* CNPJ da Classe */}
        <Typography variant="body2" sx={{ 
          marginTop: '6px', 
          fontSize: '0.85rem', 
          color: theme.palette.text.secondary // Cor do texto secundário conforme o tema
        }}>
          <strong>CNPJ:</strong> {applyCNPJFormat(cnpj_classe)}
        </Typography>

        {/* Nome da Subclasse */}
        <Typography variant="body2" sx={{ 
          marginTop: '8px', 
          fontSize: '0.85rem', 
          fontWeight: 'bold',
          color: theme.palette.primary.main // Cor do texto em destaque
        }}>
         <strong>Subclasse:</strong> {subclasse}
        </Typography>

        {/* ID da Subclasse */}
        <Typography variant="body2" sx={{ 
          marginTop: '6px', 
          fontSize: '0.85rem', 
          color: theme.palette.text.secondary // Cor do texto secundário conforme o tema
        }}>
          <strong>ID Subclasse:</strong> {id_subclasse}
        </Typography>
      </CardContent>
    </Card>
  );
});

export default FundCard;
