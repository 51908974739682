import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import ModuleCard from '../../components/ModuleCard';
import { menuItems } from '../../routes/routes';
import { useAuth } from '../../context/AuthContext'; // Importa o contexto de autenticação
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import AssessmentIcon from '@mui/icons-material/Assessment';
import SettingsIcon from '@mui/icons-material/Settings';
import WarningIcon from '@mui/icons-material/Warning';
import SearchIcon from '@mui/icons-material/Search';
import ArticleSharpIcon from '@mui/icons-material/ArticleSharp';

const FundosInvestimentos = () => {
  const theme = useTheme();
  const { permissions } = useAuth(); // Obtém as permissões do usuário

  const fundosModule = menuItems.find(item => item.text === 'Fundos de Investimento');
  let submodules = fundosModule?.submodules || [];

  // Filtra os submódulos com base nas permissões do usuário
  submodules = submodules.filter(sub => 
    !sub.requiredPermission || permissions.includes(sub.requiredPermission)
  );

  const submoduleIcons = {
    'Ranking Fundos': <TrendingUpIcon sx={{ fontSize: 60 }} />,
    'Ranking Previdência': <TrendingUpIcon sx={{ fontSize: 60 }} />,
    'Parâmetros': <SettingsIcon sx={{ fontSize: 60 }} />,
    'Fundos Atenção': <WarningIcon sx={{ fontSize: 60 }} />,
    'Buscar Fundos': <SearchIcon sx={{ fontSize: 60 }} />,
    'Relatório de Risco': <ArticleSharpIcon sx={{ fontSize: 60 }} />,
  };

  // Calcula o número total de colunas com base na quantidade de submódulos
  // A cada 3 submódulos, adicionamos 1 coluna
  const columns = Math.ceil(submodules.length / 3);

  // Função auxiliar para limitar o número de colunas de acordo com o breakpoint
  const getColumnsForBreakpoint = (breakpoint) => {
    if (breakpoint === 'xs') return Math.min(columns, 1); // Máximo de 1 coluna em telas extra pequenas
    if (breakpoint === 'sm') return Math.min(columns, 2); // Máximo de 1 colunas em telas pequenas
    if (breakpoint === 'md') return Math.min(columns, 3); // Máximo de 3 colunas em telas médias
    return columns; // Em telas maiores, usa o valor total calculado
  };

  return (
    <Box sx={{ flexGrow: 1, padding: 2 }}>
      <Typography 
        variant="h4" 
        align="center" 
        gutterBottom 
        sx={{ fontWeight: 'bold', color: theme.palette.text.primary }}
      >
        Fundos de Investimento
      </Typography>

      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: {
            xs: `repeat(${getColumnsForBreakpoint('xs')}, 1fr)`,
            sm: `repeat(${getColumnsForBreakpoint('sm')}, 1fr)`,
            md: `repeat(${getColumnsForBreakpoint('md')}, 1fr)`,
            lg: `repeat(${getColumnsForBreakpoint('lg')}, 1fr)`,
          },
          gap: 2,
          paddingX: { xs: 2, md: 6 },
        }}
      >
        {submodules.map((submodule, index) => (
          <ModuleCard
            key={index}
            title={submodule.text}
            description={`Acesse o módulo ${submodule.text}.`}
            icon={submoduleIcons[submodule.text] || <AssessmentIcon sx={{ fontSize: 60 }} />}
            route={submodule.route}
          />
        ))}
      </Box>
    </Box>
  );
};

export default FundosInvestimentos;
