import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { menuItems, filterMenuByPermissions } from '../routes/routes';
import { useAuth } from '../context/AuthContext';

const NavBar = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { permissions } = useAuth();

  // função de filtragem
  const allowedMenuItems = filterMenuByPermissions(menuItems, permissions);
  
  return (
    <Box sx={{ display: 'flex', gap: 4 }}>
      {allowedMenuItems.map((item, index) => (
        <Typography
          key={index}
          onClick={() => navigate(item.route)}
          sx={{
            cursor: 'pointer',
            padding: '6px 0',
            '&:hover': { color: theme.palette.secondary.light },
            transition: 'color 0.2s',
          }}
        >
          {item.text}
        </Typography>
      ))}
    </Box>
  );
};

export default NavBar;
