import React, { useState, useEffect, useCallback } from 'react';
import { Box, Typography,  CircularProgress, Checkbox, Button} from '@mui/material';
import { fundosStatsCnpjID,obterFundosAprovadosAceitos ,atualizarFundoAprovadoAceito, deletarFundoAprovadoAceito,obterFundosStatsTabelaCNPJ, enviarFundosAprovadosAceitos} from '../../services/fundosInvestimentos';
import { Indicadores } from '../../services/indicadores';
import HighStockChart from '../../components/Charts/HighStockChart';
import CustomSnackbar from '../../components/CustomSnackbar';
import { calcularRentabilidadeAcumuladaParaFundos, criarChartData } from '../../components/utils/chartUtils';
import { calcularRentabilidadeAcumuladaIndicadores, calcularRentabilidadeMovel } from '../../components/utils/indicadoresUtils';
import SearchBoxFundos from '../../components/SearchBoxFundos';
import FundCard from '../../components/FundCard';
import DataTable from '../../components/DataTable';
import EditButton from "../../components/ActionButton/EditButton"
import RemoveButton from "../../components/ActionButton/RemoveButton"
import { formatCNPJ } from '../../components/utils/formatsUtils';
import CustomDialog from '../../components/Modal/CustomDialog';

const BuscaFundos = () => {
  const [selectedFunds, setSelectedFunds] = useState([]);
  const [fundStats, setFundStats] = useState({});
  const [rawIndicadores, setRawIndicadores] = useState([]);
  const [chartData, setChartData] = useState({
    finalChartData: [],
    movelChartData: [],
    volatilityChartData: [],
    sharpeChartData: [],
    betaChartData: [],
    alfaJensenChartData: [],
    correlacaoChartData: [],
    drawdownChartData: [],
  });
  const [tableData, setTableData] = useState([]);
  const [approvedFunds, setApprovedFunds] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [removeDialogOpen, setRemoveDialogOpen] = useState(false);

  // Estado unificado para o fundo selecionado no diálogo
  const [currentDialogFund, setCurrentDialogFund] = useState({
    cnpj_classe: '',
    id_subclasse: '',
    classe: '',
    subclasse: '',
    aprovado: true,
    aceito: false,
  });


  useEffect(() => {
    const fetchIndicadores = async () => {
      try {
        const dadosIndicadores = await Indicadores();
        setRawIndicadores(dadosIndicadores);
      } catch (error) {
        console.error("Erro ao carregar indicadores:", error);
      }
    };
    const fetchApprovedFunds = async () => {
      setIsLoading(true);
      try {
        const data = await obterFundosAprovadosAceitos();
        setApprovedFunds(data);

      } catch (error) {
        console.error('Erro ao carregar fundos aprovados e aceitos:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchIndicadores();
    fetchApprovedFunds();
  }, []);

  const onSelectFund = useCallback(async (cnpj_classe, classe, id_subclasse, subclasse) => {
    const cnpjFormatado = cnpj_classe.replace(/[./-]/g, '');

    if (selectedFunds.some(fund => fund.cnpj_classe === cnpjFormatado && fund.id_subclasse === id_subclasse)) {
      setSnackbarOpen(true);
      return;
    }

    setIsLoading(true);

    try {
      const stats = await fundosStatsCnpjID(cnpjFormatado, id_subclasse);
      const tabelaStats = await obterFundosStatsTabelaCNPJ(cnpjFormatado, id_subclasse);
  
      const rentabilidadeLogData = stats.map(item => ({
        date: item.date,
        rentln: item.RENTLN,
      }));
  
      const volatilityData = criarChartData('DP(AT)%', stats);
      const sharpeData = criarChartData('SHARPE', stats);
      const betaData = criarChartData('BETA', stats);
      const alfaJensenData = criarChartData('alfa_jensen', stats);
      const correlacaoData = [
        { name: `Corr IBOV - ${classe}`, data: criarChartData('CORR(AT,IBOV)', stats) },
        { name: `Corr IMAB-GERAL - ${classe}`, data: criarChartData('CORR(AT,IMABG)', stats) },
      ];
      const drawdownData = criarChartData('DRAWDOWN', stats);
      const rentMovelData = criarChartData('RENTMED_AT%', stats);
  
      setFundStats((previousStats) => ({
        ...previousStats,
        [`${cnpjFormatado}-${id_subclasse}`]: {
          classe,
          id_subclasse,
          subclasse,
          rentabilidadeLogData,
          volatilityData,
          sharpeData,
          betaData,
          alfaJensenData,
          correlacaoData,
          drawdownData,
          rentMovelData,
        },
      }));
  
      setSelectedFunds(prevFunds => [...prevFunds, { cnpj_classe: cnpjFormatado, classe, id_subclasse, subclasse }]);
      setChartData((previousChartData) => ({
        ...previousChartData,
        volatilityChartData: [...previousChartData.volatilityChartData, { name: classe, data: volatilityData }],
        sharpeChartData: [...previousChartData.sharpeChartData, { name: classe, data: sharpeData }],
        betaChartData: [...previousChartData.betaChartData, { name: classe, data: betaData }],
        alfaJensenChartData: [...previousChartData.alfaJensenChartData, { name: classe, data: alfaJensenData }],
        correlacaoChartData: [...previousChartData.correlacaoChartData, ...correlacaoData],
        drawdownChartData: [...previousChartData.drawdownChartData, { name: classe, data: drawdownData }],
        movelChartData: [...previousChartData.movelChartData, { name: classe, data: rentMovelData }],
      }));

      setTableData((prevTableData) => [
        ...prevTableData, 
        { 
          CNPJ_Classe: cnpjFormatado, 
          Denominacao_Mont: classe, 
          ID_Subclasse: id_subclasse,
          Subclasse: subclasse,
          Aprovados: false, 
          Aceitos: false, 
          ...tabelaStats[0] 
        }
      ]);

    } catch (error) {
      console.error("Erro ao buscar dados do fundo:", error);
    } finally {
      setIsLoading(false);
    }
  }, [selectedFunds]);

  const removeFund = useCallback((cnpj_classe, id_subclasse) => {
    setSelectedFunds(prevFunds =>
        prevFunds.filter(fund => !(fund.cnpj_classe === cnpj_classe && fund.id_subclasse === id_subclasse))
    );

    setFundStats(prevStats => {
        const newStats = { ...prevStats };
        delete newStats[`${cnpj_classe}-${id_subclasse}`];
        return newStats;
    });

    // Remover o fundo da tabela de estatísticas corretamente
    setTableData(prevTableData => 
      prevTableData.filter(item => !(formatCNPJ(item.CNPJ_Classe) === cnpj_classe && item.ID_Subclasse === id_subclasse))
      
    );

    setChartData(prevChartData => ({
        ...prevChartData,
        volatilityChartData: prevChartData.volatilityChartData.filter(data => data.name !== fundStats[`${cnpj_classe}-${id_subclasse}`]?.classe),
        sharpeChartData: prevChartData.sharpeChartData.filter(data => data.name !== fundStats[`${cnpj_classe}-${id_subclasse}`]?.classe),
        betaChartData: prevChartData.betaChartData.filter(data => data.name !== fundStats[`${cnpj_classe}-${id_subclasse}`]?.classe),
        alfaJensenChartData: prevChartData.alfaJensenChartData.filter(data => data.name !== fundStats[`${cnpj_classe}-${id_subclasse}`]?.classe),
        correlacaoChartData: prevChartData.correlacaoChartData.filter(data => data.name !== fundStats[`${cnpj_classe}-${id_subclasse}`]?.classe && !data.name.includes('Corr')),
        drawdownChartData: prevChartData.drawdownChartData.filter(data => data.name !== fundStats[`${cnpj_classe}-${id_subclasse}`]?.classe),
        movelChartData: prevChartData.movelChartData.filter(data => data.name !== fundStats[`${cnpj_classe}-${id_subclasse}`]?.classe),
    }));

  }, [fundStats]);

  const recalcularDados = useCallback(() => {
    if (selectedFunds.length === 0 || rawIndicadores.length === 0) {
      setChartData((previousChartData) => ({
        ...previousChartData,
        finalChartData: [],
        movelChartData: [],
      }));
      return;
    }
  
    const timestamps = selectedFunds.map(fund => {
      const dataFund = fundStats[`${fund.cnpj_classe}-${fund.id_subclasse}`]?.rentabilidadeLogData;
      return dataFund ? {
        start: new Date(dataFund[0].date).getTime(),
        end: new Date(dataFund[dataFund.length - 1].date).getTime()
      } : { start: Infinity, end: -Infinity };
    });
  
    const minTimestamp = Math.max(...timestamps.map(ts => ts.start));
    const maxFundTimestamp = Math.max(...timestamps.map(ts => ts.end));
    const earliestFundStart = Math.min(...timestamps.map(ts => ts.start));
  
    const indicadoresAjustados = {
      CDI: calcularRentabilidadeAcumuladaIndicadores(rawIndicadores, 'rentln_cdi', minTimestamp, maxFundTimestamp),
      IBOV: calcularRentabilidadeAcumuladaIndicadores(rawIndicadores, 'rentln_IBOV', minTimestamp, maxFundTimestamp),
      IMAB: calcularRentabilidadeAcumuladaIndicadores(rawIndicadores, 'rentln_imab', minTimestamp, maxFundTimestamp),
    };
  
    const indicadoresMovel = {
      CDI: calcularRentabilidadeMovel(rawIndicadores.filter(item => {
        const itemDate = new Date(item.date).getTime();
        return itemDate >= earliestFundStart && itemDate <= maxFundTimestamp;
      }), 'rentln_cdi', 126),
      IBOV: calcularRentabilidadeMovel(rawIndicadores.filter(item => {
        const itemDate = new Date(item.date).getTime();
        return itemDate >= earliestFundStart && itemDate <= maxFundTimestamp;
      }), 'rentln_IBOV', 126),
      IMAB: calcularRentabilidadeMovel(rawIndicadores.filter(item => {
        const itemDate = new Date(item.date).getTime();
        return itemDate >= earliestFundStart && itemDate <= maxFundTimestamp;
      }), 'rentln_imab', 126),
    };
  
    const fundosAjustados = selectedFunds.map((fund) => {
      const cnpj_classe = fund.cnpj_classe;
      const rentabilidadeLogData = fundStats[`${cnpj_classe}-${fund.id_subclasse}`]?.rentabilidadeLogData
        .filter(item => {
          const itemTimestamp = new Date(item.date).getTime();
          return itemTimestamp >= minTimestamp && itemTimestamp <= maxFundTimestamp;
        });
  
      const rentabilidadeAcumuladaData = calcularRentabilidadeAcumuladaParaFundos(rentabilidadeLogData, 'rentln');
  
      if (rentabilidadeAcumuladaData.length > 0) {
        rentabilidadeAcumuladaData[0][1] = 0;
      }
  
      return {
        name: fund.classe,
        data: rentabilidadeAcumuladaData
      };
    });
  
    const fundosMovelData = selectedFunds.map(fund => ({
      name: fund.classe,
      data: fundStats[`${fund.cnpj_classe}-${fund.id_subclasse}`]?.rentMovelData || []
    }));
  
    setChartData((previousChartData) => ({
      ...previousChartData,
      finalChartData: [
        ...fundosAjustados,
        { name: 'CDI', data: indicadoresAjustados.CDI },
        { name: 'IBOV', data: indicadoresAjustados.IBOV },
        { name: 'IMAB', data: indicadoresAjustados.IMAB },
      ],
      movelChartData: [
        ...fundosMovelData,
        { name: 'CDI', data: indicadoresMovel.CDI },
        { name: 'IBOV', data: indicadoresMovel.IBOV },
        { name: 'IMAB', data: indicadoresMovel.IMAB },
      ],
    }));
  }, [selectedFunds, rawIndicadores, fundStats]);
  
  useEffect(() => {
    recalcularDados();
  }, [recalcularDados]);

  //------------------------------ Logica Tabela Estatisticaa Fundos -----------------------------------------//
  const defaultColumns = [
    { field: "CNPJ_Classe", headerName: "CNPJ da Classe", width: 200, description: "CNPJ da Classe do Fundo" },
    { field: "ID_Subclasse", headerName: "ID Subclasse", width: 150, description: "ID da Subclasse do Fundo" },
    { field: "RENTMED_AT%", headerName: "Rent. Média %", width: 150, description: "Rentabilidade Média Anual média do Fundo (%)" },
    { field: "RENT_%DI+", headerName: "Rent. %DI+", width: 150, description: "Rentabilidade (Spread Anual) média do Fundo em relação ao CDI (%)" },
    { field: "RENT_%IBOV+", headerName: "Rent. %IBOV+", width: 150, description: "Rentabilidade (Spread Anual) média do Fundo em relação ao IBOV (%)" },
    { field: "RENT_%IMABG+", headerName: "Rent. %IMABG+", width: 150, description: "Rentabilidade (Spread Anual) média do Fundo em relação ao IMAB Geral (%)" },
    { field: "DP(AT)%", headerName: "DP(AT)%", width: 150, description: "Média do desvio padrão anualizado do Fundo (%)" },
    { field: "CORR(AT,IBOV)", headerName: "Correlação (AT, IBOV)", width: 150, description: "Média da correlação do Fundo em relação ao Ibovespa (d.u.)" },
    { field: "BETA", headerName: "Beta", width: 150, description: "Média do Beta do Fundo (d.u.)" },
    { field: "SHARPE", headerName: "Sharpe", width: 150, description: "Média do Sharpe do Fundo" },
    { field: "alfa_jensen", headerName: "Alfa Jensen", width: 150, description: "Média do Alfa de Jensen Anual" },
    { field: "Risco_Fundo", headerName: "Risco do Fundo", width: 150, description: "Risco do Fundo" },
    { field: "MÍNIMO_SHARPE_ACEITAVEL%", headerName: "Mín. Sharpe Aceitável %", width: 180, description: "Porcentagem mínima para o Sharpe ser aceitável" },
    { field: "PORCENT_MAIOR_X_SHARPE", headerName: "Porcent. Maior x Sharpe", width: 180, description: "Porcentagem que o Fundo é maior que a referência estabelecida para Sharpe" },
    { field: "MÍNIMO_ALFA_JENSEN_ACEITAVEL%", headerName: "Mín. Alfa Jensen Aceitável %", width: 200, description: "Porcentagem mínima para o Alfa de Jensen ser aceitável" },
    { field: "PORCENT_MAIOR_X_ALFA_JENSEN", headerName: "Porcent. Maior x Alfa Jensen", width: 200, description: "Porcentagem que o Fundo é maior que a referência estabelecida para Alfa de Jensen" },
    { field: "Denominacao_Mont", headerName: "Nome do Fundo", width: 300, description: "Nomenclatura Mont do Fundo de Investimento" },
    { field: "Publico_Alvo", headerName: "Público-Alvo", width: 150, description: "Público Alvo do Fundo" },
    { field: "Classe_Cod_Mont", headerName: "Classe Código Mont", width: 150, description: "Código da Classe para Mont" },
    { field: "Classe_Mont", headerName: "Classe Mont", width: 150, description: "Substituições de nomenclatura para padrões Mont" },
    { field: "Tipo_Ativo(Gestora)", headerName: "Tipo Ativo (Gestora)", width: 200, description: "Tipo de Ativo" },
    { field: "CORRETORA(S/N)", headerName: "Corretora (S/N)", width: 150, description: "Está em alguma corretora parceira (Sim:true; Não:false)" , type: "boolean"},
    { field: "CORRETORAS(NOMES)", headerName: "Corretoras (Nomes)", width: 200, description: "Siglas das corretoras parceiras que o fundo está presente" },
    { field: "Indicador_Desempenho", headerName: "Rentabilidade do Fundo", width: 200, description: "Rentabilidade do Fundo" },
    { field: "Classe_Cotas", headerName: "Classe de Cotas", width: 150, description: "Fundo de Cotas (Sim:true; Não:false)", type: 'boolean'  },
    { field: "Tributacao_Longo_Prazo", headerName: "Tributação Longo Prazo", width: 200, description: "Tributação de Longo Prazo" , type: 'boolean' },
    { field: "Administrador", headerName: "Administrador", width: 200, description: "Nome da Administradora do Fundo" },
    { field: "Gestor", headerName: "Gestor", width: 200, description: "CPF/CNPJ da Empresa Gestora do Fundo" },
  ];

  const handleCheckboxChange = (cnpj_classe, id_subclasse, field) => {
    setTableData((prevData) => 
      prevData.map((item) => {
        if (item.CNPJ_Classe === cnpj_classe && item.ID_Subclasse === id_subclasse) {
          return {
            ...item,
            [field]: !item[field], // Alterna entre true e false
            [field === 'Aprovados' ? 'Aceitos' : 'Aprovados']: false // Desmarca o outro campo
          };
        }
        return item;
      })
    );
  };
  
  const enviarFundos = async () => {
    // Filtra os fundos que têm 'Aprovados' ou 'Aceitos' como true e que não estão em `approvedFunds`
    const fundosParaEnviar = tableData.filter(item =>
      (item.Aprovados || item.Aceitos) &&
      !approvedFunds.some(approved => approved.CNPJ_Classe === item.CNPJ_Classe && approved.ID_Subclasse === item.ID_Subclasse)
    );
  
    // Identifica fundos duplicados
    const fundosDuplicados = tableData.filter(item =>
      (item.Aprovados || item.Aceitos) &&
      approvedFunds.some(approved => approved.CNPJ_Classe === item.CNPJ_Classe && approved.ID_Subclasse === item.ID_Subclasse)
    ).map(item => `${item.CNPJ_Classe}-${item.ID_Subclasse}`); // Mantém CNPJ + ID Subclasse para evitar conflitos
  
    // Se não houver fundos para enviar
    if (fundosParaEnviar.length === 0) {
      if (fundosDuplicados.length > 0) {
        alert(`Os seguintes fundos já estão aprovados/aceitos e não foram reenviados: ${fundosDuplicados.join(', ')}`);
      } else {
        alert("Nenhum fundo novo com 'Aprovados' ou 'Aceitos' selecionado para envio.");
      }
      return;
    }
  
    try {
      // Envia fundos para o endpoint
      await enviarFundosAprovadosAceitos(fundosParaEnviar);
      alert("Fundos enviados com sucesso!");
  
      // Atualiza a tabela `approvedFunds`
      setApprovedFunds(prevApprovedFunds => [...prevApprovedFunds, ...fundosParaEnviar]);
  
      // Limpa as seleções na tabela de estatísticas
      setTableData(prevTableData =>
        prevTableData.map(item => ({
          ...item,
          Aprovados: false,
          Aceitos: false,
        }))
      );
  
      // Alerta sobre fundos duplicados, se houver
      if (fundosDuplicados.length > 0) {
        alert(`Os seguintes fundos já estavam aprovados/aceitos e não foram reenviados: ${fundosDuplicados.join(', ')}`);
      }
    } catch (error) {
      console.error("Erro ao enviar fundos:", error);
    }
  };
  
  const statisticColumns = [
    {
      field: 'Aprovados',
      headerName: 'Aprovados',
      width: 100,
      renderCell: (params) => (
        <Checkbox
          checked={params.row.Aprovados}
          onChange={() => handleCheckboxChange(params.row.CNPJ_Classe, params.row.ID_Subclasse, 'Aprovados')}
        />
      ),
    },
    {
      field: 'Aceitos',
      headerName: 'Aceitos',
      width: 100,
      renderCell: (params) => (
        <Checkbox
          checked={params.row.Aceitos}
          onChange={() => handleCheckboxChange(params.row.CNPJ_Classe, params.row.ID_Subclasse, 'Aceitos')}
        />
      ),
    },
    ...defaultColumns,
  ];

  //------------------------------ (Fim) Logica Tabela Estatistica Fundos ------------------------------------//

  // ------------------------------ Logica Tabela Aprovados e Aceitos -----------------------------------------//
  // Função para abrir o diálogo de edição e definir o fundo selecionado 
  // classe --> denominacao mont/ denominacao da classe
  const handleEdit = (cnpj_classe, id_subclasse, classe, aprovado, aceito) => {
    setCurrentDialogFund({ cnpj_classe, id_subclasse, classe, aprovado, aceito });
    setEditDialogOpen(true);
  };

  // Função para abrir o diálogo de remoção e definir o fundo selecionado
  const handleRemove = (cnpj_classe, id_subclasse, classe, subclasse) => {
    setCurrentDialogFund({ cnpj_classe, id_subclasse, classe, subclasse });
    setRemoveDialogOpen(true);
  };

  const confirmEdit = async () => {
    if (currentDialogFund.aprovado === currentDialogFund.aceito) {
      alert("Um fundo deve ser aprovado ou aceito, mas não ambos ou nenhum.");
      return;
    }

    const cnpj_formatado = formatCNPJ(currentDialogFund.cnpj_classe);

    try {
      const updateData = { Aprovados: currentDialogFund.aprovado, Aceitos: currentDialogFund.aceito };
      await atualizarFundoAprovadoAceito(cnpj_formatado, currentDialogFund.id_subclasse, updateData);

      const updatedFunds = await obterFundosAprovadosAceitos();
      setApprovedFunds(updatedFunds);
      setEditDialogOpen(false);
    } catch (error) {
      console.error('Erro ao atualizar fundo:', error);
    }
  };

  const handleRemoveConfirm = async () => {
    const cnpj_formatado = formatCNPJ(currentDialogFund.cnpj_classe);
    try {
      await deletarFundoAprovadoAceito(cnpj_formatado, currentDialogFund.id_subclasse);
      const updatedFunds = await obterFundosAprovadosAceitos();
      setApprovedFunds(updatedFunds);
      setRemoveDialogOpen(false);
    } catch (error) {
      console.error('Erro ao remover fundo:', error);
    }
  };

  const handleOptionChange = (e) => {
    const selected = e.target.value === 'Aprovado';
    setCurrentDialogFund((prevFund) => ({
      ...prevFund,
      aprovado: selected,
      aceito: !selected,
    }));
  };

  const approvedColumns = [
    {
      field: 'Ações',
      headerName: 'Ações',
      width: 150,
      renderCell: (params) => (
        <Box>
          <EditButton 
            onEdit={() => handleEdit(
              params.row.CNPJ_Classe, 
              params.row.ID_Subclasse, 
              params.row.Denominacao_Mont,
              params.row.Aprovados, 
              params.row.Aceitos
            )} 
          />
          <RemoveButton 
            onRemove={() => handleRemove(
              params.row.CNPJ_Classe, 
              params.row.ID_Subclasse, 
              params.row.Denominacao_Mont
            )} 
          />
        </Box>
      ),
    },
    { field: 'Aprovados', headerName: 'Aprovados', width: 100 },
    { field: 'Aceitos', headerName: 'Aceitos', width: 100 },
    ...defaultColumns,
  ];
  
  // ------------------------------ (Fim) Logica Tabela Aprovados e Aceitos -----------------------------------------//

  return (
    <Box sx={{ padding: '20px' }}>
      <Typography variant="h4" gutterBottom>Procurar Fundos</Typography>
      
      {/* Componente SearchBox para buscar fundos sem impactar o desempenho dos gráficos */}
      <SearchBoxFundos onSelectFund={onSelectFund} />

      {/* Renderiza um FundCard para cada fundo selecionado */}
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, mt: 2 }}>
        {selectedFunds.map((fund) => (
          <FundCard
            key={`${fund.cnpj_classe}-${fund.id_subclasse}`}
            cnpj_classe={fund.cnpj_classe}
            id_subclasse={fund.id_subclasse}
            classe={fund.classe}
            subclasse={fund.subclasse}
            onRemove={removeFund} // Passa a função para remover o fundo
          />
        ))}
      </Box>

      {isLoading && <CircularProgress size={24} sx={{ marginTop: '20px', alignSelf: 'center' }} />}

      {/* Renderização dos gráficos conforme o código original */}
      {chartData.finalChartData.length > 0 && (
        <Box sx={{ marginTop: '20px' }}>
          <HighStockChart
            title="Rentabilidade Acumulada"
            series={chartData.finalChartData}
            yAxisTitle="Rentabilidade (%)"
            suffix="%"
            height="45%"
          />
        </Box>
      )}

      {chartData.movelChartData.length > 0 && (
        <Box sx={{ marginTop: '20px' }}>
          <HighStockChart
            title="Rentabilidade Média Móvel"
            series={chartData.movelChartData}
            yAxisTitle="Rentabilidade (%)"
            suffix="%"
            height="45%"
          />
        </Box>
      )}
      {chartData.volatilityChartData.length > 0 && (
        <Box sx={{ marginTop: '20px' }}>
          <HighStockChart
            title="Volatilidade"
            series={chartData.volatilityChartData}
            yAxisTitle="Volatilidade (%)"
            suffix="%"
            height="45%"
          />
        </Box>
      )}

      {chartData.sharpeChartData.length > 0 && (
        <Box sx={{ marginTop: '20px' }}>
          <HighStockChart
            title="Sharpe"
            series={chartData.sharpeChartData}
            yAxisTitle="Sharpe"
            height="45%"
          />
        </Box>
      )}

      {chartData.betaChartData.length > 0 && (
        <Box sx={{ marginTop: '20px' }}>
          <HighStockChart
            title="Beta"
            series={chartData.betaChartData}
            yAxisTitle="Beta"
            height="45%"
          />
        </Box>
      )}

      {chartData.alfaJensenChartData.length > 0 && (
        <Box sx={{ marginTop: '20px' }}>
          <HighStockChart
            title="Alfa de Jensen"
            series={chartData.alfaJensenChartData}
            yAxisTitle="Alfa"
            height="45%"
          />
        </Box>
      )}

      {chartData.correlacaoChartData.length > 0 && (
        <Box sx={{ marginTop: '20px' }}>
          <HighStockChart
            title="Correlação"
            series={chartData.correlacaoChartData}
            yAxisTitle="Correlação"
            height="45%"
          />
        </Box>
      )}

      {chartData.drawdownChartData.length > 0 && (
        <Box sx={{ marginTop: '20px' }}>
          <HighStockChart
            title="Drawdown"
            series={chartData.drawdownChartData}
            yAxisTitle="Drawdown (%)"
            suffix="%"
            height="45%"
          />
        </Box>
      )}


      {/* Tabela Estatística dos Fundos */}
      {tableData.length > 0 && (
        <Box sx={{ marginTop: '20px' }}>
          <Typography variant="h5" gutterBottom>Estatísticas dos Fundos Selecionados</Typography>

          <DataTable 
            columns={statisticColumns} 
            rows={tableData.map(fund => ({
              ...fund,
              id: `${fund.CNPJ_Classe}-${fund.ID_Subclasse}` // Criando a chave composta
            }))} 
            idColumn="id" 
            loading={isLoading} 
          />

          <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
            <Button variant="contained" color="primary" onClick={enviarFundos}>
              Enviar Fundos Aprovados/Aceitos
            </Button>
          </Box>
        </Box>
      )}

      {/* Tabela dos Fundos Aprovados e Aceitos */}
      <Box sx={{ marginTop: 4 }}>
        <Typography variant="h5" gutterBottom>Fundos de Investimentos Aprovados e Aceitos</Typography>

        <DataTable 
          columns={approvedColumns} 
          rows={approvedFunds.map(fund => ({
            ...fund,
            id: `${fund.CNPJ_Classe}-${fund.ID_Subclasse}` // Chave composta
          }))} 
          idColumn="id" 
          loading={isLoading} 
        />
      </Box>

      {/* Diálogo para edição */}      
      <CustomDialog
        open={editDialogOpen}
        onClose={() => setEditDialogOpen(false)}
        onConfirm={confirmEdit}
        title={
          <Box>
            <Typography variant="h6" component="h2">
              Editar Status do Fundo
            </Typography>
            <Typography variant="subtitle1" color="textSecondary">
              <strong>Nome:</strong> {currentDialogFund.classe}
            </Typography>
            <Typography variant="subtitle2" color="textSecondary">
              <strong>CNPJ Classe:</strong> {currentDialogFund.cnpj_classe}
            </Typography>
            <Typography variant="subtitle2" color="textSecondary">
              <strong>ID Subclasse:</strong> {currentDialogFund.id_subclasse}
            </Typography>
          </Box>
        }
        confirmButtonText="Salvar"
        cancelButtonText="Cancelar"
        fields={[
          {
            name: "status",
            label: "Status do Fundo",
            type: "radio",
            value: currentDialogFund.aprovado ? "Aprovado" : "Aceito",
            options: [
              { label: "Aprovado", value: "Aprovado" },
              { label: "Aceito", value: "Aceito" }
            ]
          }
        ]}
        onFieldChange={(name, value) => handleOptionChange({ target: { value } })}
        sx={{
          "& .MuiDialogActions-root": {
            padding: "16px",
            justifyContent: "space-between"
          },
          "& .MuiButton-root": {
            borderRadius: "4px",
            padding: "8px 16px",
            boxShadow: "none"
          }
        }}
      />

      {/* Diálogo para confirmação de remoção */}
      <CustomDialog
          open={removeDialogOpen}
          onClose={() => setRemoveDialogOpen(false)}
          onConfirm={handleRemoveConfirm}
          title="Confirmar Remoção"
          description={
            <span>
                Tem certeza de que deseja remover o fundo <strong>{currentDialogFund.classe}</strong>?
                <br />
                CNPJ Classe: <strong>{currentDialogFund.cnpj_classe}</strong>
                <br />
                ID Subclasse: <strong>{currentDialogFund.id_subclasse}</strong>
                <br />
                <span style={{ fontSize: '0.8em', color: 'gray', fontStyle: 'italic' }}>
                    Essa ação é irreversível e todos os dados associados serão permanentemente excluídos.
                </span>
            </span>
          }
          confirmButtonText="REMOVER"
          cancelButtonText="CANCELAR"

      />
   
      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message="Este fundo já foi selecionado."
        severity="warning"
        title="Aviso"
      />
    </Box>
  );
};

export default BuscaFundos;
