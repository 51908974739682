// routes.js
import React from 'react';
import Home from '../pages/Home';
import Planejadores from '../pages/Planejadores';
import RelatorioPeriodico from '../pages/RelatorioPeriodico';

// Fundos de Investimento
import FundosInvestimentos from '../pages/fundosInvestimentos/FundosInvestimentos';
import RankingFund from '../pages/fundosInvestimentos/RankingFund';
import RankingPrev from '../pages/fundosInvestimentos/RankingPrev';
import ParametrosFundo from '../pages/fundosInvestimentos/ParametrosFundo';
import FundosAtencao from '../pages/fundosInvestimentos/FundosAtencao';
import BuscaFundos from '../pages/fundosInvestimentos/BuscaFundos';
import FundoRelatorioRisco from '../pages/fundosInvestimentos/FundoRelatorioRisco';

// Renda Variável
import ClassificacaoRendaVariavelIndex from '../pages/rendaVariavel/ClassificacaoRendaVariavelIndex';
import ConfiguracoesRendaVaraiavelIndex from '../pages/rendaVariavel/Configuracoes/ConfiguracoesRendaVariavelIndex';
import RendaVariavel from '../pages/rendaVariavel/RendaVariavel';
import RendaVariavelStock from '../pages/rendaVariavel/RendaVariavelStock';
import RendaVariavelETF from '../pages/rendaVariavel/RendaVariavelETF';
import RendaVariavelFundos from '../pages/rendaVariavel/RendaVariavelFundos';
import RendaVariavelProventos from '../pages/rendaVariavel/RendaVariavelProventos';


// Crédito Privado
import CreditoPrivado from '../pages/creditoPrivado/CreditoPrivado';
import CreditoPrivadoRanking from '../pages/creditoPrivado/RankingCreditoPrivado';
import ConfiguracoesCreditoPrivado from '../pages/creditoPrivado/ConfiguracoesCreditoPrivado';

export const menuItems = [
  { 
    text: 'Home', 
    route: '/home',
    component: <Home />,
  },
  { 
    text: 'Planejadores', 
    route: '/planejadores',
    component: <Planejadores />,
    requiredPermission: 'dashboard:planejadores',
  },
  {
    text: "Report",
    route: "/report",
    component: <RelatorioPeriodico />,
    requiredPermission: 'dashboard:report',
  },
  { 
    text: 'Fundos de Investimento', 
    route: '/fundos-investimentos',
    component: <FundosInvestimentos />,
    requiredPermission: 'dashboard:fundos_investimento',
    submodules: [
      { text: 'Ranking Fundos', route: '/fundos-investimentos/ranking_fund', component: <RankingFund />, requiredPermission: 'dashboard:fundos_investimento:ranking_fund' },
      { text: 'Ranking Previdência', route: '/fundos-investimentos/ranking_prev', component: <RankingPrev />, requiredPermission: 'dashboard:fundos_investimento:ranking_prev' },
      { text: 'Parâmetros', route: '/fundos-investimentos/parametros', component: <ParametrosFundo />, requiredPermission: 'dashboard:fundos_investimento:parametros' },
      { text: 'Fundos Atenção', route: '/fundos-investimentos/fundos_atencao', component: <FundosAtencao />, requiredPermission: 'dashboard:fundos_investimento:fundos_atencao' },
      { text: 'Buscar Fundos', route: '/fundos-investimentos/busca_fundos', component: <BuscaFundos />, requiredPermission: 'dashboard:fundos_investimento:buscar_fundos' },
      { text: 'Relatório de Risco', route: '/fundos-investimentos/relatorio_risco', component: <FundoRelatorioRisco />, requiredPermission: 'dashboard:fundos_investimento:relatorio_risco' },
    ]
  },
  {
    text: 'Renda Variável',
    route: '/renda-variavel',
    component: <RendaVariavel />,
    requiredPermission: 'dashboard:renda_variavel',
    submodules: [
      { text: "Classificação", route:'/renda-variavel/classificacao', component:<ClassificacaoRendaVariavelIndex />, requiredPermission: 'dashboard:renda_variavel:classificacao' },
      { text: "Configurações", route:'/renda-variavel/configuracoes', component: <ConfiguracoesRendaVaraiavelIndex/>, requiredPermission: 'dashboard:renda_variavel:configuracoes' },
      { text: "Fundos na Bolsa", route:'/renda-variavel/fundos_bolsa', component: <RendaVariavelFundos />, requiredPermission: 'dashboard:renda_variavel:fundos_bolsa' },
      { text: "ETF", route:'/renda-variavel/etf', component: <RendaVariavelETF />, requiredPermission: 'dashboard:renda_variavel:etf' },
      { text: "Ações e BDRs", route:'/renda-variavel/acoes_bdrs', component: <RendaVariavelStock />, requiredPermission: 'dashboard:renda_variavel:stock' },
      { text: "Proventos", route:'/renda-variavel/proventos', component: <RendaVariavelProventos />, requiredPermission: 'dashboard:renda_variavel:proventos' },
    ]},
  {
    text: 'Crédito Privado',
    route: '/credito_privado',
    component: <CreditoPrivado />,
    requiredPermission: 'dashboard:credito_privado',
    submodules: [
      { text: 'Ranking', route: '/credito_privado/ranking', component: <CreditoPrivadoRanking />, requiredPermission: 'dashboard:credito_privado:ranking' },
      { text: "Configurações", route: '/credito_privado/configuracoes', component: <ConfiguracoesCreditoPrivado />, requiredPermission: 'dashboard:credito_privado:configuracoes' },
    ]
  },
];

// Função para filtrar o menu baseado nas permissões do usuário
export const filterMenuByPermissions = (menuItems, userPermissions) => {
  return menuItems
    .map(module => {
      // Se o módulo possuir requiredPermission e o usuário não tiver, descarta o módulo
      if (module.requiredPermission && !userPermissions.includes(module.requiredPermission)) {
        return null;
      }

      // Filtra os submódulos: o usuário deve ter tanto a permissão do submódulo quanto a do módulo pai (se aplicável)
      const allowedSubmodules = module.submodules
        ? module.submodules.filter(sub =>
            (!sub.requiredPermission || userPermissions.includes(sub.requiredPermission)) &&
            (!module.requiredPermission || userPermissions.includes(module.requiredPermission))
          )
        : [];

      return { ...module, submodules: allowedSubmodules };
    })
    .filter(Boolean);
};
