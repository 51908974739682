import React, { useEffect, useState } from 'react';
import { fetchUsers, createUsers, updateUser, importUsers } from '../../services/auth';
import DataTable from '../../components/DataTable';
import { Box, Typography, Button, List, ListItem, Stack, IconButton, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CustomFormDialog from '../../components/Modal/CustomFormDialog'; // Novo componente para formulários
import CustomDialog from '../../components/Modal/CustomDialog'; // Mantém para confirmação
import CustomDialogV2 from '../../components/Modal/CustomDialogV2';
import CustomSnackbar from '../../components/CustomSnackbar';
import EditButton from '../../components/ActionButton/EditButton';
import InfoIcon from '@mui/icons-material/Info';
import FullScreenLoader from '../../components/FullScreenLoader'; // Loader em nível de página

const UserData = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isLoadingFullScreen, setIsLoadingFullScreen] = useState(false);
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  // Estado para armazenar usuário selecionado e controle do modal de edição
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
    title: '',
  });

  const [formData, setFormData] = useState({
    username: "",
    full_name: "",
    description: "",
    password: "",
  });

  const [formErrors, setFormErrors] = useState({
    username: false,
    password: false,
  });

  const [openUploadDialog, setOpenUploadDialog] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadResult, setUploadResult] = useState(null);
  const [uploadError, setUploadError] = useState(null);
  const [showResultDialog, setShowResultDialog] = useState(false);
  const [openInstructionsDialog, setOpenInstructionsDialog] = useState(false);

  useEffect(() => {
    loadUsers();
  }, []);

  const loadUsers = async () => {
    setLoading(true);
    try {
      const userData = await fetchUsers();
      setUsers(userData);
    } catch (error) {
      console.error("Erro ao carregar dados dos usuários:", error);
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    { field: 'username', headerName: 'Nome de Usuário', flex: 1 },
    { field: 'full_name', headerName: 'Nome Completo', flex: 1 },
    { field: 'description', headerName: 'Descrição', flex: 1 },
    {
      field: 'groups',
      headerName: 'Grupos',
      flex: 2,
      renderCell: (params) => {
        const groups = params.row?.groups;
        return Array.isArray(groups) && groups.length > 0
          ? groups.join(', ')
          : 'N/A';
      },
    },
    { field: 'disabled', headerName: 'Inativo', flex: 1, type: 'boolean' },
    {
      field: 'actions',
      headerName: 'Ações',
      flex: 1,
      renderCell: (params) => (
        <EditButton onEdit={() => handleEditUser(params.row)} />
      ),
    }
  ];

  const openAddDialog = () => {
    setAddDialogOpen(true);
  };

  const closeDialogs = () => {
    setEditDialogOpen(false);
    setSelectedUser(null);
    setAddDialogOpen(false);
    setConfirmDialogOpen(false);
    setFormData({
      username: "",
      full_name: "",
      description: "",
      password: "",
    });
    setFormErrors({ username: false, password: false });
  };

  const closeSnackbar = () => {
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  const handleFieldChange = (name, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: name === "disabled" 
        ? (value === "Nenhum" ? null : value === "Ativo" ? false : true) // Apenas "disabled" tem tratamento especial
        : (value === "" ? null : value), // Outros campos continuam com lógica padrão
    }));
  

    // Remove o erro ao preencher o campo
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: false,
    }));
  };

  const validateFields = () => {
    let errors = {};
    if (formData.username.trim() === "") {
      errors.username = true;
    }
    if (formData.password.trim() === "") {
      errors.password = true;
    }

    setFormErrors(errors);

    return Object.keys(errors).length === 0; // Retorna true se não houver erros
  };

  const handleOpenConfirmDialog = () => {
    if (!validateFields()) {
      setSnackbar({
        open: true,
        message: "Preencha os campos obrigatórios: Nome de Usuário e Senha.",
        severity: "error",
        title: "Erro de Validação",
        position: { vertical: 'top', horizontal: 'center' },
      });
      return;
    }
    setConfirmDialogOpen(true);
  };

  const handleAddUser = async () => {
    try {
      await createUsers({
        ...formData,
        disabled: false,
      });

      setSnackbar({
        open: true,
        message: "Usuário criado com sucesso!",
        severity: "success",
        title: "Sucesso",
        position: { vertical: 'top', horizontal: 'right' },
      });

      closeDialogs();

      loadUsers();
    } catch (error) {
      // Captura erro retornado da API (caso tenha response)
      const errorMessage = error.message;

      setSnackbar({
        open: true,
        message: errorMessage,
        severity: 'error',
        title: 'Erro',
        position: { vertical: 'top', horizontal: 'center' }
      });
      }
  };

  // Função para abrir o modal de edição com os dados do usuário
  const handleEditUser = (user) => {
    setSelectedUser(user);
    setFormData({
      full_name: user.full_name || "",
      description: user.description || "",
      password: "",
      disabled: user.disabled,
    });
    setEditDialogOpen(true);
  };

  // Função para atualizar usuário (envia apenas os campos alterados)
  const handleUpdateUser = async () => {
    if (!selectedUser) return;
  
    const updatedData = {};
    if (formData.full_name !== selectedUser.full_name) updatedData.full_name = formData.full_name;
    if (formData.description !== selectedUser.description) updatedData.description = formData.description;
    if (formData.password) updatedData.password = formData.password;
    if (formData.disabled !== null && formData.disabled !== selectedUser.disabled) {
      updatedData.disabled = formData.disabled;
    }
  
    // Se nenhum campo foi alterado, exibe aviso
    if (Object.keys(updatedData).length === 0) {
      setSnackbar({
        open: true,
        message: "Nenhuma alteração foi feita.",
        severity: "warning",
        title: "Aviso",
        position: { vertical: 'top', horizontal: 'center' },
      });
      return;
    }
  
    try {
      await updateUser(selectedUser.username, updatedData);
  
      setSnackbar({
        open: true,
        message: `Usuário ${selectedUser.username} atualizado com sucesso!`,
        severity: "success",
        title: "Sucesso",
        position: { vertical: 'top', horizontal: 'right' }
      });
  
      closeDialogs();
      loadUsers(); // Atualiza a lista de usuários
    } catch (error) {
      setSnackbar({
        open: true,
        message: error.response?.data?.detail || "Erro ao atualizar usuário.",
        severity: "error",
        title: "Erro",
        position: { vertical: 'top', horizontal: 'center' }
      });
    }
  };
  
  // Função para lidar com o upload do arquivo de criação de usuários
  const handleUploadConfirm = async () => {
    if (!selectedFile) return;
    setIsLoadingFullScreen(true); // Inicia o loading
    try {
      const result = await importUsers(selectedFile);
      setUploadResult(result);
      setUploadError(null);
      setShowResultDialog(true);
      setSnackbar({
        open: true,
        message: "Importação concluída com sucesso!",
        severity: "success",
        title: "Importação Finalizada",
        position: { vertical: 'top', horizontal: 'right' },
      });
      if (result.success_count > 0){
        loadUsers();
      }

    } catch (err) {
      const detail =
        err?.response?.data?.detail || err?.detail || err?.message || "Erro inesperado.";
      setUploadError(detail);
      setUploadResult(null);
      setShowResultDialog(true);
      setSnackbar({
        open: true,
        message:
          typeof detail === "string"
            ? detail
            : detail.message || "Erro ao importar usuários.",
        severity: "error",
        title: "Erro de Importação",
        position: { vertical: "top", horizontal: "center" },
      });
    } finally {
      setIsLoadingFullScreen(false); // Finaliza o loading
      setOpenUploadDialog(false);
      setSelectedFile(null);
    }
  };
  
  const renderUploadError = (error) => {
    // Se o erro for uma string, exibe-a diretamente
    if (typeof error === "string") {
      return (
        <Typography color="error">
          <strong>{error}</strong>
        </Typography>
      );
    }
  
    return (
      <>
        {error.message && (
          <Typography color="error">
            <strong>{error.message}</strong>
          </Typography>
        )}
        {error.duplicated_usernames && error.duplicated_usernames.length > 0 && (
          <>
            <Typography>
              <strong>Usernames duplicados no arquivo:</strong>
            </Typography>
            <Box sx={{ maxHeight:"50vh", overflowY: "auto" }}>
              <List dense>
                {error.duplicated_usernames.map((u, i) => (
                  <ListItem key={i}>
                    <span>• {u}</span>
                  </ListItem>
                ))}
              </List>
            </Box>
          </>
        )}
        {error.invalid_password_usernames && error.invalid_password_usernames.length > 0 && (
          <>
            <Typography>
              <strong>Usuários com senha ausente ou inválida:</strong>
            </Typography>
            <Box sx={{ maxHeight: "50vh", overflowY: "auto" }}>
              <List dense>
                {error.invalid_password_usernames.map((u, i) => (
                  <ListItem key={i}>
                    <span>• {u}</span>
                  </ListItem>
                ))}
              </List>
            </Box>
          </>
        )}
      </>
    );
  };
 
  const handleOpenInstructionsDialog = () => setOpenInstructionsDialog(true);
  const handleCloseInstructionsDialog = () => setOpenInstructionsDialog(false);

  return (
    <Box sx={{ p: 3 }}>
      {/* Loader em nível de página */}
      <FullScreenLoader visible={isLoadingFullScreen} message="Processando Arquivo de Usuários..." />

      <Typography variant="h4" gutterBottom>
        Users
      </Typography>

      <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={openAddDialog}
          sx={{
            mb: 2,
            backgroundColor: '#007bff',
            color: '#fff',
            fontWeight: 'bold',
            padding: '10px 20px',
            borderRadius: '8px',
          }}
        >
          Adicionar Usuário
        </Button>

        <Button 
        variant="contained" 
        color="secondary" 
        startIcon={<AddIcon />} 
        onClick={() => setOpenUploadDialog(true)} 
        sx={{ 
          mb: 2, 
          color: '#fff',
          fontWeight: 'bold',
          padding: '10px 20px',
          borderRadius: '8px',
          }}
          >
          Upload Usuários
        </Button>

        <Tooltip title="Instruções para o arquivo">
            <IconButton onClick={handleOpenInstructionsDialog}>
                <InfoIcon color="secondary" />
            </IconButton>
        </Tooltip>
      </Box>

      <DataTable
        columns={columns}
        rows={users}
        idColumn="username"
        loading={loading}
      />

      {/* Modal para adicionar usuário (Usa CustomFormDialog agora) */}
      <CustomFormDialog
        open={addDialogOpen}
        onClose={closeDialogs}
        onConfirm={handleOpenConfirmDialog} // Chama a validação antes de confirmar
        title="Adicionar Usuário"
        description="Preencha os detalhes do novo usuário."
        confirmButtonText="Continuar"
        cancelButtonText="Cancelar"
        fields={[
          {
            name: "username",
            label: "Nome de Usuário *:",
            placeholder: "Digite o nome de usuário",
            type: "text",
            required: true,
            value: formData.username,
            error: formErrors.username,
          },
          {
            name: "full_name",
            label: "Nome Completo:",
            placeholder: "Digite o nome completo",
            value: formData.full_name,
            type: "text",
          },
          {
            name: "description",
            label: "Descrição:",
            placeholder: "Digite a descrição do usuário",
            value: formData.description,
            type: "text",
          },
          {
            name: "password",
            label: "Senha *:",
            placeholder: "Digite a senha",
            type: "password",
            required: true,
            value: formData.password,
            error: formErrors.password,
            autocomplete: "new-password",
          },
        ]}
        onFieldChange={handleFieldChange}
      >
      </CustomFormDialog>

      {/* Modal de Confirmação Antes de Criar o Usuário */}
      <CustomDialog
        open={confirmDialogOpen}
        onClose={() => setConfirmDialogOpen(false)}
        onConfirm={handleAddUser}
        title="Confirmar Criação"
        description={`Deseja realmente criar o usuário ${formData.username}?`}
        confirmButtonText="Criar Usuário"
        cancelButtonText="Cancelar"
      />

      {/* Modal de Confirmação Antes de Editar o Usuário */}
      <CustomFormDialog
        open={editDialogOpen}
        onClose={closeDialogs}
        onConfirm={handleUpdateUser}
        title={`Editar Usuário: ${selectedUser?.username || ''}`}
        description="Atualize os detalhes do usuário."
        confirmButtonText="Atualizar"
        cancelButtonText="Cancelar"
        fields={[
          { name: "full_name", label: "Nome Completo:", type: "text", value: formData.full_name },
          { name: "description", label: "Descrição:", type: "text", value: formData.description },
          { name: "password", label: "Nova Senha:", type: "password", value: formData.password },
          { 
            name: "disabled", 
            label: "Status:", 
            type: "select",  // Agora é um Select!
            value:formData.disabled === null ? "Nenhum" : formData.disabled === false ? "Ativo" : "Inativo", // Converte valores do backend para exibição
            options: [
              { value: "Nenhum", label: "Nenhum (não alterar)" }, // Indica que não será alterado
              { value: "Ativo", label: "Ativo" },
              { value: "Inativo", label: "Inativo" }
            ]
          }
        ]}
        onFieldChange={handleFieldChange}
      />

      {/* Modal para Upload de Arquivo de Criação de Usuarios */}
      <CustomDialog
        open={openUploadDialog}
        onClose={() => setOpenUploadDialog(false)}
        onConfirm={handleUploadConfirm}
        title="Importar Usuários"
        description="Selecione um arquivo .csv ou .xlsx com os usuários a serem importados."
        confirmButtonText="Importar"
        cancelButtonText="Cancelar"
        fields={[{
          name: "file",
          label: "Arquivo",
          type: "file",
          accept: ".csv,.xlsx",
          value: selectedFile,
        }]}
        onFieldChange={(name, value) => name === "file" && setSelectedFile(value)}
      />

      {/* Modal para Confirmação de Upload de Arquivo de Criação de Usuarios */}
      <CustomDialogV2
        open={showResultDialog}
        onClose={() => setShowResultDialog(false)}
        onConfirm={() => setShowResultDialog(false)}
        confirmButtonText="Fechar"
        title="Resultado da Importação"
        description={
          <Stack spacing={1}>
            {uploadResult ? (
              <>
                <Typography>
                  <strong>Usuários criados:</strong> {uploadResult.success_count}
                </Typography>
                <Typography>
                  <strong>Erros:</strong> {uploadResult.error_count}
                </Typography>
                {uploadResult.errors_list && uploadResult.errors_list.length > 0 && (
                  <List dense>
                    {uploadResult.errors_list.map((err, idx) => (
                      <ListItem key={idx}>
                        <span>
                          • Linha {err.row} — <strong>{err.username}</strong>: {err.error}
                        </span>
                      </ListItem>
                    ))}
                  </List>
                )}
              </>
            ) : uploadError ? (
              renderUploadError(uploadError)
            ) : null}
          </Stack>
        }
      />

      <CustomDialog
        open={openInstructionsDialog}
        onClose={handleCloseInstructionsDialog}
        title="Instruções para o Arquivo CSV ou XLSX"
        description={
          <>
              <span style={{ display: 'block', lineHeight: 1.6, color: '#333', fontSize: '1rem', fontWeight: 500 }}>
                  O arquivo deve ser nomeado como: <strong>usuario_wealthsystem</strong>
              </span>
              <span style={{ display: 'block', lineHeight: 1.6, color: '#333', fontSize: '1rem', fontWeight: 500 }}>
                  Limite de número de usuários por arquivo: <strong>1000</strong>
              </span>
              <span style={{ display: 'block', marginTop: '1rem', fontSize: '1rem', fontWeight: 500 }}>
                  O arquivo deve conter as seguintes colunas esperadas:
              </span>
              <span style={{ display: 'block', marginTop: '0.5rem', paddingLeft: '1rem', fontSize: '1rem', color: '#333' }}>
                  - <strong>username</strong> (String)
              </span>
              <span style={{ display: 'block', paddingLeft: '1rem', fontSize: '1rem', color: '#333' }}>
                  - <strong>full_name</strong> (String)
              </span>
              <span style={{ display: 'block', paddingLeft: '1rem', fontSize: '1rem', color: '#333' }}>
                  - <strong>description</strong> (String)
              </span>
              <span style={{ display: 'block', paddingLeft: '1rem', fontSize: '1rem', color: '#333' }}>
                  - <strong>password</strong> (String)
              </span>
              <span style={{ display: 'block', marginTop: '1rem', fontSize: '1rem', color: '#333', fontWeight: 500 }}>
                Os dados do arquivo serão validados antes da importação dos usuários. É obrigatório que as colunas <strong>username</strong> e <strong>password</strong> possuam valores.
              </span>
              <span style={{ display: 'block', marginTop: '1rem', fontSize: '1rem', color: '#333', fontWeight: 500 }}>
                Após a validação bem sucedida, você verá um resumo informando:
              </span>
              <span style={{ display: 'block', marginTop: '0.5rem', paddingLeft: '1rem', fontSize: '1rem', color: '#333' }}>
                - O número de usuários criados com sucesso;
              </span>
              <span style={{ display: 'block', paddingLeft: '1rem', fontSize: '1rem', color: '#333' }}>
                - O número de registros com erro;
              </span>
              <span style={{ display: 'block', paddingLeft: '1rem', fontSize: '1rem', color: '#333' }}>
                - Uma lista detalhada dos usuários que apresentaram problemas (se houver).
              </span>
              <span style={{ display: 'block', fontSize: '1rem', color: '#d32f2f', fontWeight: 500, marginTop: '1rem' }}>
                <strong>Obs:</strong> arquivos .csv devem utilizar o delimitador <strong>;</strong>.
              </span>
          </>
      }
      
        confirmButtonText="Entendi"
        onConfirm={handleCloseInstructionsDialog}
      />


      {/* CustomSnackbar para mensagens de feedback */}
      <CustomSnackbar
        open={snackbar.open}
        message={snackbar.message}
        severity={snackbar.severity}
        onClose={closeSnackbar}
        title={snackbar.title}
        position={snackbar.position}
      />
    </Box>
  );
};

export default UserData;

