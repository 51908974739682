import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography, Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DataTable from "../../components/DataTable";
import RemoveButton from "../../components/ActionButton/RemoveButton";
import CustomDialog from "../../components/Modal/CustomDialog";
import CustomSnackbar from "../../components/CustomSnackbar";
import { fetchGroups, deleteGroup, createGroup, createPermission, deletePermission, fetchPermissions } from "../../services/auth";

export default function UserGroupPermission() {
  const [groups, setGroups] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [newGroupName, setNewGroupName] = useState("");
  const [groupToDelete, setGroupToDelete] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: "", severity: "success", title: "" });
  const navigate = useNavigate();

  // Estados para a seção de Permissões
  const [permissionList, setPermissionList] = useState([]);
  const [loadingPermissions, setLoadingPermissions] = useState(true);
  const [permissionDialogOpen, setPermissionDialogOpen] = useState(false);
  const [newPermissionName, setNewPermissionName] = useState("");
  const [deletePermissionDialogOpen, setDeletePermissionDialogOpen] = useState(false);
  const [permissionToDelete, setPermissionToDelete] = useState(null);

  useEffect(() => {
    loadGroups();
    loadPermissionList();
  }, []);

  const loadGroups = async () => {
    setLoading(true);
    try {
      const data = await fetchGroups();
      setGroups(data);
    } catch (error) {
      setSnackbar({ open: true, message: "Erro ao carregar grupos.", severity: "error", title: "Erro", position: { vertical: 'top', horizontal: 'center' } });
    } finally {
      setLoading(false);
    }
  };

  const loadPermissionList = async () => {
    setLoadingPermissions(true);
    try {
      const data = await fetchPermissions();
      setPermissionList(data);
    } catch (error) {
      setSnackbar({ open: true, message: "Erro ao carregar permissões.", severity: "error", title: "Erro", position: { vertical: 'top', horizontal: 'center' } });
    } finally {
      setLoadingPermissions(false);
    }
  };

  const handleCreateGroup = async () => {
    if (!newGroupName.trim()) {
      setSnackbar({ open: true, message: "Nome do grupo não pode estar vazio!", severity: "warning", title: "Aviso", position: { vertical: 'top', horizontal: 'center' } });
      return;
    }
    try {
      await createGroup({ name: newGroupName });
      setNewGroupName("");
      setDialogOpen(false);
      setSnackbar({ open: true, message: "Grupo criado com sucesso!", severity: "success", title: "Sucesso", position: { vertical: 'top', horizontal: 'right' } });
      loadGroups();
    } catch (error) {
      setSnackbar({ open: true, message: "Erro ao criar grupo.", severity: "error", title: "Erro", position: { vertical: 'top', horizontal: 'center' } });
    }
  };

  const handleOpenDeleteDialog = (groupName) => {
    setGroupToDelete(groupName);
    setDeleteDialogOpen(true);
  };

  const handleDeleteGroup = async () => {
    if (!groupToDelete) return;
    try {
      await deleteGroup(groupToDelete);
      setGroups(groups.filter(group => group.name !== groupToDelete));
      setSnackbar({ open: true, message: "Grupo excluído com sucesso!", severity: "success", title: "Sucesso", position: { vertical: 'top', horizontal: 'right' } });
    } catch (error) {
      setSnackbar({ open: true, message: "Erro ao excluir grupo.", severity: "error", title: "Erro", position: { vertical: 'top', horizontal: 'center' } });
    } finally {
      setDeleteDialogOpen(false);
      setGroupToDelete(null);
    }
  };

  const handleCreatePermission = async () => {
    if (!newPermissionName.trim()) {
      setSnackbar({ open: true, message: "Nome da permissão não pode estar vazio!", severity: "warning", title: "Aviso", position: { vertical: 'top', horizontal: 'center' } });
      return;
    }
    try {
      await createPermission({ name: newPermissionName });
      setNewPermissionName("");
      setPermissionDialogOpen(false);
      setSnackbar({ open: true, message: "Permissão criada com sucesso!", severity: "success", title: "Sucesso", position: { vertical: 'top', horizontal: 'right' } });
      loadPermissionList();
    } catch (error) {
      setSnackbar({ open: true, message: "Erro ao criar permissão.", severity: "error", title: "Erro", position: { vertical: 'top', horizontal: 'center' } });
    }
  };

  const handleOpenDeletePermissionDialog = (permissionName) => {
    setPermissionToDelete(permissionName);
    setDeletePermissionDialogOpen(true);
  };

  const handleDeletePermission = async () => {
    if (!permissionToDelete) return;
    try {
      await deletePermission(permissionToDelete);
      setPermissionList(permissionList.filter(p => p !== permissionToDelete));
      setSnackbar({ open: true, message: "Permissão excluída com sucesso!", severity: "success", title: "Sucesso", position: { vertical: 'top', horizontal: 'right' } });
    } catch (error) {
      setSnackbar({ open: true, message: "Erro ao excluir permissão.", severity: "error", title: "Erro", position: { vertical: 'top', horizontal: 'center' } });
    } finally {
      setDeletePermissionDialogOpen(false);
      setPermissionToDelete(null);
    }
  };

  const closeSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const columns = [
    { field: "name", headerName: "Grupo", flex: 2 },
    { field: "created_at", headerName: "Criado em", flex: 2, renderCell: (params) => new Date(params.value).toLocaleDateString() },
    {
      field: "actions",
      headerName: "Ações",
      flex: 1,
      renderCell: (params) => (
        <>
          <Button onClick={() => navigate(`/admin/users/groups/${params.row.name}`)}>Detalhes</Button>
          <RemoveButton onRemove={() => handleOpenDeleteDialog(params.row.name)} nome={params.row.name} />
        </>
      ),
    }
  ];

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>Gerenciar Grupos e Permissões</Typography>

      {/* Seção de Grupos */}
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
        onClick={() => setDialogOpen(true)}
        sx={{
          mb: 2,
          backgroundColor: "#007bff",
          color: "#fff",
          fontWeight: "bold",
          padding: "10px 20px",
          borderRadius: "8px",
        }}
      >
        Criar Grupo
      </Button>

      <DataTable columns={columns} rows={groups} idColumn="name" loading={loading} />

      {/* Modal para criação de grupo */}
      <CustomDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        onConfirm={handleCreateGroup}
        title="Criar Novo Grupo"
        description="Digite o nome do novo grupo."
        confirmButtonText="Criar"
        cancelButtonText="Cancelar"
        fields={[
          {
            name: "group_name",
            label: "Nome do Grupo:",
            placeholder: "Digite o nome do grupo",
            type: "text",
            value: newGroupName,
          },
        ]}
        onFieldChange={(name, value) => setNewGroupName(value)}
      />

      {/* Modal de Confirmação para Excluir Grupo */}
      <CustomDialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        onConfirm={handleDeleteGroup}
        title="Excluir Grupo"
        description={`Tem certeza de que deseja excluir o grupo "${groupToDelete}"? Essa ação não pode ser desfeita.`}
        confirmButtonText="Excluir"
        cancelButtonText="Cancelar"
      />

      {/* --------------------- NOVA SEÇÃO: Visualização e Criação de Permissões --------------------- */}
      <Box sx={{ mt: 4 }}>
        <Typography variant="h4" gutterBottom>Permissões</Typography>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => setPermissionDialogOpen(true)}
          sx={{
            mb: 2,
            backgroundColor: "#007bff",
            color: "#fff",
            fontWeight: "bold",
            padding: "10px 20px",
            borderRadius: "8px",
          }}
        >
          Criar Permissão
        </Button>
        <DataTable
          columns={[
            { field: "name", headerName: "Permissão", flex: 2 },
            {
              field: "actions",
              headerName: "Ações",
              flex: 1,
              renderCell: (params) => (
                <RemoveButton
                  onRemove={() => handleOpenDeletePermissionDialog(params.row.name)}
                  nome={params.row.name}
                />
              ),
            },
          ]}
          rows={permissionList.map((perm, index) => ({ id: index, name: perm }))}
          idColumn="id"
          loading={loadingPermissions}
        />
      </Box>

      {/* Modal para criação de permissão */}
      <CustomDialog
        open={permissionDialogOpen}
        onClose={() => setPermissionDialogOpen(false)}
        onConfirm={handleCreatePermission}
        title="Criar Nova Permissão"
        description="Digite o nome da nova permissão."
        confirmButtonText="Criar"
        cancelButtonText="Cancelar"
        fields={[
          {
            name: "permission_name",
            label: "Nome da Permissão:",
            placeholder: "Digite o nome da permissão",
            type: "text",
            value: newPermissionName,
          },
        ]}
        onFieldChange={(name, value) => setNewPermissionName(value)}
      />

      {/* Modal de Confirmação para Excluir Permissão */}
      <CustomDialog
        open={deletePermissionDialogOpen}
        onClose={() => setDeletePermissionDialogOpen(false)}
        onConfirm={handleDeletePermission}
        title="Excluir Permissão"
        description={`Tem certeza de que deseja excluir a permissão "${permissionToDelete}"? Essa ação não pode ser desfeita.`}
        confirmButtonText="Excluir"
        cancelButtonText="Cancelar"
      />

      {/* Snackbar para feedback */}
      <CustomSnackbar
        open={snackbar.open}
        message={snackbar.message}
        severity={snackbar.severity}
        title={snackbar.title}
        onClose={closeSnackbar}
        position={snackbar.position}
      />
    </Box>
  );
}
