import React from "react";
import { Breadcrumbs, Link, Typography, Box } from "@mui/material";
import { useLocation, Link as RouterLink } from "react-router-dom";
import { routeNamesAdmin } from "../utils/routerHelper";

const AdminBreadcrumbs = () => {
  const location = useLocation();

  // Quebra e remove apenas o primeiro "admin"
  const segments = location.pathname.split("/").filter(Boolean);
  const pathnames = segments[0] === "admin" ? segments.slice(1) : segments;

  // Verifica se o primeiro item já representa "home"
  const isFirstHome = pathnames.length > 0 && pathnames[0] === "home";

  return (
    <Box sx={{ mb: 2, mt: 1, paddingLeft: 2 }}>
      <Breadcrumbs aria-label="breadcrumb" sx={{ fontSize: "1rem" }}>
        {/* Se o primeiro item já for "Home", não exibe duas vezes */}
        {!isFirstHome && (
          <Link
            component={RouterLink}
            to="/admin/home"
            underline="hover"
            color="inherit"
            sx={{ fontWeight: "bold" }}
          >
            Home
          </Link>
        )}

        {pathnames.map((value, index) => {
          // Constrói a rota acumulando os segmentos
          const route = `/admin/${pathnames.slice(0, index + 1).join("/")}`;
          const isLast = index === pathnames.length - 1;
          const name = routeNamesAdmin[route] || value;

          return isLast ? (
            <Typography key={route} color="text.primary" sx={{ fontWeight: "bold" }}>
              {name}
            </Typography>
          ) : (
            <Link
              key={route}
              component={RouterLink}
              to={route}
              underline="hover"
              color="inherit"
              sx={{ fontWeight: "medium" }}
            >
              {name}
            </Link>
          );
        })}
      </Breadcrumbs>
    </Box>
  );
};

export default AdminBreadcrumbs;
