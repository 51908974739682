import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import AssessmentIcon from '@mui/icons-material/Assessment';
import MiscellaneousServicesTwoToneIcon from '@mui/icons-material/MiscellaneousServicesTwoTone';
import SettingsTwoToneIcon from '@mui/icons-material/SettingsTwoTone';
import PaidRoundedIcon from '@mui/icons-material/PaidRounded';
import TrendingUpRoundedIcon from '@mui/icons-material/TrendingUpRounded';
import ModuleCard from '../../components/ModuleCard';
import { menuItems } from '../../routes/routes';
import { useAuth } from '../../context/AuthContext';

const submoduleIcons = {
  'Classificação': <MiscellaneousServicesTwoToneIcon sx={{ fontSize: 60 }} />,
  'Configurações': <SettingsTwoToneIcon sx={{ fontSize: 60 }} />,
  'Fundos na Bolsa': <TrendingUpRoundedIcon sx={{ fontSize: 60 }} />,
  'ETF': <TrendingUpRoundedIcon sx={{ fontSize: 60 }} />,
  'Ações e BDRs': <TrendingUpRoundedIcon sx={{ fontSize: 60 }} />,
  'Proventos': <PaidRoundedIcon sx={{ fontSize: 60 }} />,
};

const RendaVariavel = () => {
  const theme = useTheme();
  const { permissions } = useAuth();

  // Localiza o módulo "Renda Variável" e filtra submódulos permitidos
  const rendaVariavelModule = menuItems.find(item => item.text === 'Renda Variável');
  const allowedSubmodules = rendaVariavelModule?.submodules?.filter(
    sub => !sub.requiredPermission || permissions.includes(sub.requiredPermission)
  ) || [];

  // Mapeia os submódulos formatados para exibição
  const topSubmodules = allowedSubmodules.map(sub => ({
    text: sub.text,
    description: `Acesse o módulo ${sub.text}.`,
    route: sub.route,
  }));

  // Calcula o número total de colunas (1 coluna para cada 3 submódulos)
  const columns = Math.ceil(topSubmodules.length / 3);

  // Define colunas por breakpoint
  const getColumnsForBreakpoint = (breakpoint) => {
    if (breakpoint === 'xs') return Math.min(columns, 1);
    if (breakpoint === 'sm') return Math.min(columns, 2);
    if (breakpoint === 'md') return Math.min(columns, 3);
    return columns;
  };

  return (
    <Box sx={{ flexGrow: 1, padding: theme.spacing(2) }}>
      <Typography
        variant="h4"
        align="center"
        gutterBottom
        sx={{ fontWeight: 'bold', color: theme.palette.text.primary }}
      >
        Renda Variável
      </Typography>

      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: {
            xs: `repeat(${getColumnsForBreakpoint('xs')}, 1fr)`,
            sm: `repeat(${getColumnsForBreakpoint('sm')}, 1fr)`,
            md: `repeat(${getColumnsForBreakpoint('md')}, 1fr)`,
            lg: `repeat(${getColumnsForBreakpoint('lg')}, 1fr)`,
          },
          gap: theme.spacing(2),
          paddingX: theme.spacing(2),
        }}
      >
        {topSubmodules.map((submodule, index) => (
          <ModuleCard
            key={index}
            title={submodule.text}
            description={submodule.description}
            icon={submoduleIcons[submodule.text] || <AssessmentIcon sx={{ fontSize: 60 }} />}
            route={submodule.route}
          />
        ))}
      </Box>
    </Box>
  );
};

export default RendaVariavel;
